import './CustomersStyles.scss'
import { Table, Row, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { routes } from '../../config/constants'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import DomainsIcon from '../../assets/icons/domains.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import ActiveIcon from '../../assets/icons/active.svg'
import IconWithInfo from '../../components/IconWithInfo'
import Banner from '../../components/Banner'
import FormButton from '../../components/FormButton'

const CustomersComponent = ({
  showModal,
  handleCloseModal,
  customers,
  icons,
  handleChangeCustomerState,
  onChangeFile,
  csvObjectCustomers,
  handleClickLoadCsvCustomers,
  handleCloseCustomerUpload,
  handleChangeReportState,
}) => {
  return (
    <>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Modal
          showModal={showModal.show}
          handleCloseModal={handleCloseModal}
          title={showModal.title}
          message={showModal.message}
          redirect={showModal.redirect}
          buttonMessage={showModal.buttonMessage}
        />
        {csvObjectCustomers ? (
          <>
            <Banner title="CUSTOMER UPLOAD" onClick={handleCloseCustomerUpload} />
            <div style={{ height: 'calc(100vh - 160px)' }} className="customers-container">
              <div>
                <Table className="customers-table" striped hover borderless>
                  <thead>
                    <tr>
                      <th style={{ fontSize: 16 }}>Company Name*</th>
                      <th style={{ fontSize: 16 }}>First Name*</th>
                      <th style={{ fontSize: 16 }}>Last Name*</th>
                      <th style={{ fontSize: 16 }}>Customer Report Email*</th>
                      <th style={{ fontSize: 16 }} className="text-center">
                        Reports Status
                      </th>
                      <th style={{ fontSize: 16 }} className="text-center">
                        Reports Frequency
                      </th>
                      <th style={{ fontSize: 16 }}>Domain 1*</th>
                      <th style={{ fontSize: 16 }}>Domain 2</th>
                      <th style={{ fontSize: 16 }}>Domain 3</th>
                      <th style={{ fontSize: 16 }}>Per Email 1</th>
                      <th style={{ fontSize: 16 }}>Per Email 2</th>
                      <th style={{ fontSize: 16 }}>Per Email 3</th>
                      <th style={{ fontSize: 16 }}>Per Email 4</th>
                      <th style={{ fontSize: 16 }}>Per Email 5</th>
                      <th style={{ fontSize: 16 }} className="text-center">
                        Active
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {csvObjectCustomers?.map((customer, i) => (
                      <tr key={i}>
                        <td>{customer['Company Name*']}</td>
                        <td>{customer['First Name*']}</td>
                        <td>{customer['Last Name*']}</td>
                        <td>{customer['Customer Report Email Address*']}</td>
                        <td className="text-center">Disabled</td>
                        <td className="text-center">Monthly</td>
                        <td>{customer['Domain 1*']}</td>
                        <td>{customer['Domain 2'] || ''}</td>
                        <td>{customer['Domain 3'] || ''}</td>
                        <td>{customer['Personal Email 1'] || ''}</td>
                        <td>{customer['Personal Email 2'] || ''}</td>
                        <td>{customer['Personal Email 3'] || ''}</td>
                        <td>{customer['Personal Email 4'] || ''}</td>
                        <td>{customer['Personal Email 5'] || ''}</td>
                        <td className="text-center">
                          <img src={ActiveIcon} alt="Active icon" width={15} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : (
          <>
            <Banner title="CUSTOMERS" />
            <div className="customers-container">
              <div className="customers-header-container">
                <div>
                  <Row>
                    <Col>
                      <Link to={routes.ADD_CUSTOMER_ROUTE}>
                        <div className="customers-icon-container">
                          <img
                            className="customers-plus-icon"
                            src={PlusIcon}
                            alt="Add customers icon"
                            width={30}
                          />
                          <h1>Add customer</h1>
                        </div>
                      </Link>
                    </Col>
                    <Col>
                      <p>
                        Download the CSV import template to bulk upload customers to the system.
                      </p>
                    </Col>
                    <Col>
                      <a
                        href="/MSP%20Dark%20Web%20New%20Customer%20Bulk%20Upload%20Template.csv"
                        download
                        target="_blank"
                      >
                        <p style={{ textDecoration: 'underline', color: 'black' }}>
                          CSV Import Template
                        </p>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div class="form-group" style={{ marginBottom: 0 }}>
                        <div class="form-file">
                          <label for="image" class="form-file-label">
                            <p style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                              Upload CSV File
                            </p>
                          </label>
                          <input
                            onChange={onChangeFile}
                            accept=".csv"
                            id="image"
                            type="file"
                            class="form-control-file"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <IconWithInfo
                  icon={DomainsIcon}
                  text={icons.domains}
                  bottomText="Domains"
                  width={45}
                  height={45}
                  color="primary"
                />
              </div>
              <Table className="customers-table" striped hover borderless>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                    <th>Domain(s)</th>
                    <th>Email(s)</th>
                    <th className="text-center">Total Compromises</th>
                    <th className="text-center">Last 30 Days Compromises</th>
                    <th className="text-center">Reports</th>
                    <th className="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map(customer => (
                    <tr key={customer.customerId}>
                      <td>
                        <Link to={routes.CUSTOMERS_ROUTE + '/' + customer.customerId}>
                          {customer.companyName}
                        </Link>
                      </td>
                      <td>{`${customer.firstName} ${customer.lastName}`}</td>
                      <td>{customer.domains?.map(domain => domain.domain + ' ')}</td>
                      <td>{customer.emails?.map(email => email.email + ' ')}</td>
                      <td className="text-center">{customer.breaches.length}</td>
                      <td className="text-center">{customer.lastCompromises.length}</td>
                      <td className="text-center">
                        {customer.reportStatus ? (
                          <img
                            onClick={() => {
                              handleChangeReportState(customer.customerId)
                            }}
                            src={ActiveIcon}
                            alt="Active icon"
                            style={{ cursor: 'pointer' }}
                            width={15}
                          />
                        ) : (
                          <img
                            onClick={() => {
                              handleChangeReportState(customer.customerId)
                            }}
                            style={{ opacity: 0.2, cursor: 'pointer' }}
                            src={ActiveIcon}
                            alt="Active icon"
                            width={15}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {customer.status ? (
                          <img
                            onClick={() => {
                              handleChangeCustomerState(customer.customerId)
                            }}
                            src={ActiveIcon}
                            alt="Active icon"
                            style={{ cursor: 'pointer' }}
                            width={15}
                          />
                        ) : (
                          <img
                            onClick={() => {
                              handleChangeCustomerState(customer.customerId)
                            }}
                            style={{ opacity: 0.2, cursor: 'pointer' }}
                            src={ActiveIcon}
                            alt="Active icon"
                            width={15}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </motion.div>

      {csvObjectCustomers && (
        <FormButton
          type="button"
          onClick={handleClickLoadCsvCustomers}
          style={{ position: 'fixed', right: 20, bottom: 20 }}
          width={80}
          label="LOAD"
        />
      )}
    </>
  )
}

export default CustomersComponent
