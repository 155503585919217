const TrainingIcon = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0 0 6l11 6 9-4.91V14h2V6L11 0ZM4 10.18v4L11 18l7-3.82v-4L11 14l-7-3.82Z"
      fill="#000"
    />
  </svg>
)

export default TrainingIcon
