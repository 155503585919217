import MspMenu from '../../components/MspMenu'
import FirstBreachesComponent from './FirstBreachesComponent.jsx'
import { useEffect, useState } from 'react'
import {
  archiveABreachService,
  getAllBreachesOfACustomerService,
  getAllBreachesOfAMSPService,
  sendABreachAlertService,
  ArchiveAllFirstBreachService
} from '../../services/firstBreach.service'
import { useLocation } from 'react-router-dom'
import React from 'react'
import { getMyMspService } from '../../services/msp.service'
import { decryptPassword, hashPassword, handleCheckboxClick } from '../../utilities/decryptPass'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min.js'
import { useHistory } from 'react-router-dom'
function useQuery () {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
const FirstBreachesContainer = ({ match }) => {
  const query = useQuery()
  const CustomerId = match.params.id
  const initialValues = {
    domain: query.get('domain') || '',
    email: query.get('email') || '',
  }
  const [allBreaches, setAllBreaches] = useState([])
  const [values, setValues] = useState(initialValues)
  const [customer, setCustomer] = useState({})
  const [showModal, setShowModal] = useState({})
  const [loading, setLoading] = useState(false)
  const [mspCompanyName, setMspCompanyName] = useState('')
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [headerCSV, setHeaderCSV] = useState([]);
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0)
  const history = useHistory()

  const getBreachesData = (breachesResponse) => {
    let date = process.env.REACT_APP_ENV_MSP == "staging" ? new Date("2023-09-21") : new Date("2023-10-05")
    breachesResponse?.breaches?.map((preSearch) => {
      if (preSearch?.password_plaintext && new Date(preSearch?.createdAt) >= date) {
        preSearch.decryptPass = decryptPassword(preSearch?.password_plaintext)
        if (preSearch.decryptPass) {
          preSearch.hashedPassword = hashPassword(preSearch?.decryptPass, preSearch?.password_type)
        }
      } else {
        preSearch.decryptPass = preSearch?.password_plaintext
        preSearch.hashedPassword = hashPassword(preSearch?.decryptPass, preSearch?.password_type)
      }

    }

    )
  }

  async function getAllBreachesFromAPI (page, limit) {
    try {
      setLoading(true)
      if (CustomerId) {
        const response = await getAllBreachesOfACustomerService(CustomerId, page, limit, values.domain, values.email)
        getBreachesData(response?.data?.data)
        setCustomer(response?.data?.data)
        setAllBreaches(response?.data?.data?.breaches)
        setTotalData(response?.data?.data?.totalItems);
        if (query.get('domain') || query.get('email')) {
          if (values.domain) {
            setCustomer(prevState => ({
              ...prevState,
              breaches: response?.data?.data.breaches.filter(
                breach => breach.domain === values.domain
              ),
            }))
          } else {
            setCustomer(prevState => ({
              ...prevState,
              breaches: response?.data?.data?.breaches.filter(breach => breach.email === values.email),
            }))
          }
        }
      } else {
        const response = await getAllBreachesOfAMSPService()
        getBreachesData(response?.data?.data)
        setCustomer(response.data.data)
        setAllBreaches(response.data.data.breaches)
        if (query.get('domain') || query.get('email')) {
          if (values.domain) {
            setCustomer(prevState => ({
              ...prevState,
              breaches: response.data.data.breaches.filter(
                breach => breach.domain === values.domain
              ),
            }))
          } else {
            setCustomer(prevState => ({
              ...prevState,
              breaches: response.data.data.breaches.filter(breach => breach.email === values.email),
            }))
          }
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function getMyMSPFromAPI () {
    try {
      if (!CustomerId) {
        const myMSP = await getMyMspService()
        setMspCompanyName(myMSP.data.data.companyName)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllBreachesFromAPI(currentPage, itemsPerPage)
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    getMyMSPFromAPI()
  }, [])

  useEffect(() => {
    if (isCheck.length > 0) {
      const out = isCheck.map((itm, i) =>
        customer.breaches.filter((item) => item.id === itm)
      )
      let dataArray = [];
      let date = process.env.REACT_APP_ENV_MSP == "staging" ? new Date('2023-09-21') : new Date('2023-10-05')
      out.map((item, ind) => {
        let csvPassword;
        if (new Date(item[0]?.createdAt) >= date && item[0]?.decryptPass) {
          csvPassword = item[0]?.decryptPass.slice(0, 3) + "*".repeat(item[0]?.decryptPass.length - 3)
        } else {
          csvPassword = item[0]?.password_plaintext
        }
        const dataObj = {
          companyName: (customer?.["companyName"]) ? customer?.["companyName"] : "Unknown",
          email: (item[0]?.email) ? item[0]?.email : "Unknown",
          password: item[0]?.password_type === 'plaintext' ? csvPassword :
            !csvPassword && item[0]?.password_type && item[0]?.password_type.trim() !== 'plaintext' ? 'Encrypted' :
              csvPassword && !item[0]?.password_type ? 'No Password' :
                'None',

          password_type: (item[0]?.password_type) ? "Yes" : "No",
          breachTitle: (item[0]?.breach?.title) ? item[0]?.breach?.title : "Unknown",
          breach_date: (item[0]?.breach?.breach_date) ? item[0].breach?.breach_date : "Unknown",
          spycloud_publish_date: (item[0]?.breach?.spycloud_publish_date) ? item[0]?.breach?.spycloud_publish_date : "Unknown",

          severity: (item[0].severity) ? item[0].severity : "Unknown",
        }

        dataArray.push(dataObj)
      })
      setCsvData([dataArray]);

      const headers = [
        { label: 'Customer Name', key: 'companyName' },
        { label: 'Email Address', key: 'email' },
        { label: 'Password', key: 'password' },
        // { label: 'Decrypted Password', key: 'password_type' },

        { label: 'Breach Source', key: 'breachTitle' },
        { label: 'Breach Date', key: 'breach_date' },
        { label: 'Publish Date', key: 'spycloud_publish_date' },

        { label: 'Severity', key: 'severity' },
      ];

      // headers.sort(function (a, b) {
      //   return a.label.localeCompare(b.label);
      // })
      setHeaderCSV(headers)

    }
  }, [isCheck])

  function handleChange (e) {
    initialValues.domain = '';
    initialValues.email = '';
    const { id, value } = e.target
    setValues({ ...initialValues, [id]: value })
  }

  function handleClickSearch () {
    setChecked(false)
    if (values.domain === '' && values.email === '') {
      // setCustomer(prevState => ({ ...prevState, breaches: allBreaches }))
    } else {
      if (values.domain) {
        if (
          !/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
            values.domain
          )
        ) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `You entered "${values.domain}" which is not a valid domain`,
          })
        } else {
          history.replace(`/first-breach/${CustomerId}/?domain=${values.domain}`)
          getAllBreachesFromAPI(currentPage, itemsPerPage)
          // setCustomer(prevState => ({
          //   ...prevState,
          //   breaches: allBreaches.filter(breach => breach.domain === values.domain),
          // }))
        }
      } else {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,25}$/.test(values.email)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID EMAIL TO SEARCH',
            message: `You entered "${values.email}" which is not a valid email`,
          })
        } else {
          history.replace(`/first-breach/${CustomerId}/?email=${values.email}`)
          getAllBreachesFromAPI(currentPage, itemsPerPage)
        }
      }
    }
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  async function handleSendAlert (breachId) {
    try {
      await sendABreachAlertService(breachId)
      setShowModal({
        show: true,
        title: 'SENT SUCCESSFULLY',
        message: 'The email with the breach data has been sent to your helpdesk',
      })
      getAllBreachesFromAPI(currentPage, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }

  async function handleArchiveABreach (breachId) {
    try {
      await archiveABreachService(breachId)
      getAllBreachesFromAPI(currentPage, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }

  function handleCloseModal () {
    setShowModal({})
  }

  function handleClickResetSearch () {
    setCustomer(prevState => ({
      ...prevState,
      breaches: allBreaches,
    }))
    setValues(initialValues)
  }

  const handleAllStatusChange = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(customer.breaches.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  }

  const handleStatusChange = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  }

  const handleArchive = async () => {
    if (isCheck.length > 0) {
      setArchiveLoading(true)
      const selectData = {}
      selectData.Archives = isCheck;
      await ArchiveAllFirstBreachService(selectData)
      await getAllBreachesFromAPI(currentPage, itemsPerPage)
      setIsCheckAll(false)
      setIsCheck([])
      setArchiveLoading(false)
    }
  }

  const handleCsvDownload = () => {
    setIsCheck([])
    setIsCheckAll(false)
    setCsvData([])
    // }
  }

  const handleShowCsvModal = () => {
    setShowModal({
      show: true,
      title: 'Alert',
      message: `Please select breach to generate csv`,
    })
  }

  return (
    <>
      <MspMenu>
        <FirstBreachesComponent
          {...{
            allBreaches,
            CustomerId,
            customer,
            values,
            handleChange,
            handleClickSearch,
            handleSendAlert,
            showModal,
            handleCloseModal,
            handleArchiveABreach,
            handleClickResetSearch,
            loading,
            mspCompanyName,
            handleArchive,
            handleAllStatusChange,
            handleStatusChange,
            isCheck,
            isCheckAll,
            archiveLoading,
            headerCSV,
            csvData,
            handleCsvDownload,
            handleShowCsvModal,
            handleCheckboxClick,
            checked,
            setChecked,
            currentPage,
            itemsPerPage,
            totalData,
            handlePageChange,
            handleItemsPerPageChange,
          }}
        />
      </MspMenu>
    </>
  )
}

export default FirstBreachesContainer