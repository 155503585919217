import './AdminFeatureCategories.scss'
import CrossIcon from '../../assets/icons/crossWhite.svg'
import { Row, Col, Form } from 'react-bootstrap'
import FormInput from '../FormInput'
import FormButton from '../FormButton'
import TrashIcon from '../../assets/icons/trashDelete.svg'

const AdminFeatureCategories = ({
  show,
  onClose,
  categories,
  formik,
  inputId,
  handleDeleteCategory,
  isLoading,
}) => {
  if (!show) {
    return null
  }

  return (
    <div className="admin-feature-categories">
      <div className="admin-feature-categories-content">
        <div className="admin-banner-container" style={{ borderRadius: '5px 5px 0 0' }}>
          <h1>FEATURE CATEGORIES</h1>
          <img
            onClick={onClose}
            src={CrossIcon}
            alt="Cross icon to close the modal"
            width={25}
            height={25}
          />
        </div>
        <div className="admin-feature-categories-body">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <FormInput
                  formik={formik}
                  id={inputId}
                  style={{ marginBottom: 26 }}
                  label="Category"
                />
                <FormButton
                  type="submit"
                  width={200}
                  label={isLoading ? 'LOADING...' : 'ADD'}
                  mdw
                />
              </Col>
              <Col>
                <div className="admin-feature-categories-box">
                  <h5 className="admin-feature-categories-bold-text">CATEGORY</h5>
                  {categories?.map(category => (
                    <div key={category.id} className="admin-feature-categories-items-container">
                      <p className="admin-feature-categories-underlined-text">{category.name}</p>
                      <img
                        id={category.id}
                        onClick={handleDeleteCategory}
                        style={{ cursor: 'pointer' }}
                        src={TrashIcon}
                        width={20}
                        height={20}
                        alt="Delete icon"
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AdminFeatureCategories
