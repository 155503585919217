import './LoginStyles.scss'
import Modal from '../../components/Modal'
import FormInput from '../../components/FormInput'
import FormButton from '../../components/FormButton'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { routes } from '../../config/constants'

const LoginComponent = ({ formik, isLoading, handleCloseModal, showModal }) => {
  return (
    <>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        title="ERROR IN LOGIN"
        message="The username or password are incorrect"
      />
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="login-container">
          <FormInput
            formik={formik}
            id="username"
            placeholder="Username"
            height={52}
            width={300}
            style={{ marginBottom: 0 }}
            label="Username"
          />
          <FormInput
            formik={formik}
            id="password"
            placeholder="Password"
            autocomplete="current-password"
            height={52}
            width={300}
            style={{ marginTop: 33, marginBottom: 0 }}
            label="Password"
            type="password"
          />
          <FormButton
            type="submit"
            className="login-button"
            style={{ marginTop: 30 }}
            label="LOGIN"
          />
          <Link to={routes.FORGOT_PASSWORD_ROUTE} className="login-recover-password">
            Forgot Password
          </Link>
        </div>
      </Form>
    </>
  )
}

export default LoginComponent
