import { Page, Text, Document, StyleSheet, Font, Image, View } from '@react-pdf/renderer'

Font.register({
  family: 'Roboto',
  src: '/prospecting-report/fonts/Roboto-Regular.ttf',
})

Font.register({
  family: 'Roboto Bold',
  src: '/prospecting-report/fonts/Roboto-Bold.ttf',
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    color: '#272736',
    fontFamily: 'Roboto',
  },

  headerLogo: {
    marginHorizontal: 170,
    marginTop: 50,
    marginBottom: 100,
  },

  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto Bold',
  },

  textDateOfSearch: {
    textAlign: 'center',
    marginBottom: 40,
    fontSize: 16,
  },

  textCompanyName: {
    textAlign: 'center',
    fontSize: 30,
    fontFamily: 'Roboto Bold',
  },

  textProducedBy: {
    textAlign: 'center',
    fontSize: 16,
  },

  mspLogo: {
    marginHorizontal: 220,
    marginTop: 50,
  },

  textStatistics: {
    fontSize: 50,
    textAlign: 'center',
    fontFamily: 'Roboto Bold',
  },

  inSummary: {
    fontSize: 30,
    textAlign: 'center',
    marginVertical: 30,
    fontFamily: 'Roboto Bold',
  },

  inline: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  theKeyword: {
    fontFamily: 'Roboto Bold',
    title: {
      color: '#0ddb95',
      fontSize: 30,
      fontFamily: 'Roboto Bold',
    },
    appeared: {
      fontFamily: 'Roboto Bold',
      fontSize: 12,
    },
  },

  font10: {
    fontSize: 10,
    fontFamily: 'Roboto Bold',
  },

  font20: {
    fontSize: 20,
    fontFamily: 'Roboto Bold',
  },

  font60: {
    fontSize: 60,
    fontFamily: 'Roboto Bold',
  },

  table: {
    display: 'table',
    width: 'auto',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRow: { margin: 'auto', flexDirection: 'row' },

  tableCol: {
    width: '16.6666666667%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol80: {
    width: '80%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol20: {
    width: '20%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: { margin: 'auto', marginTop: 5, fontSize: 10 },
  tableHeader: { margin: 'auto', marginTop: 5, fontSize: 10, fontFamily: 'Roboto Bold' },
})

export default ({ data }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.body}>
        <Image
          style={styles.headerLogo}
          src={data.msp.darkLogo || '/prospecting-report/MSP_Logo_DARK.png'}
        />
        <Text style={styles.title}>Live Search</Text>
        <Text style={styles.textDateOfSearch}>Date of Search: {data.breachDate}</Text>
        <Text style={styles.textCompanyName}>{data.customerName}</Text>
        <Text style={styles.textProducedBy}>Produced by: {data.msp.companyName}</Text>
      </Page>
      <Page orientation="landscape" style={styles.body}>
        <Text style={styles.textStatistics}>STATISTICS</Text>
        <Text style={styles.inSummary}>Last Year in Summary</Text>
        <View style={{ ...styles.inline, marginBottom: 40 }}>
          <View>
            <Text style={styles.theKeyword}>THE KEYWORD</Text>
            <Text style={{ ...styles.theKeyword.title, marginLeft: 27 }}>2020</Text>
            <Text style={styles.theKeyword.appeared}>Appeared in 1,638,383</Text>
            <Text style={{ ...styles.theKeyword.appeared, marginLeft: 32 }}>Passwords</Text>
          </View>
          <View>
            <Text style={styles.theKeyword}>THE KEYWORD</Text>
            <Text style={{ ...styles.theKeyword.title, marginLeft: 18 }}>COVID</Text>
            <Text style={{ ...styles.theKeyword.appeared, marginLeft: 15 }}>Appeared in 6,349</Text>
            <Text style={{ ...styles.theKeyword.appeared, marginLeft: 32 }}>Passwords</Text>
          </View>
          <View>
            <Text style={styles.theKeyword}>THE KEYWORD</Text>
            <Text style={{ ...styles.theKeyword.title, marginLeft: 13 }}>POLICE</Text>
            <Text style={{ ...styles.theKeyword.appeared, marginLeft: 8 }}>
              Appeared in 111,544
            </Text>
            <Text style={{ ...styles.theKeyword.appeared, marginLeft: 32 }}>Passwords</Text>
          </View>
          <View>
            <Text> </Text>
            <View
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginLeft: 20 }}
            >
              <Text style={{ color: '#ff0000', fontSize: 40, fontFamily: 'Roboto Bold' }}>19</Text>
              <Text
                style={{
                  color: '#0ddb95',
                  fontSize: 10,
                  fontFamily: 'Roboto Bold',
                  marginBottom: 7,
                }}
              >
                SECONDS
              </Text>
            </View>
            <Text style={styles.font10}>One small business in the UK is</Text>
            <Text style={{ ...styles.font10, marginLeft: 10 }}>successfully hacked every</Text>
          </View>
          <View>
            <Text style={{ ...styles.theKeyword, marginLeft: 25 }}>AROUND</Text>
            <Text style={{ ...styles.theKeyword.title, marginLeft: 15 }}>65,000</Text>
            <Text style={{ ...styles.font10, marginLeft: 3 }}>attempts to hack small to</Text>
            <Text style={{ ...styles.font10, marginLeft: 2 }}>medium-sized businesses</Text>
            <Text style={styles.font10}>(SMBs) in the UK every day.</Text>
          </View>
        </View>
        <View style={{ ...styles.inline, marginBottom: 40 }}>
          <View>
            <Text style={styles.font10}>The average breach included</Text>
            <Text style={styles.theKeyword.title}>5,455,813</Text>
          </View>
          <View style={{ marginRight: 30 }}>
            <Text style={{ ...styles.theKeyword, fontSize: 60, color: '#ff0000', marginLeft: 25 }}>
              4.6
            </Text>
            <Text style={{ ...styles.theKeyword, color: '#0ddb95', marginLeft: 35 }}>BILLION</Text>
            <Text style={{ ...styles.font10, marginLeft: 30 }}>Total PII Assets</Text>
            <Text style={{ ...styles.font10, marginLeft: 20 }}>Recovered Last Year</Text>
          </View>
          <View>
            <Text style={styles.theKeyword}> </Text>
            <Text style={styles.theKeyword.title}>193,073</Text>
            <Text style={{ ...styles.font10, marginLeft: 12 }}>Passwords included</Text>
            <Text style={{ ...styles.font10, marginLeft: 12 }}>pandemic keywords</Text>
          </View>
          <View style={{ marginLeft: 40 }}>
            <Text style={{ ...styles.theKeyword, fontSize: 60, marginRight: 20 }}>1.5</Text>
            <Text style={{ ...styles.theKeyword, marginLeft: 12 }}>BILLION</Text>
            <Text style={{ ...styles.font10, marginLeft: 6 }}>Total Credentials</Text>
            <Text style={{ ...styles.font10, marginLeft: 22 }}>Recovered</Text>
          </View>
          <View style={{ marginLeft: 40 }}>
            <Text style={{ ...styles.theKeyword, fontSize: 50, margin: 'auto' }}>854</Text>
            <Text style={{ ...styles.font10, margin: 'auto' }}>Breach Sources Recovered</Text>
          </View>
        </View>
      </Page>
      <Page orientation="landscape" style={styles.body}>
        <Text style={{ ...styles.textStatistics, marginBottom: 30 }}>EXPLOITS</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            marginBottom: 30,
          }}
        >
          <View style={{ marginRight: 20 }}>
            <Image
              style={{ margin: 'auto', height: 80 }}
              src="/prospecting-report/password_icon.png"
            />
            <Text style={{ ...styles.font20, margin: 'auto' }}># of Passwords Exposed</Text>
            <Text style={{ ...styles.font60, margin: 'auto' }}>{data.exploits.passwords}</Text>
          </View>
          <View style={{ marginRight: 20 }}>
            <Image
              style={{ margin: 'auto', height: 80, width: 90 }}
              src="/prospecting-report/emails_exposed_icon.png"
            />
            <Text style={{ ...styles.font20, margin: 'auto' }}># of Emails Exposed</Text>
            <Text style={{ ...styles.font60, margin: 'auto' }}>{data.exploits.emails}</Text>
          </View>
          <View style={{ marginRight: 20 }}>
            <Image
              style={{ margin: 'auto', height: 80, width: 100 }}
              src="/prospecting-report/pii_icon.png"
            />
            <Text style={{ ...styles.font20, margin: 'auto' }}># of PII Exposed</Text>
            <Text style={{ ...styles.font60, margin: 'auto' }}>{data.exploits.pii}</Text>
          </View>
          <View>
            <Image
              style={{ margin: 'auto', height: 80, width: 80 }}
              src="/prospecting-report/sources_icon.png"
            />
            <Text style={{ ...styles.font20, margin: 'auto' }}># of Sources</Text>
            <Text style={{ ...styles.font60, margin: 'auto' }}>{data.exploits.sources}</Text>
          </View>
        </View>
        <View style={{ ...styles.table, marginTop: 50 }}>
          {/* TableHeader */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol20}>
              <Text style={styles.tableHeader}>Severity</Text>
            </View>
            <View style={styles.tableCol80}>
              <Text style={styles.tableHeader}>Description</Text>
            </View>
          </View>
          {/* TableContent */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol20}>
              <Text style={styles.tableCell}>2</Text>
            </View>
            <View style={styles.tableCol80}>
              <Text style={{ ...styles.tableCell, marginLeft: 0 }}>
                Email only severity. This record is part of an email only list
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol20}>
              <Text style={styles.tableCell}>5</Text>
            </View>
            <View style={styles.tableCol80}>
              <Text style={{ ...styles.tableCell, marginLeft: 0 }}>
                Informational Severity. This severity value is given to breach records where we have
                an uncrackable password hash, or no password at all.
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol20}>
              <Text style={styles.tableCell}>20</Text>
            </View>
            <View style={styles.tableCol80}>
              <Text style={{ ...styles.tableCell, marginLeft: 0 }}>
                High severity. This severity value is given to breach records where we have an email
                address and a plaintext password.
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol20}>
              <Text style={styles.tableCell}>25</Text>
            </View>
            <View style={styles.tableCol80}>
              <Text style={{ ...styles.tableCell, marginLeft: 0 }}>
                Critical severity. This severity value is given to breach records recovered from an
                infected machine (botnet data). These records will always have a plaintext password
                and most will have an email address.
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page orientation="landscape" style={styles.body}>
        <Text style={styles.textStatistics}>BREACH DETAILS</Text>
        <Text style={{ fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 10 }}>
          Breach Results for: {data.requestBy}
        </Text>
        <Text style={styles.textDateOfSearch}>Date of Search: {data.breachDate}</Text>
        <View style={styles.table}>
          {/* TableHeader */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Publish Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Email Address</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Password</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Plaintext Password</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Breach Source</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Severity</Text>
            </View>
          </View>
          {/* TableContent */}
          {data.breaches.map(breach => (
            <View key={breach.id} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {breach.breach?.spycloud_publish_date.slice(0, 10) || 'unknown'}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{breach.email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {breach.password_type === 'plaintext' ? breach.password_plaintext : 'encrypted'}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{breach.password_plaintext ? 'yes' : 'no'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{breach.breach?.title || 'unknown'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{breach.severity}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}
