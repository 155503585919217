import MspMenu from '../../components/MspMenu'
import TrainingMaterialComponent from './TrainingMaterialComponent.jsx'
import { useState, useEffect } from 'react'
import { getAllTrainingMaterials } from '../../services/trainingMaterial.service'
import { trainingMaterialErrorHandler } from './errorHandler'

const TrainingMaterialContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [trainingMaterials, setTrainingMaterials] = useState([])

  async function getAllTrainingMaterialsFromAPI() {
    try {
      const response = await getAllTrainingMaterials()
      setTrainingMaterials(response.data.data)
    } catch (error) {
      setShowModal(trainingMaterialErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllTrainingMaterialsFromAPI()
  }, [])

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <TrainingMaterialComponent
          {...{
            showModal,
            handleCloseModal,
            trainingMaterials,
          }}
        />
      </MspMenu>
    </>
  )
}

export default TrainingMaterialContainer
