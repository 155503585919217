import { routes } from '../../config/constants'

export function customersErrorHandler(error) {
  const status = error.response ? error.response.status : error.message
  if (status === 500) {
    return {
      show: true,
      title: 'INTERNAL SERVER ERROR',
      message: "I don't know what happened, try again in a few moments",
    }
  } else if (status === 400) {
    return {
      show: true,
      title: 'BAD REQUEST',
      message: 'The data was rejected by the API',
    }
  } else if (status === 401) {
    return {
      show: true,
      title: 'UNAUTHORIZED',
      message: 'This action could not be authorized, you may need to log in again',
      redirect: routes.LOGIN_ROUTE,
    }
  } else if (status === 'Network Error') {
    return {
      show: true,
      title: 'NETWORK ERROR',
      message: 'Could not connect to the API, please try again in a few moments',
    }
  } else if (status === 406) {
    return {
      show: true,
      title: 'NOT ACCEPTABLE',
      message: error.response.data.message,
    } 
  } else if(status === 402){
    return {
      show: true,
      title: 'ALERT',
      message: error.response.data.message,
    } 
  }else {
    return {
      show: true,
      title: 'ERROR',
      message: status,
    }
  }
}
