import { useEffect } from 'react'
import { useState } from 'react'
import MdwMenu from '../../components/MdwMenu'
import { getAllMSPsService } from '../../services/msp.service'
import { deletePreviousSearchesService } from '../../services/prospectingReport.service'
import ResetProspectingComponent from './ResetProspectingComponent'
import Modal from '../../components/Modal'
/* import { useEffect, useState } from 'react'
import { getAllMSPsService } from '../../services/msp.service'
import { adminDashboardErrorHandler } from './errorHandler'
import { getPreviousAllSearchesCountService } from '../../services/prospectingReport.service' */

const AdminProspectingContainer = () => {
  const [ MSPsData, setMSPsData ] = useState( {} )
  const [showModal, setShowModal] = useState({})

  async function getAllMSPsFromAPI() {
    try {
      const MSPs = await getAllMSPsService(true)
      setMSPsData({ ...MSPs.data.data })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllMSPsFromAPI()
  }, [] )
  
  const handleCloseModal = () => {
    setShowModal({})
  }

  async function handleReset(mspId, companyName) {
    try {
      let response = await deletePreviousSearchesService(mspId)
      if (response.data.message === 'Ok' && response.data.status === true) {
        setShowModal({
          show: true,
          title: `Reset ${companyName} prospecting report`,
          message: `${companyName} prospecting report searched data is deleted successfully.`,
        })
      } else {
        setShowModal({
          show: true,
          title: `ERROR`,
          message: response.data.message ? response.data.message.toString()  : 'Internal Server Error. Something went wrong.',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <MdwMenu>
        <ResetProspectingComponent
          MSPs={MSPsData.msps}
          handleReset={handleReset}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      </MdwMenu>
    </>
  )
}

export default AdminProspectingContainer
