import './AdminDashboardStyles.scss'
import { motion } from 'framer-motion'
import AdminBanner from '../../components/AdminBanner'
import IconWithInfo from '../../components/IconWithInfo'
import SecureIcon from '../../assets/icons/secure.svg'
import DomainsIcon from '../../assets/icons/domains.svg'
import EmailsIcon from '../../assets/icons/emails.svg'
import SpecialIcon from '../../assets/icons/special.svg'
import ProspectingIcon from '../../assets/icons/prospecting.svg'
import SecureWhiteIcon from '../../assets/icons/secureWhite.svg'
import UpgradeIcon from '../../assets/icons/upgrade.svg'
import FeaturesIcon from '../../assets/icons/features.svg'
import NewsIcon from '../../assets/icons/news.svg'
import ReportsIcon from '../../assets/icons/reports'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'

const AdminDashboardComponent = ({ icons, showModal, handleCloseModal }) => {
  return (
    <>
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Modal
          showModal={showModal.show}
          handleCloseModal={handleCloseModal}
          title={showModal.title}
          message={showModal.message}
          redirect={showModal.redirect}
        />
        <AdminBanner title="MSP DARK WEB DASHBOARD" close={false} />
        <div className="admin-dashboard-container">
          <div className="admin-dashboard-icons-header">
            <IconWithInfo
              text={icons.msps}
              bottomText={"MSP's"}
              textSize={14}
              bottomTextSize={16}
              icon={SecureIcon}
              width={50}
              height={50}
              style={{ marginRight: 5 }}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.domains}
              bottomText={'Domains'}
              textSize={14}
              bottomTextSize={16}
              icon={DomainsIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.emails}
              bottomText={'Emails'}
              textSize={14}
              bottomTextSize={16}
              icon={EmailsIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.special}
              bottomText={'Special'}
              textSize={14}
              bottomTextSize={16}
              icon={SpecialIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.prospecting}
              bottomText={'Prospecting'}
              textSize={14}
              bottomTextSize={16}
              icon={ProspectingIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.pending}
              bottomText={'Pending'}
              textSize={14}
              bottomTextSize={16}
              icon={SecureWhiteIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.upgrade}
              bottomText={'Upgrade'}
              textSize={14}
              bottomTextSize={16}
              icon={UpgradeIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={icons.features}
              bottomText={'Features'}
              textSize={14}
              bottomTextSize={16}
              icon={FeaturesIcon}
              width={50}
              height={50}
              color="tertiary"
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Link to={adminRoutes.ADD_NEWS_ROUTE}>
              <div className="admin-dashboard-news-icon">
                <img src={NewsIcon} width={33.33} />
                <p>NEWS</p>
              </div>
            </Link>
            <Link to={adminRoutes.RESET_PROSPECTING_ROUTE}>
              <div className="admin-dashboard-news-icon">
                <ReportsIcon width={33.33} height={33.33} />
                <p>RESET</p>
              </div>
            </Link>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AdminDashboardComponent
