import MspMenu from '../../components/MspMenu'
import CustomerDetailsComponent from './CustomerDetailsComponent.jsx'
import { customersErrorHandler } from './errorHandler'
import { useState, useEffect } from 'react'
import {
  deleteCustomerService,
  getCustomerService,
  updateCustomerService,
} from '../../services/customer.service'
import { schema } from '../../schemas/customer.schema'
import { useHistory } from 'react-router-dom'
import { notAllowedDomains, routes } from '../../config/constants'
import { resetFirstBreachService } from '../../services/firstBreach.service'

const CustomerDetailsContainer = ({ match }) => {
  const history = useHistory()
  const CustomerId = match.params.id
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [showModal, setShowModal] = useState({})
  const [table, setTable] = useState([])
  const initialValues = {
    companyName: '',
    firstName: '',
    lastName: '',
    reportEmail: '',
    industry: '',
    country: '',
    status: false,
    customerReport: false,
    totalDomains: 0,
    totalEmails: 0,
    reportFrequency: '',
    domains: [],
    emails: [],
  }
  const [customer, setCustomer] = useState(initialValues)

  useEffect(() => {
    async function getCustomerFromAPI() {
      try {
        const response = await getCustomerService(CustomerId)
        const customerData = response.data.data

        customerData.domains = customerData.domains.map(domain => domain.domain)
        customerData.emails = customerData.emails.map(email => email.email)

        let domains = customerData.domains.map(domain => ({ value: domain, new: false }))
        let emails = customerData.emails.map(email => ({ value: email, new: false }))

        setTable([...domains, ...emails])
        setCustomer({ ...customerData })
      } catch (error) {
        setShowModal(customersErrorHandler(error))
      }
    }
    getCustomerFromAPI()
  }, [])

  function handleClickDelete(selectedString, id) {
    setTable(table.filter(emailOrDomain => emailOrDomain.value !== selectedString))
    if (id === 'emails') {
      const updatedEmails = customer.emails.filter(email => email !== selectedString)
      setCustomer({ ...customer, emails: [...updatedEmails] })
    } else if (id === 'domains') {
      const updatedDomains = customer.domains.filter(domain => domain !== selectedString)
      setCustomer({ ...customer, domains: [...updatedDomains] })
    }
  }

  function handleOnClickAdd(id) {
    // To lowercase and delete blank spaces in the text
    const value = document.getElementById(id).value.toLowerCase().replace(/\s+/g, '')
    if (id === 'domains') {
      if (customer.domains.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER DOMAIN TO SEARCH',
          message: `You entered "${value}" this domain is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (
          !/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
            value
          )
        ) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `You entered "${value}" which is not a valid domain`,
          })
          document.getElementById(id).value = ''
        } else if (notAllowedDomains.includes(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `The domain ${value} is in a list of disallowed domains`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, { value, new: true }])
          if (value !== '') setCustomer({ ...customer, [id]: [...customer[id], value] })
          document.getElementById(id).value = ''
        }
      }
    } else {
      if (customer.emails.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER EMAIL TO SEARCH',
          message: `You entered "${value}" this email is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,25}$/.test(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID EMAIL TO SEARCH',
            message: `You entered "${value}" which is not a valid email`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, { value, new: true }])
          if (value !== '') setCustomer({ ...customer, [id]: [...customer[id], value] })
          document.getElementById(id).value = ''
        }
      }
    }
  }

  function handleOnChange(e) {
    const { id, value, type } = e.target
    if (type === 'checkbox') {
      const { checked } = e.target
      if(id==='status' && !checked){
        setCustomer((prev)=>{
       return { ...prev, 'reportStatus': false }
      })
      }
      setCustomer((prev)=>{
       return { ...prev, [id]: !customer[id] }
        })
    } else if (id === 'reportEmail') {
      setCustomer({ ...customer, [id]: value.replace(/\s/g, '').trim()})
    } else{
      setCustomer({ ...customer, [id]: value })
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      customer.reportEmail = customer.reportEmail.toLocaleLowerCase()
      await schema.validate(customer, { abortEarly: false })
      setIsLoading(true)
      await updateCustomerService(customer, CustomerId)
      setShowModal({
        show: true,
        title: 'CUSTOMER HAS BEEN UPDATED',
        message: 'Your customer details have been saved successfully',
        redirect: routes.CUSTOMERS_ROUTE,
      })
      setIsLoading(false)
    } catch (error) {
            setIsLoading(false)
      if (error.inner) {
                const newErrorObject = {}
        error.inner.forEach(e => {
          if (e.path.includes('domains')) e.path = 'domains'
          if (e.path.includes('emails')) e.path = 'emails'
          newErrorObject[e.path] = e.message
        })
        setErrors({ ...newErrorObject })
      } else {
        setShowModal(customersErrorHandler(error))
      }
    }
  }

  async function handleDeleteCustomer(id) {
    try {
      if (
        window.confirm('Are you sure you want to remove the customer? This action is irreversible')
      ) {
        await deleteCustomerService(id)
        setTimeout(() => {
          history.replace(routes.CUSTOMERS_ROUTE)
        }, 1000)
        setShowModal({
          show: true,
          title: 'CUSTOMER HAS BEEN DELETED',
          message: 'The customer has been deleted successfully',
        })
      }
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  async function handleResetDomainOrEmail(domainOrEmail) {
    try {
      await resetFirstBreachService(domainOrEmail, CustomerId)
      setShowModal({
        show: true,
        title: 'HAS BEEN SUCCESSFULLY UPDATED',
        message: `The initial scan of ${domainOrEmail} has been updated successfully`,
      })
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <CustomerDetailsComponent
          isLoading={isLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          customer={customer}
          errors={errors}
          handleSubmit={handleSubmit}
          handleOnChange={handleOnChange}
          handleClickDelete={handleClickDelete}
          handleOnClickAdd={handleOnClickAdd}
          handleDeleteCustomer={handleDeleteCustomer}
          table={table}
          handleResetDomainOrEmail={handleResetDomainOrEmail}
        />
      </MspMenu>
    </>
  )
}

export default CustomerDetailsContainer
