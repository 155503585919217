import * as Yup from 'yup'

export const schema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  emailAddress: Yup.string()
    .email('Must be a valid email')
    .required('Email address is required'),
  status: Yup.boolean('Must be a boolean').required('Status is required'),
  resetPassword: Yup.boolean('Must be a boolean').required(
    'Reset password is required'
  )
})
