import React, { useState } from 'react';
import { Accordion, Card, Table, Button, Form } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa';
import { createAccordionData } from '../../utilities/accordin';
import { useEffect } from 'react';
import './AdminRateLimit.scss'
import { deleteBatch, pauseBatch } from '../../services/rateLimit.service';
import Modal from '../../components/Modal'
import AdminBanner from '../../components/AdminBanner';

const AccordionTable = ({ breachResponse, fetchUpdate }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [data, setData] = useState([])
  const [modelData, setModelData] = useState({
    isOpen: false,
    title: 'Success',
    message: 'Data has been submitted',
  })

  useEffect(() => {
    if (breachResponse?.data?.length) {
      const response = createAccordionData(breachResponse.data);
      setData(response);
    }
  }, [breachResponse?.data]);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleCheckboxClick = async (e, id) => {
    if (e.target.checked) {
      setModelData({
        isOpen: true,
        title: 'Batch Process Confirmation',
        message: 'Are you sure you want to pause the batch',
        functionName: pauseBatchData,
        data: { value: true, id: id }
      })
    } else {
      setModelData({
        isOpen: true,
        title: 'Batch Process Confirmation',
        message: 'Are you sure you want to resume the batch',
        functionName: pauseBatchData,
        data: { value: false, id: id }
      })
    }
  }
  const pauseBatchData = async (data) => {
    await pauseBatch({ pauseBatch: data?.value }, data?.id)
    setModelData({
      isOpen: false,
    })
    fetchUpdate();
  }

  const handleDelete = (id) => {
    setModelData({
      isOpen: true,
      title: 'Batch Process Confirmation',
      message: 'Are you sure you want to delete the batch',
      functionName: removeBatch,
      data: id
    })
  }
  const removeBatch = async (id) => {
    await deleteBatch(id)
    setModelData({
      isOpen: false,
    })
    fetchUpdate();
  }
  return (
    <>
      <Modal
        showModal={modelData.isOpen}
        handleCloseModal={() => modelData.functionName(modelData.data)}
        title={modelData.title}
        message={modelData.message}
        closeBtn={true}
        handleClose={() => setModelData({ isOpen: false })}
      />
          <div className="admin-rate-limit-green-bar">Batch</div>
          <Accordion activeKey={activeKey} style={{ padding: '20px' }}>
        {data.map((item, index) => (
          <Card key={index}>
            <Card.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={index.toString()}
                  onClick={() => handleToggle(index.toString())}
                  style={{ flex: 1, textAlign: 'left' }}
                >
                  {item.header}
                  {activeKey === index.toString() ? <FaArrowUp style={{ marginLeft: '10px' }} /> : <FaArrowDown style={{ marginLeft: '10px' }} />}
                </Accordion.Toggle>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginBottom: '0' }}>
                  <div style={{marginRight:'10px'}}>
                  {"Date:"+item.batchDate}
                  </div>
                  <input
                    className='checkbox'
                    disabled
                    checked={item.processed}
                    type="checkbox"
                    id={`processed-${item.id}`}
                  />
                  <label className='label' htmlFor="processed" style={{ marginLeft: '5px' }}>Processed</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginBottom: '0' }}>
                  <input
                    className='checkbox'
                    type="checkbox"
                    disabled={item.processed}
                    id={`pause-${item.id}`}
                    checked={item.pause}
                    onChange={(e) => handleCheckboxClick(e, item.id)}
                  />
                  <label className='label' htmlFor={`pause-${item.id}`} style={{ marginLeft: '5px' }}>Pause</label>
                </div>
                <Button variant="white" onClick={() => handleDelete(item.id)}>
                  <FaTrash color='red' />
                </Button>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>To</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>MSP Name</th>
                      <th>Customer Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, index) => (
                      <tr key={row.to + index}>
                        <td>{row?.to}</td>
                        <td>{row?.firstName}</td>
                        <td>{row?.lastName}</td>
                        <td>{row?.companyName}</td>
                        <td>{row?.customerName}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
};

export default AccordionTable;
