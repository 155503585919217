import './AdminAdminStyles.scss'
import AdminBanner from '../../components/AdminBanner'
import AdminAddPaymentMethods from '../../components/AdminAddPaymentMethods'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'
import { motion } from 'framer-motion'

export default function AdminAdminComponent({
  formik,
  paymentMethods,
  showModal,
  showPaymentMethods,
  handleCloseModal,
  handleDeletePaymentMethod,
  handleClickPaymentMethods,
  handleClosePaymentMethods,
}) {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <AdminAddPaymentMethods
        formik={formik}
        id="name"
        paymentMethods={paymentMethods}
        show={showPaymentMethods}
        onClose={handleClosePaymentMethods}
        handleDelete={handleDeletePaymentMethod}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <AdminBanner title="ADMIN" />

        <div className="admin-admin-container">
          <p className="admin-admin-text" onClick={handleClickPaymentMethods}>
            Payment Methods
          </p>
          <Link to={adminRoutes.DASHBOARD_CONTENT_ROUTE}>
            <p className="admin-admin-text">Dashboard Content</p>
          </Link>
          <Link to={adminRoutes.TRAINING_MATERIAL_ROUTE}>
            <p className="admin-admin-text">Training Material</p>
          </Link>
          <Link to={adminRoutes.RATE_LIMIT_ROUTE}>
            <p className="admin-admin-text">Rate Limit</p>
          </Link>
        </div>
      </motion.div>
    </>
  )
}
