import AdminUsersComponent from './AdminUsersComponent.jsx'
import { useState, useEffect } from 'react'
import {
  addMdwUserService,
  updateMdwUserService,
  getMdwUserService,
  deleteMdwUserService,
  getAllMdwUsersService,
} from '../../../services/mdwUser.service'
import { adminUserErrorHandler } from '../errorHandler'
import { schema } from '../../../schemas/adminUser.schema'
import { useFormik } from 'formik'
import { adminRoutes } from '../../../config/constants'
import { useHistory } from 'react-router-dom'
import { getMyUserService } from '../../../services/user.service'

const AdminUsersContainer = ({ match }) => {
  const UserId = match.params.id
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [showModalConfirm, setShowModalConfirm] = useState({});
  const [users, setUsers] = useState([])
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    status: true,
    resetPassword: false,
  })
  const [show, setShow] = useState({})
  const [account, setAccount] = useState({})
  const [userType, setUserType] = useState('')

  useEffect( () => {
    async function getUserFromAPI() {
      if (UserId && userType === 'MDW') {
        try {
          const user = await getMdwUserService( UserId, userType )
          setInitialValues( prevState => ( { ...prevState, ...user.data.data } ) )
        } catch (error) {
          setShowModal(adminUserErrorHandler(error))
        }
      } else {
        setUserType("")
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: true,
          resetPassword: false,
        })
      }
    }

    async function getMyUserFromAPI() {
      try {
        const response = await getMyUserService()
        setAccount(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }

    getMyUserFromAPI()
    getUserFromAPI()
  }, [UserId])

  async function getAllUsersFromAPI() {
    try {
      const allUsers = await getAllMdwUsersService()
      setUsers(allUsers.data.data.mdwUser)
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllUsersFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async data => {
      try {
        setIsLoading(true)
        if (UserId && userType ) {
          const response = await updateMdwUserService(data, UserId, userType)
          const { username } = response.data.data
          await getAllUsersFromAPI()
          setShowModal({
            show: true,
            title: 'MDW USER HAS BEEN UPDATED',
            message: `The user ${username} has been updated successfully`,
            redirect: adminRoutes.USERS_ROUTE,
          })
        } else {
          data.userType = 'MDW';
          const response = await addMdwUserService(data)
          const { username } = response.data.data
          setShowModal({
            show: true,
            title: 'MDW USER HAS BEEN CREATED',
            message: `The user ${username} has been created successfully`,
          })
          getAllUsersFromAPI()
        }
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: false,
          resetPassword: false,
        })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(adminUserErrorHandler(error))
      }
    },
  })

async function handleStatusChange(e) {
  try {
    const userIdType = 'MDW'
    const data = {}
    const { id } = e.target
    if (id !== account.userId) {
      const user = users.find( user => user.userId === id )
      data.status= !user.status
      await updateMdwUserService(data, id, userIdType)
      getAllUsersFromAPI()
    } else {
      setShowModal({
        show: true,
        title: 'YOU CANNOT DISABLE THIS USER',
        message: 'You cannot disable this user because it is this account',
      })
    }
  } catch (error) {
    setShowModal(adminUserErrorHandler(error))
  }
}

async function handleDelete(id) {
  try {
    const userIdType = 'MDW'
    await deleteMdwUserService(id, userIdType)
    getAllUsersFromAPI()
    handleClose()
  } catch (error) {
    setShowModal(adminUserErrorHandler(error))
  }
}

  function handleClickUser(e) {
    const { id } = e.target
    const user = 'MDW'
    setUserType(user)
    history.push(adminRoutes.USERS_ROUTE + '/' + id)
  }

  async function handleResetPassword(id) {
    try {
      const userIdType = 'MDW'
      const user = getUserDataByID(id)
      user.resetPassword = true
      await updateMdwUserService(user, id, userIdType)
      setShowModal({
        show: true,
        title: 'PASSWORD CHANGE REQUEST SENT',
        message: 'The user will receive an email with the link to reset their password',
      } )
      handleClose()
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  async function handleReset2fa(id) {
    try {
      const userIdType = 'MDW'
      await updateMdwUserService({ reset2faCode: true }, id, userIdType)
      setShowModal({
        show: true,
        title: '2FA CODE RESET SENT',
        message: "User's 2fa code has been reset",
      } )
      handleClose()
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  const getUserDataByID = id => {
    const user = users.find(user => user.userId === id)
    return user
  }

  const handleShow = (id, key) => {
    const user = getUserDataByID(id)
    if (key === 'deleteUser') {
      console.log('deleteUser__', id, key)
      setShow({
        showModal: true,
        title: 'Delete User Confirmation',
        message: 'Are you sure you want to remove the user?',
        handleOk: handleDelete,
        id,
      })
    } else if (key === 'reset2FA') {
      console.log('reset2FA__', id, key)
      setShow({
        showModal: true,
        title: 'Reset 2FA User Confirmation',
        message: `Are you sure you want to reset 2FA of user ${user.firstName} ${user.lastName}?`,
        handleOk: handleReset2fa,
        id,
      })
    } else if (key === 'resetPassword') {
      console.log('resetPassword__', id, key)
      setShow({
        showModal: true,
        title: 'Reset Password User Confirmation',
        message: `Are you sure you want to reset password of user ${user.firstName} ${user.lastName}?`,
        handleOk: handleResetPassword,
        id,
      })
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <AdminUsersComponent
        account={account}
        formik={formik}
        isLoading={isLoading}
        showModal={showModal}
        showModalConfirm={showModalConfirm}
        handleCloseModal={handleCloseModal}
        users={users}
        UserId={UserId}
        handleStatusChange={handleStatusChange}
        handleDelete={handleDelete}
        handleClickUser={handleClickUser}
        handleResetPassword={handleResetPassword}
        handleReset2fa={handleReset2fa}
        userType={userType}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show} 
      />
    </>
  )
}

export default AdminUsersContainer
