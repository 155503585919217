import MspMenu from '../../components/MspMenu'
import IntegrationsComponent from './IntegrationsComponent.jsx'

const IntegrationsContainer = () => {
  return (
    <>
      <MspMenu>
        <IntegrationsComponent />
      </MspMenu>
    </>
  )
}

export default IntegrationsContainer
