import FormButton from '../../components/FormButton'

const BrandingLogoComponent = ({ img, onChangeImg, onDeleteImg, submitLogo, isLoading }) => {
  return (
    <>
      <div className="branding-logo-container">
        <h3 className="branding-logo-container-title">Report Logo</h3>
        <p className="branding-logo-container-text">
          Upload your logo here to whitelabel all reports.
          <br />
          <br /> Your logo will then appear on all your reports.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {img ? (
            <img className="brandind-logo-preview" src={img} />
          ) : (
            <div className="branding-logo-input-logo">Preview your logo here</div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <label className="branding-logo-input-file">
              <input onChange={onChangeImg} accept="image/*" type="file" id="logo" />
              UPLOAD
            </label>
            <FormButton
              onClick={onDeleteImg}
              style={{ display: 'block' }}
              width={225}
              label="REMOVE"
              outline
            />
          </div>
        </div>
      </div>
      <div className="text-right" style={{ marginTop: 7 }}>
        <FormButton onClick={submitLogo} width={100} label={isLoading ? 'LOADING...' : 'SAVE'} />
      </div>
    </>
  )
}

export default BrandingLogoComponent
