import Axios from '../config/Axios'

export const getUtilizationReportService = async () => {
  const response = await Axios.get('/report/utilization')
  return response
}

export const getSpycloudReportService = async () => {
  const response = await Axios.get('/report/spycloud')
  return response
}
