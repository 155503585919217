import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdminBanner from '../../components/AdminBanner'
import { adminRoutes } from '../../config/constants'
import Modal from '../../components/Modal'


export default function ResetProspectingComponent({ MSPs, handleReset, showModal, handleCloseModal }) {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />

      <AdminBanner title="RESET PROSPECTING REPORTS" />

      <div style={{ width: '50%' }} className="admin-msps-container">
        <Table className="admin-msps-table" borderless hover striped>
          <thead>
            <tr>
              <th>COMPANY</th>
              <th>CONTACT</th>
              <th>RESET PROSPECTING</th>
            </tr>
          </thead>
          <tbody>
            {MSPs?.map(MSP => (
              <tr key={MSP.mspId}>
                <td>
                  <Link
                    to={adminRoutes.EDIT_MSP_ROUTE + MSP.mspId}
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    {MSP.companyName}
                  </Link>
                </td>
                <td>{`${MSP.pcFirstName} ${MSP.pcLastName}`}</td>
                <td className="text-center">
                  <span
                    onClick={() => {
                      handleReset(MSP.mspId, MSP.companyName)
                    }}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    reset
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}
