import MdwMenu from '../../components/MdwMenu'
import AdminDashboardComponent from './AdminDashboardComponent.jsx'
import { useEffect, useState } from 'react'
import { getAllMSPsServiceCount } from '../../services/msp.service'
import { adminDashboardErrorHandler } from './errorHandler'
import { getPreviousAllSearchesCountService } from '../../services/prospectingReport.service'

const AdminDashboardContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [MSPsData, setMSPsData] = useState({})

  useEffect(() => {
    async function getAllMSPsFromAPI() {
      try {
        const MSPs = await getAllMSPsServiceCount()
        const previousSearchesCount = await getPreviousAllSearchesCountService()
        setMSPsData({ ...MSPs.data.data, prospecting: previousSearchesCount.data.data })
      } catch (error) {
        setShowModal(adminDashboardErrorHandler(error))
      }
    }
    getAllMSPsFromAPI()
  }, [])

  const icons =
    Object.keys(MSPsData).length !== 0
      ? {
          msps: MSPsData.msps,
          domains: MSPsData.domains,
          emails: MSPsData.emails,
          special: MSPsData.specials,
          quoting: MSPsData.quotings,
          pending: MSPsData.pendings,
          upgrade: MSPsData.upgrades,
          features: MSPsData.features,
          prospecting: MSPsData.prospecting,
        }
      : {
          msps: '...',
          domains: '...',
          emails: '...',
          special: '...',
          quoting: '...',
          pending: '...',
          downgrade: '...',
          upgrade: '...',
          features: '...',
          prospecting: '...',
        }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminDashboardComponent
          icons={icons}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      </MdwMenu>
    </>
  )
}

export default AdminDashboardContainer
