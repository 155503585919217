import './AdminFeaturesStyles.scss'
import { Table } from 'react-bootstrap'
import { motion } from 'framer-motion'
import AdminBanner from '../../components/AdminBanner'
import AdminFeatureDetails from '../../components/AdminFeatureDetails'
import AdminFeatureCategories from '../../components/AdminFeatureCategories'
import PlusIcon from '../../assets/icons/plusWhite.svg'
import Modal from '../../components/Modal'

const AdminFeaturesComponent = ({
  onChangeCategory,
  showModal,
  handleCloseModal,
  onChangeStatus,
  featureRequests,
  statuses,
  categories,
  categoryFormik,
  handleDeleteCategory,
  isLoading,
  showFeatureDetails,
  showFeatureCategories,
  handleFeatureDetailsClick,
  handleFeatureCategoriesClick,
  handleOnCloseFeatureDetails,
  handleOnCloseFeatureCategories,
  handleOnClickDelete,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <AdminFeatureDetails
        statuses={statuses}
        categories={categories}
        onChangeStatus={onChangeStatus}
        onChangeCategory={onChangeCategory}
        featureRequest={showFeatureDetails.data}
        show={showFeatureDetails.show}
        onClose={handleOnCloseFeatureDetails}
      />
      <AdminFeatureCategories
        categories={categories}
        formik={categoryFormik}
        handleDeleteCategory={handleDeleteCategory}
        inputId="name"
        isLoading={isLoading}
        show={showFeatureCategories}
        onClose={handleOnCloseFeatureCategories}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <AdminBanner title="FEATURES" />
        <div className="admin-features-container">
          <Table className="admin-features-table" borderless hover>
            <thead>
              <tr>
                <th>MSP</th>
                <th style={{ width: 120 }}>REQ DATE</th>
                <th>TITLE</th>
                <th>DESCRIPTION</th>
                <th style={{ width: 150 }}>STATUS</th>
                <th
                  className="admin-features-cursor-pointer text-nowrap"
                  onClick={handleFeatureCategoriesClick}
                >
                  CATEGORY{' '}
                  <img
                    style={{ marginBottom: 5 }}
                    src={PlusIcon}
                    alt="Plus icon"
                    width={15}
                    height={15}
                  />
                </th>
                <th>+1</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {featureRequests?.map(featureRequest => (
                <tr
                  key={featureRequest.id}
                  className="admin-features-cursor-pointer"
                  onClick={() => handleFeatureDetailsClick(featureRequest.id)}
                >
                  <td>{featureRequest.msp?.companyName || 'MSP DARK WEB'}</td>
                  <td>{featureRequest.createdAt.slice(0, -14)}</td>
                  <td>{featureRequest.title}</td>
                  <td>{featureRequest.description}</td>
                  <td>{featureRequest.featureRequestStatus?.name || 'Waiting...'}</td>
                  <td>{featureRequest.featureRequestCategory?.name}</td>
                  <td>{featureRequest.requests}</td>
                  <td>
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        handleOnClickDelete(featureRequest.id)
                      }}
                      style={{ textDecoration: 'underline' }}
                    >
                      delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </motion.div>
    </>
  )
}

export default AdminFeaturesComponent
