import './UsersStyles.scss'
import { Table, Form } from 'react-bootstrap'
import { motion } from 'framer-motion'
import Banner from '../../components/Banner'
import FormInput from '../../components/FormInput'
import FormButton from '../../components/FormButton'
import FormCheckbox from '../../components/FormCheckbox'
import Modal from '../../components/Modal'
import { routes } from '../../config/constants'

const UsersComponent = ({
  formik,
  isLoading,
  showModal,
  handleCloseModal,
  users,
  UserId,
  handleStatusChange,
  handleDelete,
  handleClickUser,
  handleResetPassword,
  handleReset2fa,
  account,
}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <Banner title="USERS" redirect={UserId && routes.USERS_ROUTE} />
      <div className="users-container">
        <h3 className="users-subtitle">{UserId ? 'Edit User' : 'Add User'}</h3>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="users-inputs-container">
            <FormInput
              id="firstName"
              formik={formik}
              className="users-input"
              label="First Name"
              color="primary"
            />
            <FormInput
              id="lastName"
              formik={formik}
              className="users-input"
              label="Last Name"
              color="primary"
            />
            <FormInput
              id="emailAddress"
              formik={formik}
              className="users-input"
              label="Email Address"
              color="primary"
            />
            <FormButton
              type="submit"
              label={isLoading ? 'LOADING...' : UserId ? 'SAVE' : 'ADD'}
              width={100}
            />
          </div>
        </Form>
        <Table className="users-table" borderless hover striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email Address</th>
              <th className="text-center">2FA</th>
              <th className="text-center">Password</th>
              <th className="text-center">Active</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.userId}>
                <td>
                  <span
                    id={user.userId}
                    onClick={handleClickUser}
                    style={{ cursor: 'pointer' }}
                  >{`${user.firstName} ${user.lastName}`}</span>
                </td>
                <td>{user.username}</td>
                <td>{user.emailAddress}</td>
                <td className="text-center users-table-reset">
                  <span id={user.userId} onClick={handleReset2fa}>
                    reset
                  </span>
                </td>
                <td className="text-center users-table-reset">
                  <span id={user.userId} onClick={handleResetPassword}>
                    reset
                  </span>
                </td>
                <td className="text-center">
                  <FormCheckbox
                    id={user.userId}
                    checked={user.status}
                    onChange={handleStatusChange}
                  />
                </td>
                <td className="text-center users-table-delete">
                  {(account.username !== user.username && user.role !== 'MSP') && (
                    <span id={user.userId} onClick={handleDelete}>
                      delete
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </motion.div>
  )
}

export default UsersComponent
