import './TwoFAStyles.scss'
import Navbar from '../../components/Navbar'
import { Form, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Modal from '../../components/Modal'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'
import FormButton from '../../components/FormButton'

const renderTooltip = props => <Tooltip {...props}>Copy to clipboard</Tooltip>

const TwoFAComponent = ({
  formik,
  isLoading,
  handleCloseModal,
  showModal,
  qrCodeUrl,
  secretCode,
  handleCopyCode,
  handleReset2FA,
}) => {
  return (
    <>
      <Navbar logo={Logo} />
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <div style={{ marginTop: 120 }}>
        {qrCodeUrl && (
          <div>
            <h3 style={{ textAlign: 'center' }}>
              Please scan this image with your preferred 2FA Application
            </h3>
            <img
              src={qrCodeUrl}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              alt="QR Code"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <OverlayTrigger
                placement="right"
                style={{ backgroundColor: 'green' }}
                delay={{ show: 200, hide: 400 }}
                overlay={renderTooltip}
              >
                <h5 style={{ cursor: 'pointer' }} onClick={handleCopyCode}>
                  Secret code:{' '}
                  {secretCode
                    ?.toLowerCase()
                    ?.match(/.{1,4}/g)
                    ?.join(' ')}
                </h5>
              </OverlayTrigger>
            </div>
          </div>
        )}
        <div className="TwoFact__container">
          <Form noValidate onSubmit={formik.handleSubmit} className="form-col border">
            <Row style={{ margin: 'auto' }}>
              <Col>
                <Form.Group controlId="code" style={{ position: 'relative' }}>
                  <Form.Control
                    type="password"
                    placeholder="2FA PASSWORD"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.code}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {formik.errors.code}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="TwoFact__submit row">
                  <div className="twodact__btn__link">
                    <FormButton
                      width={150}
                      type="submit"
                      label={!isLoading ? 'LOGIN' : 'LOADING...'}
                      mdw
                    />
                  </div>
                </div>
                {!secretCode && (
                  <div className="text-center twoFA-reset-button" onClick={handleReset2FA}>
                    Reset 2FA
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
}

export default TwoFAComponent
