import Axios from '../config/Axios'

export const getAllResetTwofaRequestsService = async () => {
  return await Axios.get('/twofa/reset/all')
}

export const addResetTwofaRequestService = async () => {
  return await Axios.post('/twofa/reset')
}

export const resetTwofaRequestService = async (data) => {
  return await Axios.post('/twofa/reset/resetTwoFa',data)
}

export const updateResetTwofaRequestService = async (id, data) => {
    return await Axios.put(`/twofa/reset/${id}`, data)
  }

export const deleteResetTwofaRequestService = async id => {
  return await Axios.delete(`/twofa/reset/${id}`)
}
