import React from 'react'
import './IconWithInfo.scss'

const IconWithInfo = ({
  text,
  bottomText,
  icon,
  width,
  height,
  style,
  filter,
  textSize,
  bottomTextSize,
  color,
}) => {
  if (!color) {
    return null
  }

  return (
    <div className="icon-container" style={style}>
      <img
        className="icon"
        src={icon}
        alt={bottomText + ' icon'}
        width={width}
        height={height}
        style={{ filter }}
      />
      <div className={`icon-${color}-color green-box`}>
        <p className="text" style={{ fontSize: textSize }}>
          {text}
        </p>
      </div>
      <p className="bottom-text text-nowrap" style={{ fontSize: bottomTextSize }}>
        {bottomText}
      </p>
    </div>
  )
}

export default IconWithInfo
