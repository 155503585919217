import TwoFAComponent from './TwoFAComponent.jsx'
import { useFormik } from 'formik'
import { loginTwoFa, getQrCode, getSecretCode } from '../../services/auth.service'
import {
  addResetTwofaRequestService,
  resetTwofaRequestService,
} from '../../services/resetTwofaRequest.service'
import { useState, useEffect } from 'react'
import { schema } from '../../schemas/2fa.schema'
import { useHistory } from 'react-router-dom'
import { twoFaErrorHandler } from './errorHandler'
import { adminRoutes, routes } from '../../config/constants'
import { getMyMspLightLogoService } from '../../services/msp.service.js'

const TwoFAContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [qrCodeUrl, setQrCodeUrl] = useState(false)
  const [secretCode, setSecretCode] = useState('')
  const history = useHistory()

  async function getQrCodeDataFromAPI() {
    try {
      const QR = await getQrCode()
      if (QR.headers['content-type'] === 'image/png') {
        setQrCodeUrl(`data:image/png;base64, ${QR.image}`)
      }
      const secretCode = await getSecretCode()
      setSecretCode(secretCode.data.data)
    } catch (error) {
      setShowModal(twoFaErrorHandler(error))
    }
  }

  useEffect(() => {
    getQrCodeDataFromAPI()
  }, [])

  const initialValues = {
    code: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        const twoFaResponse = await loginTwoFa(data)
        const { token, role } = twoFaResponse.data.data
        localStorage.setItem('2fa-token', token)
        localStorage.setItem('role', role)
        sessionStorage.setItem('sessionStatus', true);
        if (!role && !token) history.replace(routes.LOGIN_ROUTE)
        if (role === 'MSP' || role === 'MSP_USER') {
          const logoResponse = await getMyMspLightLogoService()
          const logoUrl = logoResponse.data.data.lightLogo
          localStorage.setItem('logo', logoUrl)
          history.replace(routes.DASHBOARD_ROUTE)
        }
        if (role === 'MDW') history.replace(adminRoutes.DASHBOARD_ROUTE)
      } catch (error) {
        setIsLoading(false)
        setShowModal(twoFaErrorHandler(error))
        actions.resetForm()
      }
    },
  })

  async function handleReset2FA() {
    try {
      await resetTwofaRequestService({ status: true })
      setShowModal({
        show: true,
        title: 'REQUEST ACCEPTED',
        message: 'The request is accepted and approved',
      })
    } catch (error) {
      setShowModal(twoFaErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  function handleCopyCode() {
    navigator.clipboard.writeText(secretCode)
  }

  return (
    <>
      <TwoFAComponent
        isLoading={isLoading}
        formik={formik}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        qrCodeUrl={qrCodeUrl}
        secretCode={secretCode}
        handleCopyCode={handleCopyCode}
        handleReset2FA={handleReset2FA}
      />
    </>
  )
}

export default TwoFAContainer
