import MspMenu from '../../components/MspMenu'
import BreachesComponent from './BreachesComponent.jsx'
import { useEffect, useState, useRef } from 'react'
import { getAllBreachesService, sendABreachAlertService } from '../../services/breach.service'
import { decryptPassword, hashPassword, handleCheckboxClick } from '../../utilities/decryptPass'

const BreachesContainer = () => {
  const initialValues = {
    domain: '',
    email: '',
  }
  const [allBreaches, setAllBreaches] = useState([])
  const [totalData, setTotalData] = useState(0)
  const [breaches, setBreaches] = useState([])
  const [values, setValues] = useState(initialValues)
  const [showModal, setShowModal] = useState({})
  const [checked, setChecked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true)
  const [paramsData, setParamsData] = useState({
    email: '',
    domain: ''
  })

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClearSearch = () => {
    setValues({ email: '', domain: '' })
    setParamsData({ email: '', domain: '' })
    setCurrentPage(1)
    setItemsPerPage(10)
  }
  const getAllBreachesFromAPI = async (signal, email, domain) => {
    try {
      setLoading(true)
      const response = await getAllBreachesService(currentPage, itemsPerPage, signal, email, domain);
      const date = process.env.REACT_APP_ENV_MSP === "staging" ? new Date('2023-09-21') : new Date('2023-10-06');
      setAllBreaches(response?.data?.data?.breaches);
      setTotalData(response?.data?.data?.totalItems);

      const breachesData = response?.data?.data?.breaches;
      breachesData.map((breach) => {
        if (breach?.password_plaintext && breach?.password_plaintext.trim() && new Date(breach?.createdAt) >= date) {
          breach.decryptPass = decryptPassword(breach?.password_plaintext);
          if (breach.decryptPass) {
            breach.hashedPassword = hashPassword(breach?.decryptPass, breach?.password_type);
          }
        } else {
          breach.decryptPass = breach?.password_plaintext;
          breach.hashedPassword = hashPassword(breach?.decryptPass, breach?.password_type);
        }
      });
      setBreaches(breachesData);
      setLoading(false)
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAllBreachesFromAPI(signal, paramsData.email, paramsData.domain);

    return () => { controller.abort(); };
  }, [currentPage, itemsPerPage, paramsData.email, paramsData.domain]);

  function handleChange (e) {
    const { id, value } = e.target
    setValues({ ...initialValues, [id]: value })
  }

  async function handleClickSearch () {
    try {
      setChecked(false)
      if (values.domain === '' && values.email === '') {
        setBreaches(allBreaches)
      } else {
        if (values.domain) {
          setParamsData({ email: '', domain: values.domain })
        } else {
          setParamsData({ email: values.email, domain: '' })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function handleSendAlert (breachId) {
    try {
      await sendABreachAlertService(breachId)
      setShowModal({
        show: true,
        title: 'SENT SUCCESSFULLY',
        message: 'The email with the breach data has been sent to your helpdesk',
      })
      getAllBreachesFromAPI('', paramsData.email, paramsData.domain)
    } catch (error) {
      console.error(error)
    }
  }

  function handleCloseModal () {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <BreachesComponent
          {...{
            breaches,
            values,
            handleChange,
            handleClickSearch,
            handleSendAlert,
            showModal,
            handleCloseModal,
            handleCheckboxClick,
            checked,
            setChecked,
            totalData,
            currentPage,
            itemsPerPage,
            handleItemsPerPageChange,
            handlePageChange,
            loading,
            handleClearSearch
          }}
        />
      </MspMenu>
    </>
  )
}

export default BreachesContainer