import Axios from '../config/Axios'

export const getBreachCatalogueService = async (offset, limit) => {
  return await Axios.get(`/spycloud/breach/catalogue?offset=${offset}&limit=${limit}`)
}

export const searchBreachCatalogueService = async (search) => {
  return await Axios.get(`/spycloud/breach/catalogue?search=${search}`)
}

export const getBreachCatalogueAssetStatsService = async () => {
  return await Axios.get('/spycloud/breach/catalogue/asset/stats')
}

export const getBreachDetailsService = async id => {
  return await Axios.get(`/spycloud/breach/${id}`)
}
