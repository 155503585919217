import Axios from '../config/Axios'

export const addNewsCategoryService = async (data) => {
  const response = await Axios.post('news/category/', data)
  return response
}

export const deleteNewsCategoryService = async (id) => {
  const response = await Axios.delete(`news/category/${id}`)
  return response
}

export const getAllNewsCategoriesService = async () => {
  const response = await Axios.get('news/category/all')
  return response
}
