import Axios from '../config/Axios'

export const getAllPaymentMethodsService = async () => {
  return await Axios.get('/payment-method/all')
}

export const addPaymentMethodService = async data => {
  return await Axios.post('/payment-method', data)
}

export const deletePaymentMethodService = async id => {
  return await Axios.delete(`/payment-method/${id}`)
}
