import { Modal, Button } from 'react-bootstrap'
import './Modal.scss';

const ConfirmModal = ({
  title,
  message,
  buttonMessage = 'Ok',
  showModal,
  handleCloseCancelModal,
  handleOk,
  footer = true,
  id,
}) => {
  return (
    <>
      <Modal show={showModal} onHide={handleCloseCancelModal}>
        <Modal.Header>
          <Modal.Title className="modal-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        {footer && (
          <Modal.Footer>
            <Button
              variant="dark"
              className="readonly-btn white-btn"
              onClick={() => {
                handleOk(id)
              }}
            >
              {buttonMessage}
            </Button>
            <Button
              variant="dark"
              className="readonly-btn white-btn"
              onClick={handleCloseCancelModal}
            >
              Close
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default ConfirmModal
