import Axios from '../config/Axios'

export const addFeatureRequestCategoryService = async (data) => {
  const response = await Axios.post('feature-request/category/', data)
  return response
}

export const deleteFeatureRequestCategoryService = async (id) => {
  const response = await Axios.delete(`feature-request/category/${id}`)
  return response
}

export const getAllFeatureRequestCategoriesService = async () => {
  const response = await Axios.get('feature-request/category/all')
  return response
}
