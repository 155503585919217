import './ProspectingReportStyles.scss'
import { Form, Table } from 'react-bootstrap'
import FormInput from '../../components/FormInput'
import FormButton from '../../components/FormButton'
import Banner from '../../components/Banner'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import { routes } from '../../config/constants'
import { PDFDownloadLink } from '@react-pdf/renderer'
import ProspectingReportPdf from '../../components/ProspectingReportPdf'
import FormSwitch from '../../components/FormSwitchWithoutFormik'
import { useState } from 'react'

const ProspectingReportComponent = ({
  formik,
  isLoading,
  showModal,
  handleCloseModal,
  breaches,
  handleOnSwitch,
  checkToggle,
  downloadPdfReports,
  isDownloading,
  handleCheckboxClick,
  checked,
  setChecked
}) => {
  const [show, setShow] = useState(false)
  if (breaches.breaches?.length > 0) {
    setTimeout(() => {
      setShow(true)
    }, 1000)
  }

  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Banner title="PROSPECTING REPORT" />
        <div className="prospecting-report-container">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <h2 className="prospecting-report-subtitle">
              Enter a Domain or Email address below you wish to seach for:
            </h2>

            <div className="prospecting-report-input-container">
              <div className="Toggle-div" style={{ display: 'flex', alignItems: 'center' }}>
                {
                  <label for="toggleSwitch" style={{ marginBottom: 0, marginRight: '10px' }}>
                    Email:{' '}
                  </label>
                }
                <FormSwitch
                  label=""
                  value={checkToggle}
                  errors={'errors'}
                  onChange={e => handleOnSwitch(e.target.checked)}
                  id="45"
                  color="primary"
                  mutedText=""
                  style={{ marginBottom: '25px', marginTop: '30px', marginRight: '20px' }}
                />
              </div>
              <p> {checkToggle ? 'Search By Email:' : 'Search By Domain:'} </p>
              <FormInput
                formik={formik}
                id="emailDomain"
                style={{ margin: 0, marginRight: 20 }}
                color="#215F30"
                width={400}
              />

              <p>Customer Name: </p>
              <FormInput
                formik={formik}
                id="customerName"
                style={{ margin: 0 }}
                color="#215F30"
                width={400}
              />
            </div>
            <Form.Group controlId="terms">
              <div className="form-check form-checkbox-container">
                <input
                  onChange={formik?.handleChange}
                  checked={formik?.values.terms}
                  id="terms"
                  style={{ width: 20, height: 20 }}
                  type="checkbox"
                  className={
                    !!formik?.errors.terms
                      ? 'form-input-danger-color form-check-input form-control is-invalid'
                      : 'form-check-input'
                  }
                />
                <label
                  htmlFor="terms"
                  title=""
                  className="form-check-label"
                  style={{ fontSize: 14 }}
                >
                  &nbsp;I acknowledge that I am authorised to search the Dark Web for this Email
                  Address / Domain. The information found will not be used for malicious, illegal or
                  illicit purposes.
                </label>
              </div>
            </Form.Group>
            <div className="text-right">
              <Link to={routes.PREVIOUS_SEARCHES_ROUTE}>
                <FormButton
                  type="button"
                  label="PREVIOUS SEARCHES"
                  style={{ marginRight: 10 }}
                  width={200}
                />
              </Link>
              <FormButton
                type="submit"
                label={isLoading ? 'LOADING...' : 'SEARCH DARK WEB'}
                width={200}
              />
            </div>
          </Form>
          <div className="prospecting-report-separate-line" />
          {breaches.breaches && (
            <>
              {breaches.breaches.length > 0 ? (
                <>
                  <div className="prospecting-report-results-container">
                    <h2 className="prospecting-report-results-subtitle">RESULTS</h2>
                    {show && (
                      <FormButton
                        onClick={() => {
                          downloadPdfReports(breaches.pdfReport)
                        }}
                        label={isDownloading ? 'DOWNLOADING...' : 'DOWNLOAD REPORT'}
                        isDisabled={isDownloading}
                        width={200}
                      />
                    )}
                  </div>
                  <p>Search Results for: {breaches.customerName}</p>
                  <Table className="prospecting-report-table" borderless hover striped>
                    <thead>
                      <tr>
                        <th>Email Address</th>
                        <th style={{ display: 'flex', alignItems: 'center' , gap: '5px' , minWidth : '150px' }}>
                    Password
                <FormSwitch
                  label=""
                  value={checked}
                  errors={'errors'}
                  onChange={e => handleCheckboxClick(e,setChecked)}
                  id="revealPass"
                  color="primary"
                  mutedText=""
                  style={{ margin:0  }}
                />
                        </th>



                        {/* <th>
                        <div class="switch">
                        <input id="switch__input" type="checkbox" checked={checked} onClick={(e)=> handleCheckboxClick(e,setChecked)}/>
                        <label for="switch__input">
                         Password<span></span>
                        </label>
                        </div> 
                        </th> */}
                        <th>Breach Source</th>
                        <th>Breach Date</th>
                        <th>Publish Date</th>
                        <th className="text-center">Severity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {breaches.breaches?.map(breach => (
                        <tr key={breach.id}>
                          <td>{breach.email}</td>
                          <td>
                          {breach.decryptPass && breach.password_plaintext && checked
                                ? breach.decryptPass
                                : breach.hashedPassword
                                }
                          </td>
                          {/* <td className="text-center">
                            {breach.password_plaintext ? 'yes' : 'no'}
                          </td> */}
                          <td>
                            <Link
                              className="breaches-table-link"
                              to={`/breaches/${breach.breach?.mspbcid}/details`}
                            >
                              {breach.breach?.title || 'unknown'}
                            </Link>
                          </td>
                          <td>{breach.breach?.breach_date.slice(0, 10) || 'unknown'}</td>
                          <td>{breach.spycloud_publish_date.slice(0, 10)}</td>
                          <td className="text-center">{breach.severity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div className="prospecting-report-results-container">
                    <h2 className="prospecting-report-results-subtitle">RESULTS</h2>
                  </div>
                  <p>Search Results for: {breaches.customerName}</p>
                  <p>Congratulations! You currently have a clean bill of health.</p>
                </>
              )}
            </>
          )}
        </div>
      </motion.div>
    </>
  )
}

export default ProspectingReportComponent
