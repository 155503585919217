import MspMenu from '../../components/MspMenu'
import UsersComponent from './UsersComponent.jsx'
import { useState, useEffect } from 'react'
import {
  addStaffUserService,
  updateStaffUserService,
  getStaffUserService,
  deleteStaffUserService,
  getAllStaffUsersService,
} from '../../services/staffUser.service'
import { userErrorHandler } from './errorHandler'
import { schema } from '../../schemas/user.schema'
import { useFormik } from 'formik'
import { routes } from '../../config/constants'
import { useHistory } from 'react-router-dom'
import { getMyUserService } from '../../services/user.service'

const UsersContainer = ({ match }) => {
  const UserId = match.params.id
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [users, setUsers] = useState([])
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    status: true,
    resetPassword: false,
  })
  const [account, setAccount] = useState({})

  useEffect(() => {
    async function getUserFromAPI() {
      if (UserId) {
        try {
          const user = await getStaffUserService(UserId)
          setInitialValues(prevState => ({ ...prevState, ...user.data.data }))
        } catch (error) {
          setShowModal(userErrorHandler(error))
        }
      } else {
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: true,
          resetPassword: false,
        })
      }
    }

    async function getMyUserFromAPI() {
      try {
        const response = await getMyUserService()
        setAccount(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }

    getMyUserFromAPI()
    getUserFromAPI()
  }, [UserId])

  async function getAllUsersFromAPI() {
    try {
      const allUsers = await getAllStaffUsersService()
      setUsers(allUsers.data.data)
    } catch (error) {
      setShowModal(userErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllUsersFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async data => {
      try {
        setIsLoading(true)
        if (UserId) {
          const response = await updateStaffUserService(data, UserId)
          const { username } = response.data.data
          await getAllUsersFromAPI()
          setShowModal({
            show: true,
            title: 'STAFF USER HAS BEEN UPDATED',
            message: `The user ${username} has been updated successfully`,
            redirect: routes.USERS_ROUTE,
          })
        } else {
          const response = await addStaffUserService(data)
          const { username } = response.data.data
          setShowModal({
            show: true,
            title: 'STAFF USER HAS BEEN CREATED',
            message: `The user ${username} has been created successfully`,
          })
          getAllUsersFromAPI()
        }
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: false,
          resetPassword: false,
        })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(userErrorHandler(error))
      }
    },
  })

  async function handleStatusChange(e) {
    try {
      const { id } = e.target
      if (id !== account.userId) {
        const user = users.find(user => user.userId === id)
        await updateStaffUserService({ status: !user.status }, id)
        getAllUsersFromAPI()
      } else {
        setShowModal({
          show: true,
          title: 'YOU CANNOT DISABLE THIS USER',
          message: 'You cannot disable this user because it is this account',
        })
      }
    } catch (error) {
      setShowModal(userErrorHandler(error))
    }
  }

  async function handleDelete(e) {
    try {
      const { id } = e.target
      if (window.confirm('Are you sure you want to remove the user? This action is irreversible')) {
        await deleteStaffUserService(id)
        getAllUsersFromAPI()
      }
    } catch (error) {
      setShowModal(userErrorHandler(error))
    }
  }

  function handleClickUser(e) {
    const { id } = e.target
    history.push(routes.USERS_ROUTE + '/' + id)
  }

  async function handleResetPassword(e) {
    try {
      const { id } = e.target
      const user = users.find(user => user.userId === id)
      if (
        window.confirm(
          `Please confirm that you want to reset the password of user ${user.firstName} ${user.lastName}`
        )
      ) {
        await updateStaffUserService({ ...user, resetPassword: true }, id)
        setShowModal({
          show: true,
          title: 'PASSWORD CHANGE REQUEST SENT',
          message: 'The user will receive an email with the link to reset their password',
        })
      }
    } catch (error) {
      setShowModal(userErrorHandler(error))
    }
  }

  async function handleReset2fa(e) {
    try {
      const { id } = e.target
      const user = users.find(user => user.userId === id)
      if (
        window.confirm(
          `Please confirm that you want to reset the 2fa of user ${user.firstName} ${user.lastName}`
        )
      ) {
        await updateStaffUserService({ reset2faCode: true }, id)
        setShowModal({
          show: true,
          title: '2FA CODE RESET SENT',
          message: "User's 2fa code has been reset",
        })
      }
    } catch (error) {
      setShowModal(userErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <UsersComponent
          account={account}
          formik={formik}
          isLoading={isLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          users={users}
          UserId={UserId}
          handleStatusChange={handleStatusChange}
          handleDelete={handleDelete}
          handleClickUser={handleClickUser}
          handleResetPassword={handleResetPassword}
          handleReset2fa={handleReset2fa}
        />
      </MspMenu>
    </>
  )
}

export default UsersContainer
