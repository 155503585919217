import './AdminPrivateDataSources.scss'
import questionIcon from '../../assets/icons/question.svg'

const AdminPrivateDataSources = ({ children }) => {
  return (
    <div className="admin-private-data-sources-children">
      {children}
      <img src={questionIcon} style={{ position: 'absolute', right: 12, top: 50 }} />
      <div className="admin-private-data-sources-container">
        <h1>Private Data Sources</h1>
        <p>
          We often gain access to stolen information by interacting with criminals on forums that
          are not available to the general public. Using private forums, data theives may trade or
          sell their information on the underground. Private information is typically not
          availablefor purchase by legal entities. Information from these sources is typically
          urgent or critical in nature.
        </p>
      </div>
    </div>
  )
}

export default AdminPrivateDataSources
