import Axios from '../config/Axios'

export const addDashboardContentService = async data => {
  const response = await Axios.post('/dashboard/content/', data)
  return response
}

export const getAllDashboardContentsService = async () => {
  return await Axios.get('/dashboard/content/all')
}

export const getDashboardContentService = async (id) => {
  if (id) {
    return await Axios.get(`/dashboard/content/${id}`)
  }
  return await Axios.get(`/dashboard/content`)
}

export const updateDashboardContentService = async (data, id) => {
  const response = await Axios.put(`/dashboard/content/${id}`, data)
  return response
}

export const deleteDashboardContentService = async id => {
  const response = await Axios.delete(`/dashboard/content/${id}`)
  return response
}
