const TrailIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9H12.82C12.4 7.84 11.3 7 10 7C8.7 7 7.6 7.84 7.18 9H4C3.67 9 2 8.9 2 7V6C2 4.17 3.54 4 4 4H14.18C14.6 5.16 15.7 6 17 6C17.7956 6 18.5587 5.68393 19.1213 5.12132C19.6839 4.55871 20 3.79565 20 3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0C15.7 0 14.6 0.84 14.18 2H4C2.39 2 0 3.06 0 6V7C0 9.94 2.39 11 4 11H7.18C7.6 12.16 8.7 13 10 13C11.3 13 12.4 12.16 12.82 11H16C16.33 11 18 11.1 18 13V14C18 15.83 16.46 16 16 16H5.82C5.4 14.84 4.3 14 3 14C2.20435 14 1.44129 14.3161 0.87868 14.8787C0.316071 15.4413 0 16.2044 0 17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20C4.3 20 5.4 19.16 5.82 18H16C17.61 18 20 16.93 20 14V13C20 10.07 17.61 9 16 9ZM17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3C18 3.26522 17.8946 3.51957 17.7071 3.70711C17.5196 3.89464 17.2652 4 17 4C16.7348 4 16.4804 3.89464 16.2929 3.70711C16.1054 3.51957 16 3.26522 16 3C16 2.73478 16.1054 2.48043 16.2929 2.29289C16.4804 2.10536 16.7348 2 17 2ZM3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17C2 16.7348 2.10536 16.4804 2.29289 16.2929C2.48043 16.1054 2.73478 16 3 16C3.26522 16 3.51957 16.1054 3.70711 16.2929C3.89464 16.4804 4 16.7348 4 17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18Z"
        fill="black"
      />
    </svg>
  )
}

export default TrailIcon
