import './AdminFeatureDetails.scss'
import CrossIcon from '../../assets/icons/crossWhite.svg'
import { Row, Col } from 'react-bootstrap'
import { dateToSemiTextFormat } from '../../utilities/transformDates'
import FormSelect from '../FormSelect'

const AdminFeatureDetails = ({
  featureRequest,
  show,
  onClose,
  statuses,
  onChangeStatus,
  categories,
  onChangeCategory,
}) => {
  if (!show) {
    return null
  }

  return (
    <div className="admin-feature-details">
      <div className="admin-feature-details-content">
        <div className="admin-banner-container" style={{ borderRadius: '5px 5px 0 0' }}>
          <h1>FEATURE</h1>
          <img
            onClick={onClose}
            src={CrossIcon}
            alt="Cross icon to close the modal"
            width={25}
            height={25}
          />
        </div>
        <div className="admin-feature-details-body">
          <Row>
            <Col>
              <h5 className="admin-feature-details-bold-text">{featureRequest?.title}</h5>
              <h5 className="admin-feature-details-bold-text">
                Request Date:{' '}
                <span className="admin-feature-details-light-text">
                  {dateToSemiTextFormat(featureRequest.createdAt)}
                </span>
              </h5>
              <h5 className="admin-feature-details-bold-text">
                {featureRequest?.msp?.companyName || 'MSP DARK WEB'}
              </h5>
              <h5 className="admin-feature-details-bold-text" style={{ marginBottom: 0 }}>
                {featureRequest?.requests}
              </h5>
            </Col>
            <Col>
              <FormSelect
                onChange={onChangeStatus}
                value={featureRequest.featureRequestStatus?.id}
                type="statuses"
                options={statuses}
                height={50}
                label="Status"
              />
              <FormSelect
                onChange={onChangeCategory}
                value={featureRequest.featureRequestCategory?.id}
                type="categories"
                options={categories}
                height={50}
                label="Category"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="admin-feature-details-separate-line" />
              <p className="admin-feature-details-text">{featureRequest?.description}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AdminFeatureDetails
