const PeopleIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6666 17.3333V20H0V17.3333C0 17.3333 0 12 9.3333 12C18.6666 12 18.6666 17.3333 18.6666 17.3333ZM14 4.66672C14 3.74374 13.7263 2.8415 13.2135 2.07407C12.7007 1.30664 11.9719 0.708506 11.1191 0.355298C10.2664 0.00209021 9.32812 -0.090325 8.42288 0.0897387C7.51764 0.269802 6.68612 0.714258 6.03348 1.3669C5.38084 2.01954 4.93638 2.85106 4.75632 3.7563C4.57625 4.66154 4.66867 5.59985 5.02188 6.45257C5.37509 7.30529 5.97322 8.03412 6.74065 8.5469C7.50808 9.05968 8.41032 9.33337 9.3333 9.33337C10.571 9.33337 11.758 8.84171 12.6331 7.96654C13.5083 7.09137 14 5.90439 14 4.66672ZM18.5866 12C19.4063 12.6344 20.077 13.4406 20.5515 14.362C21.026 15.2835 21.2929 16.2977 21.3333 17.3333V20H26.6666V17.3333C26.6666 17.3333 26.6666 12.4934 18.5866 12ZM17.3333 7.01051e-05C16.4155 -0.00504016 15.518 0.269343 14.7599 0.786734C15.5699 1.91838 16.0054 3.27511 16.0054 4.66672C16.0054 6.05833 15.5699 7.41506 14.7599 8.54671C15.518 9.0641 16.4155 9.33848 17.3333 9.33337C18.5709 9.33337 19.7579 8.84171 20.6331 7.96654C21.5083 7.09137 21.9999 5.90439 21.9999 4.66672C21.9999 3.42905 21.5083 2.24207 20.6331 1.3669C19.7579 0.491734 18.5709 7.01051e-05 17.3333 7.01051e-05Z"
        fill="black"
      />
    </svg>
  )
}

export default PeopleIcon
