import './CustomerDetailsStyles.scss'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { routes, industries, countries } from '../../config/constants'
import FormInput from '../../components/FormInputWithoutFormik'
import FormSelect from '../../components/FormSelectWithoutFormik'
import FormSwitch from '../../components/FormSwitchWithoutFormik'
import FormButton from '../../components/FormButton'
import Banner from '../../components/Banner'
import Modal from '../../components/Modal'
import PlusIcon from '../../assets/icons/plus.svg'
import TrashDeleteBlackIcon from '../../assets/icons/trashDeleteBlack.svg'

const CustomerDetailsComponent = ({
  isLoading,
  showModal,
  handleCloseModal,
  customer,
  errors,
  handleSubmit,
  handleOnChange,
  handleClickDelete,
  handleOnClickAdd,
  handleDeleteCustomer,
  table,
  handleResetDomainOrEmail,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message || 'The new package can now be assigned to an MSP'}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <Banner title={customer.companyName} redirect={routes.CUSTOMERS_ROUTE} />
      <div className="customer-details-container">
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xs={5}>
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="companyName"
                label="Company Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="firstName"
                label="First Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="lastName"
                label="Last Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSelect
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                options={industries}
                type="industries"
                id="industry"
                label="Industry"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSelect
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                options={countries}
                type="countries"
                id="country"
                label="Country"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="reportEmail"
                label="Customer Report Email Address"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSwitch
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                password={customer?.reportStatus}
                id="reportStatus"
                label="Disable/Enable Reports"
                color="primary"
                mutedText="Enabling Customer Reports will send a
              report to the customer report delivery email address at the frequency selected."
                style={{ marginBottom: 5, marginTop: 30 }}
              />
              <FormSelect
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                options={['monthly']}
                type="frequency"
                id="reportFrequency"
                label="Report Frequency"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSwitch
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="status"
                label="Disable/Enable Customer"
                color="primary"
                mutedText="Disabling the customer will disable all domains and emails from live monitoring"
                style={{ marginBottom: 5 }}
              />
              <Form.Group style={{ marginBottom: 5 }} controlId="domains">
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="form-input-label"
                >
                  Domain(s)
                </Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    disabled={customer?.totalAvailableDomains<=0?true:false}
                    className="form-input-primary-color form-input-plus-control"
                    type="text"
                    isInvalid={errors.domains}
                  />
                  <img
                    style={{ position: 'absolute', cursor: 'pointer', top: 10, right: 10 }}
                    src={PlusIcon}
                    onClick={() => customer?.totalAvailableDomains && handleOnClickAdd('domains')}
                    alt="Add icon"
                    width={18}
                    height={18}
                  />
                  <Form.Control.Feedback type="invalid">{errors.domains}</Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  {customer?.totalAvailableDomains > 0 ? "Adding a domain will monitor the entire email domain for your customer and lookfor any breaches with email addresses in that domain." : <span style={{ color: '#f85954' }}>You have reached your package domain limit. To add new domains for monitoring, please go to your account screen and request an increase for your monthly package.</span>}
                </Form.Text>
              </Form.Group>
              <Form.Group style={{ marginBottom: 5 }} controlId="emails">
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="form-input-label"
                >
                  Personal Email Addresses
                </Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    className="form-input-primary-color form-input-plus-control"
                    type="text"
                    isInvalid={errors.emails}
                  />
                  <img
                    style={{ position: 'absolute', cursor: 'pointer', top: 10, right: 10 }}
                    src={PlusIcon}
                    onClick={() => handleOnClickAdd('emails')}
                    alt="Add icon"
                    width={18}
                    height={18}
                  />
                  <Form.Control.Feedback type="invalid">{errors.emails}</Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  Adding personal email addresses for persons of significant importance within this
                  company will monitor for breaches on that email address only.
                </Form.Text>
              </Form.Group>
              <div className="customer-details-buttons-container">
                <FormButton
                  onClick={() => {
                    handleDeleteCustomer(customer.customerId)
                  }}
                  style={{ marginRight: 20 }}
                  type="button"
                  width={100}
                  label="DELETE"
                />
                <FormButton type="submit" width={100} label={isLoading ? 'LOADING...' : 'UPDATE'} />
              </div>
            </Col>
            <Col>
              <div>
                <Table className="customers-table" hover borderless>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>Type</th>
                      <th style={{ width: '30%' }}>Domain/Email</th>
                      <th style={{ width: '15%' }} className="text-center">
                        View Scan
                      </th>
                      <th style={{ width: '15%' }} className="text-center">
                        Reset Scan
                      </th>
                      <th style={{ width: '10%' }} className="text-center">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((emailOrDomain, i) => (
                      <tr key={i}>
                        <td>
                          {emailOrDomain.value.includes('@') ? 'Personal Email Address' : 'Domain'}
                        </td>
                        <td>{emailOrDomain.value}</td>
                        <td className="text-center">
                          {emailOrDomain.new ? (
                            <span
                              title="You must save the changes first"
                              style={{
                                textDecoration: 'underline',
                                color: 'black',
                                cursor: 'not-allowed',
                                opacity: 0.6,
                              }}
                            >
                              view
                            </span>
                          ) : (
                            <Link
                              to={`/first-breach/${customer.customerId}?${
                                emailOrDomain.value.includes('@') ? 'email' : 'domain'
                              }=${emailOrDomain.value}`}
                            >
                              <span style={{ textDecoration: 'underline', color: 'black' }}>
                                view
                              </span>
                            </Link>
                          )}
                        </td>
                        <td className="text-center">
                          {emailOrDomain.new ? (
                            <span
                              title="You must save the changes first"
                              style={{
                                textDecoration: 'underline',
                                cursor: 'not-allowed',
                                opacity: 0.6,
                              }}
                            >
                              reset
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                handleResetDomainOrEmail(emailOrDomain.value)
                              }}
                              style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              reset
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          <img
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleClickDelete(
                                emailOrDomain.value,
                                emailOrDomain.value.includes('@') ? 'emails' : 'domains'
                              )
                            }}
                            src={TrashDeleteBlackIcon}
                            alt="Delete icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default CustomerDetailsComponent
