import Axios from '../config/Axios'
export const addMspService = async data => {
  const response = await Axios.post('msp/', data)
  return response
}

export const addMspServiceForAdmin = async data => {
  const response = await Axios.post('msp/admin/', data)
  return response.data
}

export const updateMyMspService = async (data, softUpdate) => {
  if (softUpdate) {
    const response = await Axios.put('msp/?softUpdate=true', data)
    return response.data
  } else {
    const response = await Axios.put('msp/', data)
    return response.data
  }
}

export const updateMspService = async (data, id) => {
  const response = await Axios.put(`msp/${id}`, data)
  return response.data
}

export const getMyMspService = async () => {
  const response = await Axios.get('msp/')
  return response
}

export const getMyMspLightLogoService = async () => {
  const response = await Axios.get('msp/logo/light')
  return response
}

export const getMspService = async id => {
  const response = await Axios.get(`msp/${id}`)
  return response
}

export const getAllMSPsServiceCount = async () => {
  return Axios.get('msp/allCount')
}

export const getAllMSPsServiceAdmin = async (onlyCompletedMSPs = false) => {
  return Axios.get(`msp/allAdmin/?onlyCompletedMSPs=${onlyCompletedMSPs}`)
}

export const getAllMSPsService = async (onlyCompletedMSPs = false) => {
  if (onlyCompletedMSPs) {
    return Axios.get(`msp/all/?onlyCompletedMSPs=${onlyCompletedMSPs}`)
  }
  return Axios.get('msp/all')
}

export const deleteMspService = async id => {
  const response = await Axios.delete('/msp/' + id)
  return response
}

export const updateLightMspLogoService = async data => {
  const { lightLogo } = data
  const formData = new FormData()
  formData.append('logo', lightLogo)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return Axios.put('/msp/logo/light', formData, config)
}

export const deleteLightMspLogoService = async () => {
  const response = await Axios.delete('/msp/logo/light')
  return response
}

export const updateDarkMspLogoService = async data => {
  const { darkLogo } = data
  const formData = new FormData()
  formData.append('logo', darkLogo)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return Axios.put('/msp/logo/dark', formData, config)
}

export const deleteDarkMspLogoService = async () => {
  const response = await Axios.delete('/msp/logo/dark')
  return response
}
