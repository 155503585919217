import MdwMenu from '../../components/MdwMenu'
import AdminFeaturesComponent from './AdminFeaturesComponent.jsx'
import {
  getAllFeatureRequestStatusesService,
  addFeatureRequestStatusService,
  deleteFeatureRequestStatusService,
} from '../../services/featureRequestStatus.service'
import {
  getAllFeatureRequestCategoriesService,
  addFeatureRequestCategoryService,
  deleteFeatureRequestCategoryService,
} from '../../services/featureRequestCategory.service'
import {
  deleteFeatureRequestService,
  getAllFeatureRequestsService,
  updateFeatureRequestService,
} from '../../services/featureRequest.service'
import { useFormik } from 'formik'
import { schema as statusSchema } from '../../schemas/featureRequestStatus.schema'
import { schema as categorySchema } from '../../schemas/featureRequestCategory.schema'
import { featureRequestErrorHandler } from './errorHandler.js'
import { useState, useEffect } from 'react'

const AdminFeaturesContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [showFeatureDetails, setShowFeatureDetails] = useState({})
  const [showFeatureCategories, setShowFeatureCategories] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [categories, setCategories] = useState([])
  const [featureRequests, setFeatureRequests] = useState([])

  const initialValues = {
    name: '',
  }

  async function getAllStatusesFromAPI() {
    try {
      const allStatusesResponse = await getAllFeatureRequestStatusesService()
      setStatuses(allStatusesResponse.data.data)
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function getAllCategoriesFromAPI() {
    try {
      const allCategoriesResponse = await getAllFeatureRequestCategoriesService()
      setCategories(allCategoriesResponse.data.data)
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function getAllFeatureRequestsFromAPI() {
    try {
      const allFeaturesResponse = await getAllFeatureRequestsService()
      let result = allFeaturesResponse.data.data
      const waiting = result.filter(featureRequest => featureRequest.featureRequestStatus === null)
      result = result.filter(featureRequest => featureRequest.featureRequestStatus)
      result = result.sort((a, b) => {
        if (a.featureRequestStatus.name < b.featureRequestStatus.name) {
          return -1
        }
        if (a.featureRequestStatus.name > b.featureRequestStatus.name) {
          return 1
        }
        return 0
      })
      setFeatureRequests(waiting.concat(result))
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllFeatureRequestsFromAPI()
    getAllStatusesFromAPI()
    getAllCategoriesFromAPI()
  }, [])

  const statusFormik = useFormik({
    initialValues,
    validationSchema: statusSchema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await addFeatureRequestStatusService(data)
        actions.resetForm()
        getAllStatusesFromAPI()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(featureRequestErrorHandler(error))
      }
    },
  })

  const categoryFormik = useFormik({
    initialValues,
    validationSchema: categorySchema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await addFeatureRequestCategoryService(data)
        actions.resetForm()
        getAllCategoriesFromAPI()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(featureRequestErrorHandler(error))
      }
    },
  })

  async function handleDeleteStatus(e) {
    try {
      const { id } = e.target
      await deleteFeatureRequestStatusService(id)
      getAllStatusesFromAPI()
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function handleDeleteCategory(e) {
    try {
      const { id } = e.target
      await deleteFeatureRequestCategoryService(id)
      getAllCategoriesFromAPI()
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  function handleFeatureDetailsClick(id) {
    const featureRequest = featureRequests.find(featureRequest => featureRequest.id === id)
    setShowFeatureDetails({ show: true, data: featureRequest })
  }

  async function onChangeStatus(e) {
    try {
      const { value } = e.target
      await updateFeatureRequestService(
        { featureRequestStatusId: value },
        showFeatureDetails.data.id
      )
      getAllFeatureRequestsFromAPI()
      handleOnCloseFeatureDetails()
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function onChangeCategory(e) {
    try {
      const { value } = e.target
      await updateFeatureRequestService(
        { featureRequestCategoryId: value },
        showFeatureDetails.data.id
      )
      getAllFeatureRequestsFromAPI()
      handleOnCloseFeatureDetails()
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function handleOnClickDelete(id) {
    try {
      if (
        window.confirm(
          'Are you sure you want to remove this feature request? This action is irreversible'
        )
      ) {
        await deleteFeatureRequestService(id)
        getAllFeatureRequestsFromAPI()
      }
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  function handleFeatureCategoriesClick() {
    setShowFeatureCategories(true)
  }

  function handleOnCloseFeatureDetails() {
    setShowFeatureDetails({ show: false })
  }

  function handleOnCloseFeatureCategories() {
    setShowFeatureCategories(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminFeaturesComponent
          onChangeCategory={onChangeCategory}
          handleCloseModal={handleCloseModal}
          onChangeStatus={onChangeStatus}
          featureRequests={featureRequests}
          statuses={statuses}
          categories={categories}
          categoryFormik={categoryFormik}
          handleDeleteCategory={handleDeleteCategory}
          isLoading={isLoading}
          showModal={showModal}
          showFeatureDetails={showFeatureDetails}
          showFeatureCategories={showFeatureCategories}
          handleFeatureDetailsClick={handleFeatureDetailsClick}
          handleFeatureCategoriesClick={handleFeatureCategoriesClick}
          handleOnCloseFeatureDetails={handleOnCloseFeatureDetails}
          handleOnCloseFeatureCategories={handleOnCloseFeatureCategories}
          handleOnClickDelete={handleOnClickDelete}
        />
      </MdwMenu>
    </>
  )
}

export default AdminFeaturesContainer
