import * as Yup from 'yup'

export const schema = Yup.object({
  companyName: Yup.string()
    .min(2, 'Company name must be at least 2 chars long')
    .required('Company name is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  postalCode: Yup.string().required('Postal code is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  pcFirstName: Yup.string()
    .min(2, 'First name must be at least 2 chars long')
    .max(30, 'First name must have a maximum of 30 characters')
    .required('First name is required'),
  pcLastName: Yup.string()
    .min(2, 'Last name must be at least 2 chars long')
    .max(30, 'Last name must have a maximum of 30 characters')
    .required('Last name is required'),
  pcEmail: Yup.string().email('Must be a valid email').required('Email is required'),
  pcTelephone: Yup.string().required('Telephone is required'),
  website: Yup.string()
    .matches(/^(?!www)[A-Za-z0-9_-]+\.+[A-Za-z0-9./%&=?_:;-]+$/gm, 'Must be a valid domain'),
  invoiceDelivery: Yup.string().required('Invoice delivery is required'),
  billingFirstName: Yup.string().required('Billing firstname is required'),
  billingLastName: Yup.string().required('Billing lastname is required'),
  billingEmail: Yup.string().email('Must be a valid email').required('Billing Email is required'),
  billingTelephone: Yup.string().required('Billing telephone is required'),
  packageId: Yup.string().required('Package is required'),
  paymentMethodId: Yup.string().required('Package is required'),
})
