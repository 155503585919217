import './FormSelect.scss'
import { Form } from 'react-bootstrap'
import PlusIcon from '../../assets/icons/plusBlack.svg'

const FormSelect = ({
  label,
  id,
  formik,
  readOnly,
  placeholder,
  style,
  size,
  options,
  type,
  color,
  width,
  height = 30,
  addButton,
  onClick,
  value,
  onChange,
  className,
}) => {
  function typeSwitch(type) {
    switch (type) {
      case 'packages':
        return options?.map(option => (
          <option key={option.packageId} value={option.packageId}>
            {option.packageSku}
          </option>
        ))
      case 'paymentMethods':
        return options?.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))
      case 'statuses':
        return options?.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))
      case 'categories':
        return options?.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))
      case 'industries':
        return options?.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      case 'countries':
        return options?.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      case 'frequency':
        return options?.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      case 'customers':
        return options?.map(option => (
          <option key={option.customerId} value={option.customerId}>
            {option.companyName}
          </option>
        ))
      case 'MspUser':
        return options?.map(option => (
          <option key={option} value={option.mspId}>
            {option.companyName}
          </option>
        ))
    }
  }

  return (
    <Form.Group
      className={className}
      controlId={id}
      style={{ position: 'relative', width, ...style }}
    >
      <Form.Label
        style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
        className="form-select-label"
      >
        {label}
        {addButton && (
          <img
            onClick={onClick}
            style={{ marginLeft: 20, marginBottom: 2, cursor: 'pointer' }}
            src={PlusIcon}
            width={18}
            height={18}
          />
        )}
      </Form.Label>
      {!!formik?.errors[id] ? (
        <Form.Control
          size={size}
          as="select"
          value={formik?.values[id] || value}
          onChange={formik?.handleChange || onChange}
          isInvalid={!!formik?.errors[id]}
          className="form-select-danger-color form-select-control"
          style={{ height: height && height, fontSize: 12 }}
          placeholder={placeholder}
          readOnly={readOnly}
        >
          <option selected disabled hidden>
            Choose here
          </option>
          {typeSwitch(type)}
        </Form.Control>
      ) : (
        <Form.Control
          size={size}
          as="select"
          value={formik?.values[id] || value}
          onChange={formik?.handleChange || onChange}
          className={(color && `form-select-${color}-color`) + ' form-select-control'}
          style={{ height: height && height, fontSize: 12 }}
          placeholder={placeholder}
          readOnly={readOnly}
        >
          <option selected disabled hidden>
            Choose here
          </option>
          {typeSwitch(type)}
        </Form.Control>
      )}
      <small class="muted-text form-text"></small>
    </Form.Group>
  )
}

export default FormSelect
