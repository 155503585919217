import * as React from 'react'

export default function TwoFAReset(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={22} fill="none" {...props}>
      <path
        d="M9 0L0 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V4L9 0zm0 6c1.4 0 2.8 1.1 2.8 2.5V10c.6 0 1.2.6 1.2 1.3v3.5c0 .6-.6 1.2-1.3 1.2H6.2c-.6 0-1.2-.6-1.2-1.3v-3.5c0-.6.6-1.2 1.2-1.2V8.5C6.2 7.1 7.6 6 9 6zm0 1.2c-.8 0-1.5.5-1.5 1.3V10h3V8.5c0-.8-.7-1.3-1.5-1.3z"
        fill="#000"
      />
    </svg>
  )
}
