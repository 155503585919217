import './Admin2FARequestStyles.scss'
import AdminBanner from '../../components/AdminBanner'
import { Table } from 'react-bootstrap'
import Modal from '../../components/Modal'

export default function Admin2FARequestComponent({
  resetTwofaRequests,
  showModal,
  handleCloseModal,
}) {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <AdminBanner title="2FA RESET REQUEST" />
      <div className="admin-2fa-request-container">
        {resetTwofaRequests ? (
          <>
            {resetTwofaRequests.length > 0 ? (
              <Table className="admin-2fa-request-table" borderless hover striped>
                <thead>
                  <tr>
                    <th>MSP</th>
                    <th>NAME</th>
                    <th>EMAIL ADDRESS</th>
                    <th>REQ DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {resetTwofaRequests.map(resetTwofaRequest => (
                    <tr key={resetTwofaRequest?.id}>
                      <td>{resetTwofaRequest?.msp?.companyName}</td>
                      <td>
                        {resetTwofaRequest?.user?.firstName} {resetTwofaRequest?.user?.lastName}
                      </td>
                      <td>{resetTwofaRequest?.user?.emailAddress}</td>
                      <td>{resetTwofaRequest?.createdAt.slice(0, -14)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>You currently no 2FA reset requests.</p>
            )}
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </>
  )
}
