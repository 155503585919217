import { Form } from 'react-bootstrap'

const FormSelect = ({
  id,
  label,
  value,
  onChange,
  errors,
  options,
  type,
  width,
  style,
  color,
  height = 30,
  className
}) => {
  function typeSwitch(type) {
    switch (type) {
      case 'industries':
        return options?.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      case 'countries':
        return options?.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      case 'frequency':
        return options?.map(option => (
          <option key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        ))
      case 'package':
        return options?.map(option => (
          <option key={option.packageId} value={option.packageId}>
            {option.packageSku}
          </option>
        ))
      case 'customer':
        return options?.map(option => (
          <option key={option.customerId} value={option.customerId}>
            {option.companyName}
          </option>
        ))
    }
  }
  return (
    <Form.Group
      className={className}
      controlId={id}
      style={{ position: 'relative', width, ...style }}
    >
      <Form.Label
        style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
        className="form-input-label"
      >
        {label}
      </Form.Label>
      <Form.Control
        className="margin-null"
        style={{
          height: height && height,
          fontSize: 12,
        }}
        size="sm"
        as="select"
        value={value[id]}
        onChange={onChange}
        isInvalid={errors && !!errors[id]}
        className={(color && `form-select-${color}-color`) + ' form-select-control'}
      >
        <option value="" selected disabled hidden>
          Choose here
        </option>
        {typeSwitch(type)}
      </Form.Control>
      <Form.Control.Feedback type="invalid" tooltip>
        {errors && errors[id]}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormSelect
