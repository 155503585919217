import './AdminAddNewsStyles.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Row, Col, Form } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { adminRoutes } from '../../config/constants'
import AdminBanner from '../../components/AdminBanner'
import AdminNewsCategory from '../../components/AdminNewsCategory'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import FormButton from '../../components/FormButton'
import Modal from '../../components/Modal'

const AdminNewsComponent = ({
  editorState,
  onEditorStateChange,
  showModal,
  handleCloseModal,
  categories,
  newsFormik,
  categoryFormik,
  handleDeleteCategory,
  isLoading,
  showNewsCategories,
  handleNewsCategoryClick,
  handleOnCloseNewsCategories,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <AdminNewsCategory
        categories={categories}
        formik={categoryFormik}
        handleDeleteCategory={handleDeleteCategory}
        inputId="name"
        isLoading={isLoading}
        show={showNewsCategories}
        onClose={handleOnCloseNewsCategories}
      />
      <AdminBanner title="ADD NEWS" redirect={adminRoutes.NEWS_ROUTE} />
      <Form noValidate onSubmit={newsFormik.handleSubmit}>
        <div className="admin-add-news-container">
          <Row>
            <Col xs={8}>
              <FormInput formik={newsFormik} id="title" height={50} label="Title" />
            </Col>
            <Col>
              <FormSelect
                formik={newsFormik}
                id="newsCategoryId"
                options={categories}
                type="categories"
                height={50}
                label="Category"
                onClick={handleNewsCategoryClick}
                addButton
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarClassName="admin-add-news-editor-toolbar"
                wrapperClassName="admin-add-news-editor-wrapper"
                editorClassName="admin-add-news-editor-editor"
                toolbar={{
                  options: [
                    'inline',
                    'fontSize',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'emoji',
                    'image',
                    'remove',
                    'history',
                  ],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline'],
                  },
                  fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                }}
              />
            </Col>
          </Row>
          <div style={{ marginTop: 29 }} className="text-right">
            <FormButton type="submit" label="ADD NEWS" width={100} mdw />
          </div>
        </div>
      </Form>
    </>
  )
}

export default AdminNewsComponent
