import MdwMenu from '../../components/MdwMenu'
import AdminPackagesComponent from './AdminPackagesComponent.jsx'
import { useFormik } from 'formik'
import {
  addPackageService,
  updatePackageService,
  getPackageService,
  getAllPackagesService,
  deletePackageService,
} from '../../services/package.service'
import { adminRoutes } from '../../config/constants'
import { useState, useEffect } from 'react'
import { schema } from '../../schemas/package.schema'
import { packageErrorHandler } from './errorHandler'

const AdminPackagesContainer = ({ match }) => {
  const PackageId = match.params.id
  const [showPackageConfirmation, setShowPackageConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [packages, setPackages] = useState([])
  const [initialValues, setInitialValues] = useState({
    packageSku: '',
    packageName: '',
    packageDescription: '',
    packageDomains: '',
    packageEmails: '',
    status: true,
  })

  async function getAllPackagesFromAPI() {
    try {
      const allPackagesResponse = await getAllPackagesService()
      setPackages(allPackagesResponse.data.data)
    } catch (error) {
      setShowModal(packageErrorHandler(error))
    }
  }

  useEffect(() => {
    setInitialValues({
      packageSku: '',
      packageName: '',
      packageDescription: '',
      packageDomains: '',
      packageEmails: '',
      status: true,
    })
    async function getPackageFromAPI() {
      if (PackageId) {
        try {
          const packagee = await getPackageService(PackageId)
          setInitialValues(prevState => ({ ...prevState, ...packagee.data.data }))
        } catch (error) {
          setShowModal(packageErrorHandler(error))
        }
      }
    }
    getPackageFromAPI()
    getAllPackagesFromAPI()
  }, [PackageId])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        if (PackageId) {
          await updatePackageService(data, PackageId)
          setShowModal({
            show: true,
            title: 'PACKAGE HAS BEEN UPDATED',
            message: 'The package has been updated successfully',
            redirect: adminRoutes.PACKAGES_ROUTE,
          })
        } else {
          await addPackageService(data)
          setShowModal({
            show: true,
            title: 'PACKAGE HAS BEEN CREATED',
            message: 'The new package has been created successfully',
            redirect: adminRoutes.PACKAGES_ROUTE,
          })
        }
        actions.resetForm()
        getAllPackagesFromAPI()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(packageErrorHandler(error))
      }
    },
  })

  async function handleDelete(e) {
    try {
      const { id } = e.target
      if (window.confirm('Are you sure you want to remove the package? This action is irreversible')) {
        await deletePackageService(id)
        getAllPackagesFromAPI()
      }
    } catch (error) {
      setShowModal(packageErrorHandler(error))
    }
  }

  function handleOnClosePackageConfirmation() {
    setShowPackageConfirmation(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminPackagesComponent
          PackageId={PackageId}
          packages={packages}
          formik={formik}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          isLoading={isLoading}
          handleDelete={handleDelete}
          showPackageConfirmation={showPackageConfirmation}
          handleOnClosePackageConfirmation={handleOnClosePackageConfirmation}
        />
      </MdwMenu>
    </>
  )
}

export default AdminPackagesContainer
