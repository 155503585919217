export const dateToSemiTextFormat = date => {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

  switch (da) {
    case '01':
      da = '1st'
      break
    case '02':
      da = '2nd'
      break
    case '03':
      da = '3rd'
      break
    case '04':
      da = '4th'
      break
    case '05':
      da = '5th'
      break
    case '06':
      da = '6th'
      break
    case '07':
      da = '7th'
      break
    case '08':
      da = '8th'
      break
    case '09':
      da = '9th'
      break
    default:
      da = da + 'th'
  }

  return `${da} ${mo} ${ye}`
}
