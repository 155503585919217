import Axios from '../config/Axios'

export const addMdwUserService = async data => {
  const response = await Axios.post('/mdw', data)
  return response
}

export const updateMdwUserService = async (data, userId, userType) => {
  const response = await Axios.put(`/mdw/userUpdateById?id=${userId}&userType=${userType}`, data)
  return response
}

export const getMdwUserService = async (userId, userType) => {
  if (userType && userId) {
    const response = Axios.get(`/mdw/userDataById?id=${userId}&userType=${userType}`)
    return response
  }
}

export const deleteMdwUserService = async (userId, userType) => {
  if (userType && userId) {
    const response = Axios.delete(`/mdw/deleteDataById?id=${userId}&userType=${userType}`)
    return response
  }
}

export const getAllMdwUsersService = async () => {
  const response = await Axios.get('/mdw/all')
  return response
}

export const sendMdwUserIdService = async data => {
  const response = await Axios.post('/mdw/mspData', data)
  return response
}

