import { useState } from 'react'
import Navbar from '../../components/Navbar'
import ForgotPasswordComponent from './ForgotPasswordComponent.jsx'
import { useFormik } from 'formik'
import { forgotPasswordService } from '../../services/auth.service'
import { schema } from '../../schemas/forgotPassword.schema'
import { forgotPasswordErrorHandler } from './errorHandler'
import { routes } from '../../config/constants'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'

const ForgotPasswordContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})

  const initialValues = {
    emailAddress: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await forgotPasswordService(data)
        setShowModal({
          show: true,
          title: 'THE EMAIL HAS BEEN SENT',
          message: `Please check your email ${data.emailAddress} to be able to change your password`,
          redirect: routes.LOGIN_ROUTE,
        })
        actions.resetForm()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(forgotPasswordErrorHandler(error))
        actions.resetForm()
      }
    },
  })

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <Navbar logo={Logo} />
      <ForgotPasswordComponent
        isLoading={isLoading}
        formik={formik}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
      />
    </>
  )
}

export default ForgotPasswordContainer
