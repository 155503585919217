import Axios from '../config/Axios'

export const addStaffUserService = async (data) => {
  const response = await Axios.post('auth/staffsignup/', data)
  return response
}

export const updateStaffUserService = async (data, id) => {
  const response = await Axios.put(`auth/staffsignup/${id}`, data)
  return response
}

export const getStaffUserService = async (id) => {
  const response = await Axios.get(`staff/${id}`)
  return response
}

export const deleteStaffUserService = async (id) => {
  const response = await Axios.delete(`staff/${id}`)
  return response
}

export const getAllStaffUsersService = async () => {
  const response = await Axios.get('staff/all')
  return response
}
