import './FormCheckbox.css'
import { Form } from 'react-bootstrap'

const FormCheckbox = ({ className, id, width, height, label, style, checked, onChange }) => {
  return (
    <Form.Group className={className} controlId={id} style={style}>
      <div className="form-check form-checkbox-container">
        <input onChange={onChange} checked={checked} id={id} style={{ width, height }} type="checkbox" className="form-check-input" />
        {label && (
          <label htmlFor={id} title="" className="form-check-label">
            &nbsp;{label}
          </label>
        )}
      </div>
    </Form.Group>
  )
}

export default FormCheckbox
