import Axios from '../config/Axios'
export const addCustomerService = async (data) => {
  const response = await Axios.post('customer/', data)
  return response
}

export const bulkCreateCustomersService = async (data) => {
  const response = await Axios.post('/customer/bulk', data)
  return response
}

export const updateCustomerService = async (data, id) => {
  const response = await Axios.put(`customer/${id}`, data)
  return response
}

export const getCustomerService = async (id) => {
  const response = await Axios.get(`customer/${id}`)
  return response
}

export const deleteCustomerService = async (id) => {
  const response = await Axios.delete(`customer/${id}`)
  return response
}

export const getAllCustomersService = async () => {
  const response = await Axios.get('customer/all')
  return response
}

export const getCustomersCountService = async () => {
  const response = await Axios.get('/customer')
  return response
}
