import './IntegrationsStyles.scss'
import { Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion'
import FormButton from '../../components/FormButton'
import Banner from '../../components/Banner'

const IntegrationsComponent = () => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Banner title="INTEGRATIONS" />
      <div className="integrations-container">
        <Row>
          <Col>
            <div>
              <h5 className="integrations-subtitle">PSA</h5>
              <Row>
                <Col>
                  <div className="integrations-button">Connectwise</div>
                  <div className="integrations-button">Kasaya BMS</div>
                  <div className="integrations-button">SyncroMSP</div>
                </Col>
                <Col>
                  <div className="integrations-button">AutoTask</div>
                  <div className="integrations-button">Altera</div>
                </Col>
              </Row>
            </div>
            <div>
              <h5 className="integrations-subtitle">ACCOUNTING</h5>
            </div>
            <div>
              <h5 className="integrations-subtitle">RMM</h5>
            </div>
          </Col>
          <Col></Col>
        </Row>
        <div className="integrations-buttons-container">
          <FormButton label="SAVE" width={100} />
        </div>
      </div>
    </motion.div>
  )
}

export default IntegrationsComponent
