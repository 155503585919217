const LensIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2857 12.5714L20 18.2857L18.2857 20L12.5714 14.2857V13.3829L12.2629 13.0629C10.96 14.1829 9.26857 14.8571 7.42857 14.8571C5.45839 14.8571 3.56891 14.0745 2.17578 12.6814C0.782651 11.2882 0 9.39875 0 7.42857C0 5.45839 0.782651 3.56891 2.17578 2.17578C3.56891 0.782651 5.45839 0 7.42857 0C9.39875 0 11.2882 0.782651 12.6814 2.17578C14.0745 3.56891 14.8571 5.45839 14.8571 7.42857C14.8571 9.26857 14.1829 10.96 13.0629 12.2629L13.3829 12.5714H14.2857ZM7.42857 1.71429L6.8 1.74857C6.52571 2.34286 6.10286 3.34857 5.79429 4.57143H9.06286C8.75429 3.34857 8.33143 2.34286 8.05714 1.74857C7.85143 1.71429 7.64571 1.71429 7.42857 1.71429ZM12.3771 4.57143C11.7029 3.39429 10.6171 2.48 9.31429 2.03429C9.58857 2.64 9.94286 3.52 10.2057 4.57143H12.3771ZM2.48 4.57143H4.65143C4.91429 3.52 5.26857 2.64 5.54286 2.03429C4.24 2.48 3.15429 3.39429 2.48 4.57143ZM1.71429 7.42857C1.71429 8 1.80571 8.60571 1.97714 9.14286H4.42286L4.28571 7.42857L4.42286 5.71429H1.97714C1.80571 6.25143 1.71429 6.85714 1.71429 7.42857ZM12.88 9.14286C13.0514 8.60571 13.1429 8 13.1429 7.42857C13.1429 6.85714 13.0514 6.25143 12.88 5.71429H10.4343C10.5257 6.28571 10.5714 6.85714 10.5714 7.42857C10.5714 8 10.5257 8.57143 10.4343 9.14286H12.88ZM5.56571 5.71429L5.42857 7.42857L5.56571 9.14286H9.29143C9.38286 8.57143 9.42857 8 9.42857 7.42857C9.42857 6.85714 9.38286 6.28571 9.29143 5.71429H5.56571ZM7.42857 13.1429C7.63429 13.1429 7.84 13.1429 8.03429 13.1086C8.32 12.5143 8.75429 11.5086 9.06286 10.2857H5.79429C6.10286 11.5086 6.53714 12.5143 6.82286 13.1086L7.42857 13.1429ZM12.3771 10.2857H10.2057C9.94286 11.3371 9.58857 12.2171 9.31429 12.8229C10.6171 12.3771 11.7029 11.4629 12.3771 10.2857ZM2.48 10.2857C3.15429 11.4629 4.24 12.3771 5.54286 12.8229C5.26857 12.2171 4.91429 11.3371 4.65143 10.2857H2.48Z"
        fill="black"
      />
    </svg>
  )
}

export default LensIcon
