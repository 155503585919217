import './DashboardStyles.scss'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import IconWithInfo from '../../components/IconWithInfo'
import NewBreachesIcon from '../../assets/icons/newBreaches.svg'
import SubscriptionIcon from '../../assets/icons/subscription.svg'
import DomainsIcon from '../../assets/icons/domains.svg'
import CustomersIcon from '../../assets/icons/customers.svg'
import EmailsIcon from '../../assets/icons/emails.svg'
import ProspectingIcon from '../../assets/icons/prospecting.svg'
import Banner from '../../components/Banner'
import { routes } from '../../config/constants'

const DashboardComponent = ({
  icons,
  news,
  showModal,
  handleCloseModal,
  breaches,
  dashboardContent,
}) => {
  return (
    <motion.div
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <Banner title="DASHBOARD" close={false} />
      <div className="dashboard-container">
        <Row>
          <Col>
            <div className="dashboard-header-container">
              <IconWithInfo
                icon={NewBreachesIcon}
                text={icons.newBreaches}
                bottomText="New Breaches"
                width={50}
                height={50}
                color="danger"
                style={{ marginRight: 2 }}
              />
              <IconWithInfo
                icon={SubscriptionIcon}
                text={icons.subscription}
                bottomText="Subscription"
                width={50}
                height={50}
                color="primary"
                style={{ marginRight: 2 }}
              />
              <IconWithInfo
                icon={DomainsIcon}
                text={icons.domains}
                bottomText="Domains"
                width={50}
                height={50}
                color="primary"
                style={{ marginRight: 2 }}
              />
              <IconWithInfo
                icon={CustomersIcon}
                text={icons.customers}
                bottomText="Customers"
                width={50}
                height={50}
                color="primary"
                style={{ marginRight: 2 }}
              />
              <IconWithInfo
                icon={EmailsIcon}
                text={icons.emails}
                bottomText="Emails"
                width={50}
                height={50}
                color="primary"
                style={{ marginRight: 2 }}
              />
              <IconWithInfo
                icon={ProspectingIcon}
                text={icons.prospecting}
                bottomText="Prospecting"
                width={50}
                height={50}
                color="primary"
              />
            </div>
            <Link to={routes.BREACHES_ROUTE}>
              <p className="dashboard-subtitle">LATEST BREACHES</p>
            </Link>
            <Table className="dashboard-table" striped borderless style={{ marginBottom: 60 }}>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Email Address</th>
                  <th className="text-center">Plain Text Password</th>
                  <th>Publish Date</th>
                  <th>Breach Source</th>
                </tr>
              </thead>
              <tbody>
                {breaches?.map(breach => (
                  <tr key={breach.id}>
                    <td>{breach.customer?.companyName ? breach.customer?.companyName : breach.msp?.companyName ? breach.msp?.companyName : "The MSP"}</td>
                    <td>{breach.email}</td>
                    <td className="text-center">{breach.password_plaintext ? 'yes' : 'no'}</td>
                    <td>{breach.spycloud_publish_date.slice(0, 10)}</td>
                    <td>{breach.breach?.title || 'unknown'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          {/* <Col xs={6}>
            <div dangerouslySetInnerHTML={{ __html: dashboardContent?.description }}></div>
          </Col> */}
        </Row>
        {/* <Row>
          <Col>
            <Link to={routes.NEWS_ROUTE}>
              <h3 className="dashboard-news-title">LATEST NEWS</h3>
            </Link>
            <div className="dashboard-news">
              {news.map(oneNews => (
                <div key={oneNews.id}>
                  <h5 className="title">{oneNews.title}</h5>
                  <div dangerouslySetInnerHTML={{ __html: oneNews.description }}></div>
                </div>
              ))}
            </div>
          </Col>
        </Row> */}
      </div>
    </motion.div>
  )
}

export default DashboardComponent
