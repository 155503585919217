import { Modal, Button } from 'react-bootstrap'
import './Modal.scss'
import { Link } from 'react-router-dom'

const DarkWebModal = ({
  title,
  message,
  buttonMessage = 'Ok',
  redirect = '#',
  handleCloseModal,
  showModal,
  handleClose,
  children,
  closeBtn=false,
  footer = true
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title className='modal-title'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children || message}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Link to={redirect}>
         
          <Button
              variant='dark'
              className='readonly-btn white-btn'
              onClick={handleCloseModal}
            >
              {buttonMessage}
            </Button>
            {closeBtn && (<Button
            style={{marginRight:'10px'}}
              variant='dark'
              className='readonly-btn white-btn'
              onClick={handleClose}
            >
              {'Cancel'}
            </Button>)}
           
          </Link>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default DarkWebModal
