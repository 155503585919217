import './AdminPublicDataSources.scss'
import questionIcon from '../../assets/icons/question.svg'

const AdminPublicDataSources = ({ children }) => {
  return (
    <div className="admin-public-data-sources-children">
      {children}
      <img src={questionIcon} style={{ position: 'absolute', right: 12, top: 60 }} />
      <div className="admin-public-data-sources-container">
        <h1>Public Data Sources</h1>
        <p>
          Public sources include sites that anybody on the internet can easily visit to download or
          purchase a list of leaked records. This data includes pastebin links, public leak forums,
          and direct downloads on file sharing networks.
        </p>
      </div>
    </div>
  )
}

export default AdminPublicDataSources
