const crypto = require('crypto')

export const handleCheckboxClick = (e ,setChecked ) =>{
    { e.target.checked ? setChecked(true) : setChecked(false)}
    }

 export   const hashPassword = (pass_plaintext,type) =>{
      try {
        if (pass_plaintext  && pass_plaintext?.length >= 3) {
          return pass_plaintext.slice(0, 3) + "*".repeat(pass_plaintext.length - 3);
          }else if(!pass_plaintext  && type && type.trim() !== 'plaintext'){
              return 'Encrypted'
          }else if(pass_plaintext && !type){
            return 'No Password'
          }else{
              return 'None'
            }
      } catch (error) {
        return error
      }
      }

    export   const decryptPassword = (encryptedPassword)=>{
        try {
            const decipher = crypto.createDecipheriv("aes-256-cbc",process.env.REACT_APP_SECURITY_KEY,process.env.REACT_APP_INIT_VECTOR);
                let decryptedData = decipher.update(encryptedPassword, "hex", "utf8");
                decryptedData += decipher.final("utf8");
                    return decryptedData
        } catch (error) {
            console.log(error)
            return error
        }
       
            }