import { createContext } from 'react'
// import {login, signup, verifyauth} from '../services/auth.service';

export const authContext = createContext()

const AuthContext = (props) => {
  /* const [user, setUser] = useState({});
	const [isAuth, setIsAuth] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false); */

  return (
    <authContext.Provider value={{}}>{props.children}</authContext.Provider>
  )
}

export default AuthContext
