import MspMenu from '../../components/MspMenu'
import FeatureRequestsComponent from './FeatureRequestsComponent.jsx'
import { useEffect, useState } from 'react'
import {
  getAllFeatureRequestsService,
  addFeatureRequestService,
  plusOneFeatureRequestService,
} from '../../services/featureRequest.service'
import { getAllFeatureRequestCategoriesService } from '../../services/featureRequestCategory.service'
import { schema } from '../../schemas/featureRequest.schema'
import { featureRequestErrorHandler } from './errorHandler'

import { useFormik } from 'formik'

const FeatureRequestsContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showFeatureDetails, setShowFeatureDetails] = useState({})
  const [featureRequests, setFeatureRequests] = useState([])
  const [categories, setCategories] = useState([])

  async function getAllFeaturesRequestsFromAPI() {
    try {
      const response = await getAllFeatureRequestsService()
      let result = response.data.data

      result = response.data.data.filter(featureRequest => featureRequest.featureRequestStatus)
      let groupByStatus = result.reduce((prevValue, newValue) => {
        const { featureRequestStatus } = newValue
        const { name } = featureRequestStatus
        prevValue[name] = prevValue[name] ?? []
        prevValue[name].push(newValue)
        return prevValue
      }, {})

      let groupByStatusOrdered = Object.entries(groupByStatus)
      groupByStatusOrdered = groupByStatusOrdered.sort(([a], [b]) => {
        if (a === b) {
          return 0
        }

        if (a === 'Waiting for MSP Votes') {
          return -1
        } else if (a === 'Under Review' && b !== 'Waiting for MSP Votes') {
          return -1
        } else if (a === 'Approved and In Development' && b !== 'Waiting for MSP Votes' && b !== 'Under Review'){
          return -1
        } else if (a === 'Released' && b !== 'Waiting for MSP Votes' && b !== 'Under Review' && b !== 'Approved and In Development') {
          return -1
        } else {
          return 1
        }
      })

      setFeatureRequests(groupByStatusOrdered)
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  async function getAllCategoriesFromAPI() {
    try {
      const response = await getAllFeatureRequestCategoriesService()
      setCategories(response.data.data)
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllFeaturesRequestsFromAPI()
    getAllCategoriesFromAPI()
  }, [])

  const initialValues = {
    title: '',
    description: '',
    featureRequestCategoryId: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await addFeatureRequestService(data)
        getAllFeaturesRequestsFromAPI()
        setShowModal({
          show: true,
          title: 'SENT SUCCESSFULLY',
          message: 'Thank you for your feature request. Your feedback is valuable for our progress.',
        })
        actions.resetForm()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(featureRequestErrorHandler(error))
        actions.resetForm()
      }
    },
  })

  async function handlePlusOneFeatureRequestClick(e) {
    const { id } = e.target
    try {
      await plusOneFeatureRequestService(id)
      getAllFeaturesRequestsFromAPI()
    } catch (error) {
      setShowModal(featureRequestErrorHandler(error))
    }
  }

  function handleFeatureDetailsClick(e) {
    const { id } = e.target
    const featureRequest = featureRequests.find(featureRequest => featureRequest.id === id)
    setShowFeatureDetails({ show: true, data: featureRequest })
  }

  function handleOnCloseFeatureDetails() {
    setShowFeatureDetails({})
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <FeatureRequestsComponent
          formik={formik}
          featureRequests={featureRequests}
          categories={categories}
          isLoading={isLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          showFeatureDetails={showFeatureDetails}
          handleFeatureDetailsClick={handleFeatureDetailsClick}
          handleOnCloseFeatureDetails={handleOnCloseFeatureDetails}
          handlePlusOneFeatureRequestClick={handlePlusOneFeatureRequestClick}
        />
      </MspMenu>
    </>
  )
}

export default FeatureRequestsContainer
