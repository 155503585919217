import MdwMenu from '../../components/MdwMenu'
import AdminAddNewsComponent from './AdminAddNewsComponent.jsx'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { schema as categorySchema } from '../../schemas/newsCategory.schema'
import { schema as newsSchema } from '../../schemas/news.schema'
import { newsErrorHandler } from './errorHandler.js'
import {
  getAllNewsCategoriesService,
  addNewsCategoryService,
  deleteNewsCategoryService,
} from '../../services/newsCategory.service'
import { addNewsService } from '../../services/news.service'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { adminRoutes } from '../../config/constants'

const AdminAddNewsContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [showNewsCategories, setShowNewsCategories] = useState(false)
  const [categories, setCategories] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState)
  }

  const newsInitialValues = {
    title: '',
    newsCategoryId: '',
    description: '',
  }

  async function getAllCategoriesFromAPI() {
    try {
      const allCategoriesResponse = await getAllNewsCategoriesService()
      setCategories(allCategoriesResponse.data.data)
    } catch (error) {
      setShowModal(newsErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllCategoriesFromAPI()
  }, [])

  const categoryFormik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: categorySchema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await addNewsCategoryService(data)
        actions.resetForm()
        getAllCategoriesFromAPI()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(newsErrorHandler(error))
      }
    },
  })

  const newsFormik = useFormik({
    initialValues: newsInitialValues,
    validationSchema: newsSchema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        data.description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        await addNewsService(data)
        actions.resetForm()
        setIsLoading(false)
        setShowModal({
          show: true,
          title: 'CREATED SUCCESSFULLY',
          message: `The news was created correctly`,
          redirect: adminRoutes.NEWS_ROUTE,
        })
      } catch (error) {
        setIsLoading(false)
        setShowModal(newsErrorHandler(error))
      }
    },
  })

  async function handleDeleteCategory(e) {
    try {
      const { id } = e.target
      await deleteNewsCategoryService(id)
      getAllCategoriesFromAPI()
    } catch (error) {
      setShowModal(newsErrorHandler(error))
    }
  }

  function handleNewsCategoryClick() {
    setShowNewsCategories(true)
  }

  function handleOnCloseNewsCategories() {
    setShowNewsCategories(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminAddNewsComponent
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          categories={categories}
          newsFormik={newsFormik}
          categoryFormik={categoryFormik}
          handleDeleteCategory={handleDeleteCategory}
          isLoading={isLoading}
          showNewsCategories={showNewsCategories}
          handleNewsCategoryClick={handleNewsCategoryClick}
          handleOnCloseNewsCategories={handleOnCloseNewsCategories}
        />
      </MdwMenu>
    </>
  )
}

export default AdminAddNewsContainer
