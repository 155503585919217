import * as React from 'react'

export default function Admin(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
      <path
        d="M8.756 12.15a3.15 3.15 0 110-6.3 3.15 3.15 0 010 6.3zm6.687-2.277c.036-.288.063-.576.063-.873 0-.297-.027-.594-.063-.9l1.9-1.467a.454.454 0 00.107-.576l-1.8-3.114a.442.442 0 00-.549-.198l-2.24.9c-.469-.351-.955-.657-1.522-.882L11.006.378a.455.455 0 00-.45-.378h-3.6a.455.455 0 00-.45.378l-.333 2.385c-.567.225-1.053.531-1.52.882l-2.242-.9a.442.442 0 00-.549.198l-1.8 3.114a.444.444 0 00.108.576L2.07 8.1a7.696 7.696 0 00-.064.9c0 .297.027.585.063.873L.17 11.367a.444.444 0 00-.108.576l1.8 3.114c.108.198.351.27.55.198l2.24-.909c.468.36.954.666 1.521.891l.333 2.385a.455.455 0 00.45.378h3.6a.455.455 0 00.45-.378l.333-2.385a6.553 6.553 0 001.521-.891l2.241.909c.198.072.441 0 .55-.198l1.8-3.114a.454.454 0 00-.109-.576l-1.899-1.494z"
        fill="#000"
      />
    </svg>
  )
}
