const ErrorIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.27 0L0 5.27V12.73L5.27 18H12.73L18 12.73V5.27L12.73 0H5.27ZM5.41 4L9 7.59L12.59 4L14 5.41L10.41 9L14 12.59L12.59 14L9 10.41L5.41 14L4 12.59L7.59 9L4 5.41"
        fill="black"
      />
    </svg>
  )
}

export default ErrorIcon
