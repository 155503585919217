import './Banner.scss'
import { Link } from 'react-router-dom'
import { routes } from '../../config/constants'
import CrossIcon from '../../assets/icons/crossWhite.svg'

const Banner = ({ title, style, close = true, redirect = routes.DASHBOARD_ROUTE, onClick }) => {
  return (
    <div className="banner-container" style={{ ...style }}>
      <h1>{title}</h1>
      {onClick ? (
        <img
          onClick={onClick}
          src={CrossIcon}
          alt="Cross icon to close page"
          width={25}
          height={25}
        />
      ) : (
        <>
          {close && (
            <Link to={redirect}>
              <img src={CrossIcon} alt="Cross icon to close page" width={25} height={25} />
            </Link>
          )}
        </>
      )}
    </div>
  )
}

export default Banner
