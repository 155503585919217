import Axios from '../config/Axios'

export const getAllBreachesOfACustomerService = async (customerId, page, limit, domain, email) => {
  const response = await Axios.get(`/first-breach/${customerId}/all?page=${page}&limit=${limit}&domain=${domain ?? ''}&email=${email ?? ''}`)
  return response
}

export const getAllBreachesOfAMSPService = async () => {
  const response = await Axios.get(`/first-breach/`)
  return response
}

export const sendABreachAlertService = async breachId => {
  const response = await Axios.post(`/first-breach/alert/${breachId}`)
  return response
}

export const archiveABreachService = async breachId => {
  const response = await Axios.post(`/first-breach/archive/${breachId}`)
  return response
}

export const resetFirstBreachService = async (domainOrEmail, customerId) => {
  const response = await Axios.post('/first-breach/reset', { domainOrEmail, customerId })
  return response
}

export const ArchiveAllFirstBreachService = async (selectData) => {
  const response = await Axios.post('/first-breach/status', {selectData})
  return response
}
