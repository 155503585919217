import React, { useEffect, useState } from 'react';
import AdminBanner from '../../components/AdminBanner';
import { adminRoutes } from '../../config/constants';
import { Row, Col, Form, Button  } from 'react-bootstrap';
import FormInput from '../../components/FormInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getRateLimit, setRateLimit,getBatchData } from '../../services/rateLimit.service';
import Modal from '../../components/Modal'
import AccordionTable from './AccordionTable';
import Spinner from 'react-bootstrap/Spinner';


const validationSchema = Yup.object({
    rateLimit: Yup.number()
        .min(1, 'Rate Limit must be at least 1')
        .max(20, 'Rate Limit must be 20 or less')
        .required('Rate Limit is required'),
    batchSize: Yup.number()
        .min(1, 'Batch Size must be at least 1')
        .max(200, 'Batch Size must be 200 or less')
        .required('Batch Size is required'),
});

const AdminRateLimitComponent = () => {
    const [limitData, setLimitData] = useState(null);
    const [modelValue, setModelValue] = useState(false);
    const [breachResponse,setBreachResponse] = useState([])
    const [totalBreach,setTotalBreach]= useState(0)
    const [loader,setLoader] = useState(false)
    const getRateLimitData = async () => {
        try {
            setLoader(true)
            const batchResponse= await getBatchData()
            setBreachResponse(batchResponse.data)
            const totalBreachData = batchResponse?.data?.data?.reduce((acc, curr) => {
                return acc + (curr?.emailData?.length || 0);
              }, 0);     
            setTotalBreach(totalBreachData)
            const res = await getRateLimit();
            if (res?.data) {
                setLimitData({
                    rateLimit: res.data?.data?.[0]?.rateLimit || 1,
                    batchSize: res.data?.data?.[0]?.batchSize || 100,
                    emailLimit:res.data?.data?.[0]?.emailLimit || 2000
                });
            } else {
                console.error('No data returned from API');
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error('Error fetching rate limit data:', error);
        }
    };
    useEffect(() => {
        getRateLimitData();
    }, []);

    

    return (
        <>
   <Modal
        showModal={modelValue}
        handleCloseModal={()=>{setModelValue(false)}}
        title={"Success"}
        message={"Data has been submitted"}
      />

            <AdminBanner  title="RATE LIMIT" redirect={adminRoutes.ADMIN_ROUTE} />
                <Formik
                    initialValues={{
                        rateLimit: limitData?.rateLimit || 1,
                        batchSize: limitData?.batchSize || 100,
                        emailLimit:limitData?.emailLimit || 2000
                    }}
                    validationSchema={validationSchema}
                    enableReinitialize={true} // This allows the form to reinitialize when limitData changes
                    onSubmit={async(values) => {
                        setModelValue(true);
                        await setRateLimit(values)
                        getRateLimitData();
                    }}
                >
                    {formik => (
                        <Form noValidate onSubmit={formik.handleSubmit}  style={{marginTop:'10px'}}>
                            <Row style={{ margin: 0, padding: '5px' }}>
                                <Col xs={8} md={4} lg={2}>
                                    <FormInput
                                        id="rateLimit"
                                        height={50}
                                        label="Rate Limit (In Minutes)"
                                        formik={formik}
                                        placeholder="Enter Rate Limit (1-20)"
                                        type='number'
                                    />
                                </Col>
                                <Col xs={8} md={4} lg={2}>
                                    <FormInput
                                        id="batchSize"
                                        height={50}
                                        label="Batch Size (Max 200)"
                                        formik={formik}
                                        placeholder="Enter Batch Size (1-200)"
                                        type='number'
                                    />
                                </Col>
                                <Col xs={8} md={4} lg={2}>
                                    <FormInput
                                        id="emailLimit"
                                        height={50}
                                        label="Email Alert Limit"
                                        formik={formik}
                                        placeholder="Enter Email Limit"
                                        type='number'
                                    />
                                </Col>
                                <Col xs={4} md={4} lg={1}>
                            <Button style={{ marginTop: '22px' }} type="submit" variant="primary" onClick={()=>{}}>Submit</Button>
                                </Col>
                                <Col style={{ marginTop: '26px' }} xs={4} md={4} lg={4}>
                                Total Breaches: {totalBreach}
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <div style={{padding:'10px 20px'}}>
                <b>Rate Limit:</b> Rate limit determines the gap between processing each batch and will take effect in the next email scheduler run.                </div>
                <div style={{padding:'10px 20px'}}>
                <b>Batch Size:</b> Batch size determines the number of breaches processed in each cycle to optimize performance and prevent timeouts.
                </div>
                <div style={{padding:'10px 20px'}}>
                <b>Email Alert Limit:</b> Email sending will be paused and all batches will be paused if the number of breaches exceeds the allowable limit
                </div>
                {
                    loader ?
                    <div style={{height:"50vh",display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Spinner animation="border" variant="primary" />
                        </div>
                    :<AccordionTable breachResponse={breachResponse} fetchUpdate={getRateLimitData}/>

                }

        </>
    );
};

export default AdminRateLimitComponent;