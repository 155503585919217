const PackagesIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.45 15.39L9.36 19.97V16.4L14.4 13.63L17.45 15.39ZM18 14.89V5.31L15.04 7V13.18L18 14.89ZM0.55 15.39L8.64 19.96V16.4L3.6 13.63L0.55 15.39ZM0 14.89V5.31L2.96 7V13.18L0 14.89ZM0.35 4.69L8.64 0V3.45L3.33 6.37L3.28 6.39L0.35 4.69ZM17.65 4.69L9.36 0V3.45L14.67 6.38L14.71 6.4L17.65 4.69ZM8.64 15.59L3.67 12.85V7.44L8.64 10.31V15.59ZM9.36 15.59L14.33 12.86V7.44L9.36 10.31V15.59ZM4 6.81L9 4.06L14 6.81L9 9.69L4 6.81Z"
        fill="black"
      />
    </svg>
  )
}

export default PackagesIcon
