import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import fontSize from '../../assets/icons/fontSize.svg'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import FormButton from '../../components/FormButton'
import { Form } from 'react-bootstrap'

const BrandingReportsComponent = ({
  editorState,
  onEditorStateChange,
  editorStateCleanBill,
  onEditorStateCleanBillChange,
  formik,
  formikTest,
  handleClickVariable,
  isLoading,
  customers,
}) => {
  return (
    <>
      <Form noValidate onSubmit={formikTest.handleSubmit}>
        <div className="branding-customer-report-test-container">
          <FormSelect
            id="customer"
            options={customers}
            type="customers"
            color="primary"
            label="Select Company"
            width="40%"
            style={{ minWidth: 200 }}
            formik={formikTest}
          />
          <FormInput
            id="emailToSend"
            color="primary"
            label="Email to Send Report to"
            width="45%"
            style={{ minWidth: 200 }}
            formik={formikTest}
          />
          <FormButton type="submit" label="SEND TEST" width="10%" style={{ minWidth: 120 }} />
        </div>
      </Form>

      <h5 style={{ fontFamily: 'Roboto', fontSize: 14, marginBottom: 20 }}>
        The customer monthly reports are sent on the 1st of each month and include a report of the
        last 30 days. If the customer has had breaches in the last 30 days then they will receive a
        PDF report with your MSP branding and details of the breaches that have occured. If they
        have not had any breaches then they will receive the email below for clean bill of health.
        <br />
        You are required to set up your content for the accompanying emails in your branding tab.{' '}
        <br />
        <small>
          (Monthly Customer Report Emails will be sent from: noreply@darkwebsecurity.cloud. Please
          ensure you white list this email address with your clients to ensure smooth delivery of
          the monthly report)
        </small>
      </h5>

      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="branding-customer-report-container">
          <h3 className="branding-customer-report-title">Customer Report Email</h3>
          <FormInput
            id="customerEmailSubject"
            formik={formik}
            color="#0D66D0"
            placeholder="Dark Web Alert - %%companyName%% - %%firstName%% %%lastName%%"
            style={{ maxWidth: 800 }}
            height={50}
            label="Customer Report Email Subject - Leave unchanged for the default subject format"
          />
          <h5 className="branding-subject-variables">
            Variables allowed in the subject are:{' '}
            <span onClick={handleClickVariable}>%%companyName%%</span>,{' '}
            <span onClick={handleClickVariable}>%%firstName%%</span>,{' '}
            <span onClick={handleClickVariable}>%%lastName%%</span>,{' '}
            <span onClick={handleClickVariable}>%%reportFrequency%%</span>
          </h5>
          <h3 className="branding-customer-report-subtitle">Customer Report Email Body</h3>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="branding-customer-report-editor-editor"
            toolbar={{
              options: [
                'inline',
                'fontSize',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'image',
                'remove',
                'history',
              ],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
              },
              fontSize: {
                icon: fontSize,
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
            }}
          />
          <h3 style={{ marginTop: 15 }} className="branding-customer-report-subtitle">
            Customer Report Clean Bill of Health Email Body
          </h3>
          <Editor
            editorState={editorStateCleanBill}
            onEditorStateChange={onEditorStateCleanBillChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="branding-customer-report-editor-editor"
            toolbar={{
              options: [
                'inline',
                'fontSize',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'image',
                'remove',
                'history',
              ],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
              },
              fontSize: {
                icon: fontSize,
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
            }}
          />
        </div>
        <div className="text-right" style={{ marginTop: 7 }}>
          <FormButton type="submit" width={100} label={isLoading ? 'LOADING...' : 'SAVE'} />
        </div>
      </Form>
    </>
  )
}

export default BrandingReportsComponent
