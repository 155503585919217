const SecureIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 4C9.79565 4 10.5587 4.31607 11.1213 4.87868C11.6839 5.44129 12 6.20435 12 7C12 7.79565 11.6839 8.55871 11.1213 9.12132C10.5587 9.68393 9.79565 10 9 10C8.20435 10 7.44129 9.68393 6.87868 9.12132C6.31607 8.55871 6 7.79565 6 7C6 6.20435 6.31607 5.44129 6.87868 4.87868C7.44129 4.31607 8.20435 4 9 4ZM14.13 16C12.92 17.85 11.11 19.24 9 19.92C6.89 19.24 5.08 17.85 3.87 16C3.53 15.5 3.24 15 3 14.47C3 12.82 5.71 11.47 9 11.47C12.29 11.47 15 12.79 15 14.47C14.76 15 14.47 15.5 14.13 16Z"
        fill="black"
      />
    </svg>
  )
}

export default SecureIcon
