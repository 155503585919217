import './AdminReportsStyles.scss'
import AdminBanner from '../../components/AdminBanner'
import { Row, Col } from 'react-bootstrap'

const AdminReportsComponent = ({ utilizationReport, spycloudReport }) => {
  return (
    <>
      <AdminBanner title="REPORTS" />
      <div className="admin-reports-container">
        <Row>
          <Col xs={9}>
            <p className="admin-reports-text-italic">
              {utilizationReport ? (
                <a
                  className="admin-reports-text-bold"
                  download="utilisationReport.csv"
                  href={utilizationReport}
                >
                  <span>Utilisation Report - </span>
                </a>
              ) : (
                <span className="admin-reports-text-bold">Utilisation Report - </span>
              )}
              This will list each MSP and give total domains and emails they can use vs used.
            </p>
            <p className="admin-reports-text-italic">
              {spycloudReport ? (
                <a
                  className="admin-reports-text-bold"
                  download="spycloudReport.csv"
                  href={spycloudReport}
                >
                  <span>Spycloud Report - </span>
                </a>
              ) : (
                <span className="admin-reports-text-bold">Spycloud Report - </span>
              )}
              This will list total number of domains being monitored on the day the report is run.
              Also in the background on the last day of the month this report needs to run
              automatically and be sent to: spycloud@yourcloudworks.com
            </p>
          </Col>
          <Col>
            <h3 className="admin-reports-text-bold">REPORTS</h3>
            <p className="admin-reports-text-bold" style={{ fontWeight: 300 }}>
              All reports will be in CSV format that we can download once run.
            </p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AdminReportsComponent

/* 
        <div className="admin-reports-text">
          {spycloudReport ? (
            <a download="spycloudReport.csv" href={spycloudReport}>
              <h3>SpyCloud Report</h3>
            </a>
          ) : (
            <h3>SpyCloud Report</h3>
          )}
          <p>
            This will list total number of domains being monitored on the day the report is run.
            Also in the background on the last day of the month this report needs to run
            automatically and be sent to: spycloud@yourcloudworks.com
          </p>
        </div> */
