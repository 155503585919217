import MspMenu from '../../components/MspMenu'
import HelpdeskAlertingComponent from './HelpdeskAlertingComponent.jsx'
import { useFormik } from 'formik'
import { testHelpdeskAlertingService } from '../../services/helpdeskAlerting.service'
import { getMyMspService, updateMyMspService } from '../../services/msp.service'
import { getAllCustomersService } from '../../services/customer.service'
import { schema } from '../../schemas/helpdeskAlerting.schema'
import { helpdeskAlertingErrorHandler } from './errorHandler'
import { useState, useEffect } from 'react'

const HelpdeskAlertingContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTest, setIsLoadingTest] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [initialValues, setInitialValues] = useState({
    helpdeskEmailAddress: '',
    helpdeskEmailSubject: '',
  })
  const [helpdeskTest, setHelpdeskTest] = useState({
    testCompany: '',
    testEmailAddress: '',
  })
  const [customers, setCustomers] = useState([])

  async function getAllCustomersFromAPI() {
    try {
      const response = await getAllCustomersService()
      const mspResponse = await getMyMspService()
      const { helpdeskEmailAddress, helpdeskEmailSubject } = mspResponse.data.data
      setInitialValues({ helpdeskEmailAddress, helpdeskEmailSubject })
      setCustomers([...response.data.data, {...mspResponse.data.data}])
    } catch (error) {
      setShowModal(helpdeskAlertingErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllCustomersFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await updateMyMspService(data, true)
        setShowModal({
          show: true,
          title: 'SUCCESSFUL UPDATE',
          message: 'The helpdesk alerting information has been updated',
        })
        actions.resetForm()
        setIsLoading(false)
        getAllCustomersFromAPI()
      } catch (error) {
        setIsLoading(false)
        setShowModal(helpdeskAlertingErrorHandler(error))
        actions.resetForm()
      }
    },
  })

  async function handleSubmitTestHelpdesk() {
    try {
      if (!(helpdeskTest.testCompany.length && helpdeskTest.testEmailAddress.length)) {
        return setShowModal({
          show: true,
          title: 'COULD NOT SEND',
          message: 'Could not send because one of the fields is invalid',
        })
      }
      setIsLoadingTest(true)
      await testHelpdeskAlertingService(helpdeskTest)
      setShowModal({
        show: true,
        title: 'SUCCESSFUL SEND TEST',
        message: 'The helpdesk test alerting has been sent',
      })
      setIsLoadingTest(false)
    } catch (error) {
      setIsLoadingTest(false)
      setShowModal(helpdeskAlertingErrorHandler(error))
    }
  }

  function handleOnChangeTestHelpdesk(id, e) {
    setHelpdeskTest(prevState => ({ ...prevState, [id]: e.target.value }))
  }

  function handleClickVariable(variable) {
    setInitialValues({
      ...formik.values,
      helpdeskEmailSubject: formik.values.helpdeskEmailSubject + ' ' + variable,
    })
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <HelpdeskAlertingComponent
          {...{
            formik,
            isLoading,
            isLoadingTest,
            showModal,
            handleCloseModal,
            helpdeskTest,
            handleOnChangeTestHelpdesk,
            customers,
            handleSubmitTestHelpdesk,
            handleClickVariable,
          }}
        />
      </MspMenu>
    </>
  )
}

export default HelpdeskAlertingContainer
