import MspUsersComponent from './MspUsersComponent.jsx'
import { useState, useEffect } from 'react'
import {
  addMdwUserService,
  updateMdwUserService,
  getMdwUserService,
  deleteMdwUserService,
  getAllMdwUsersService,
  sendMdwUserIdService,
  getMspMdwUserService,
} from '../../../services/mdwUser.service'
import { adminUserErrorHandler } from '../errorHandler'
import { schema } from '../../../schemas/adminMspUser.schema'
import { useFormik } from 'formik'
import { adminRoutes } from '../../../config/constants'
import { useHistory } from 'react-router-dom'
import { getMyUserService } from '../../../services/user.service'

const AdminMSPUsersContainer = ({ match }) => {
  const UserId = match.params.id
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [ users, setUsers ] = useState( [] )
  const [mspUsersTab, setMspUsersTab] = useState()
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    status: true,
    resetPassword: false,
  })
  const [show, setShow] = useState({});
  const [ account, setAccount ] = useState( {} )
  const [userType, setUserType] = useState('')
  const [mspData, setMspData] = useState({
    mspId: '',
    companyName: ''
  })

  useEffect(() => {
    async function getUserFromAPI() {
      if (UserId && userType === 'MSP') {
        try {
          const user = await getMdwUserService( UserId, userType )
          setInitialValues(prevState => ({ ...prevState, ...user.data.data }))
        } catch (error) {
          setShowModal(adminUserErrorHandler(error))
        }
      } else {
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: true,
          resetPassword: false,
        })
      }
    }

    async function getMyUserFromAPI() {
      try {
        const response = await getMyUserService()
        setAccount(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }

    getMyUserFromAPI()
    getUserFromAPI()
  }, [UserId])

  async function getAllUsersFromAPI(key) {
    try {
      const allMspUsers = await getAllMdwUsersService()
      setUsers( allMspUsers.data.data.mspMdwUser )
      if ( key === 'MSP' ){
        const response = await sendMdwUserIdService(mspData)
        setMspUsersTab(response.data.data)
      }
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllUsersFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async data => {
      try {
        setIsLoading(true)
        if(mspData.companyName.trim()) {
          if ( UserId && userType ) {
            data.mspId= mspData.mspId;
            data.companyData= mspData.companyName;
            const response = await updateMdwUserService(data, UserId, userType)
            const { username } = response.data.data
            await getAllUsersFromAPI(userType)
            setShowModal({
              show: true,
              title: 'MSP USER HAS BEEN UPDATED',
              message: `The user ${username} has been updated successfully`,
              redirect: adminRoutes.USERS_ROUTE,
            })
        } else {
            data.mspId= mspData.mspId;
            data.companyData= mspData.companyName;
            data.userType = "MSP";
            const response = await addMdwUserService(data)
            const { username } = response.data.data
            setShowModal({
              show: true,
              title: 'MSP USER HAS BEEN CREATED',
              message: `The user ${username} has been created successfully`,
            })
            getUserDataByMspId(mspData)
            getAllUsersFromAPI(userType)
        }
        setInitialValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: false,
          resetPassword: false,
        })
        setIsLoading(false)
        } else {
          setIsLoading(false)
          setShowModal({
            show: true,
            title: 'MSP USERS',
            message: `Please select MSP to add user`,
          })
        }
      } catch (error) {
        setIsLoading(false)
        setShowModal(adminUserErrorHandler(error))
      }
    },
  })

  async function handleStatusChange(e) {
    try {
      const userIdType = 'MSP'
      const data = {}
      const { id } = e.target
      if ( id ) {
        const user = mspUsersTab.find( user => user.userId === id )
        data.status= !user.status
        await updateMdwUserService(data, id, userIdType)
        getAllUsersFromAPI(userIdType)
      } else {
        setShowModal({
          show: true,
          title: 'YOU CANNOT DISABLE THIS USER',
          message: 'You cannot disable this user because it is this account',
        })
      }
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  async function handleDelete ( id ) {
    try {
      const userIdType = 'MSP'
      await deleteMdwUserService(id, userIdType)
      getAllUsersFromAPI(userIdType)
      handleClose()
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  function handleClickUser(e) {
    const { id } = e.target
    const user = 'MSP'
    setUserType(user)
    history.push(adminRoutes.USERS_ROUTE + '/' + id)
  }

  async function handleResetPassword(id) {
    try {
      const userIdType = 'MSP'
      const user = getUserDataByID( id );
      user.resetPassword = true
      await updateMdwUserService(user, id, userIdType)
      setShowModal({
        show: true,
        title: 'PASSWORD CHANGE REQUEST SENT',
        message: 'The user will receive an email with the link to reset their password',
      } )
      handleClose()
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  async function handleReset2fa(id) {
    try {
      const userIdType = 'MSP'
      await updateMdwUserService({ reset2faCode: true }, id, userIdType)
      setShowModal({
        show: true,
        title: '2FA CODE RESET SENT',
        message: "User's 2fa code has been reset",
      } )
      handleClose()
    } catch (error) {
      setShowModal(adminUserErrorHandler(error))
    }
  }

  const getUserDataByID = (id) => {
    const user = mspUsersTab.find( user => user.userId === id );
    return user
  }

  const handleShow = (id, key) => {
    const user = getUserDataByID(id)
    if (key === 'deleteUser') {
      setShow({
        showModal: true,
        title: 'Delete User Confirmation',
        message: 'Are you sure you want to remove the user?',
        handleOk: handleDelete,
        id,
      })
    } else if (key === 'reset2FA') {
      setShow({
        showModal: true,
        title: 'Reset 2FA User Confirmation',
        message: `Are you sure you want to reset 2FA of user ${user.firstName} ${user.lastName}?`,
        handleOk: handleReset2fa,
        id,
      })
    } else if (key === 'resetPassword') {
      setShow({
        showModal: true,
        title: 'Reset Password User Confirmation',
        message: `Are you sure you want to reset password of user ${user.firstName} ${user.lastName}?`,
        handleOk: handleResetPassword,
        id,
      })
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  const getUserDataByMspId = async (data) => {
    const response = await sendMdwUserIdService(data)
    setMspUsersTab(response.data.data)
  }

  const dropChange = async e => {
    let data = {}
    let getObjById = '';
    let selectedMspId = e.target.value;
    data.mspId= selectedMspId;

    if( users && users.length ) {
      getObjById = users.filter((el) => {
        return el.mspId === selectedMspId
      });
    }

    setMspData({ ...mspData,
      mspId: selectedMspId, 
      companyName: getObjById[0]?.companyName
    })

    if(data.mspId){
      await getUserDataByMspId(data);
    }
  }

  return (
    <>
      <MspUsersComponent
        account={account}
        formik={formik}
        isLoading={isLoading}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        users={users}
        UserId={UserId}
        handleStatusChange={handleStatusChange}
        handleDelete={handleDelete}
        handleClickUser={handleClickUser}
        handleResetPassword={handleResetPassword}
        handleReset2fa={handleReset2fa}
        dropChange={dropChange}
        mspUsersTab={mspUsersTab}
        userType={userType}
        mspData ={mspData}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show} 
      />
    </>
  )
}

export default AdminMSPUsersContainer
