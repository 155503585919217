import { Form } from 'react-bootstrap'

const FormSwitch = ({ id, label, onChange, color, value, mutedText, style,password }) => {
  return (
    <Form.Group style={style} controlId={id}>
      <Form.Check
        className="form-switch"
        type="switch"
        label={label}
        onChange={onChange}
        checked={password ? value : value[id]}
      />
      {mutedText && 
      <Form.Text className={color && `form-switch-${color}-color`}>{mutedText}</Form.Text>}
    </Form.Group>
  )
}

export default FormSwitch
