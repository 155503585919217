import * as Yup from 'yup'

export const schema = Yup.object({
  newPassword: Yup.string()
    .required('The password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*[a-zA-Z]).{25,50}$/,
      'The password must have at least 25 characters (Max 50), including lower case, upper case, a number and a special character.'
    ),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})
