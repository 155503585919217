import MdwMenu from '../../components/MdwMenu'
import Admin2FARequestComponent from './Admin2FARequestComponent'
import {
  getAllResetTwofaRequestsService,
  updateResetTwofaRequestService,
  deleteResetTwofaRequestService,
} from '../../services/resetTwofaRequest.service'
import { twofaRequestErrorHandler } from './errorHandler'
import { useEffect, useState } from 'react'

export default function Admin2FARequestContainer() {
  const [showModal, setShowModal] = useState({})
  const [resetTwofaRequests, setTwofaRequests] = useState(null)

  async function getAllResetTwofaRequestsFromAPI() {
    try {
      const response = await getAllResetTwofaRequestsService()
      setTwofaRequests(response.data.data)
    } catch (error) {
      setShowModal(twofaRequestErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllResetTwofaRequestsFromAPI()
  }, [])

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <MdwMenu>
      <Admin2FARequestComponent
        {...{
          resetTwofaRequests,
          showModal,
          handleCloseModal,
        }}
      />
    </MdwMenu>
  )
}
