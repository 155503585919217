import Axios from '../config/Axios'

export const getAllTrainingMaterials = async () => {
  return await Axios.get('/training-material/all')
}

export const deleteTrainingMaterial = async id => {
  return await Axios.delete(`/training-material/${id}`)
}

export const uploadTrainingMaterial = async data => {
  const { name, description, order, video, image } = data
  const formData = new FormData()
  formData.append('name', name)
  formData.append('description', description)
  formData.append('order', order)
  formData.append('video', video)
  formData.append('image', image)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return Axios.post('/training-material', formData, config)
}
