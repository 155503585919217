import MspMenu from '../../components/MspMenu'
import PreviousSearchesComponent from './PreviousSearchesComponent.jsx'
import { useEffect, useState } from 'react'
import { getPreviousSearchesService } from '../../services/prospectingReport.service'
import { postProspectingReportPDFService } from '../../services/pdf.services'
import { decryptPassword,handleCheckboxClick,hashPassword } from '../../utilities/decryptPass'



const PreviousSearchesContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [breaches, setBreaches] = useState({})
  const [allPreviousSearches, setAllPreviousSearches] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [previousSearches, setPreviousSearches] = useState({
    emails: [],
    domains: [],
  })

  async function getAllPreviousSearchesFromAPI() {
    try {
      const response = await getPreviousSearchesService()
      let prevSearches = response.data.data
      let date=process.env.REACT_APP_ENV_MSP=="staging"?new Date('2023-09-20'):new Date('2023-10-05')
      setAllPreviousSearches(prevSearches)
      prevSearches = prevSearches.map(prevSearch => {
         prevSearch?.previousSearchBreaches.map((preSearch)=>{
          if(preSearch?.password_plaintext && preSearch?.password_plaintext.trim() && new Date(preSearch.createdAt) >= date){
            preSearch.decryptPass = decryptPassword(preSearch?.password_plaintext)
          if (preSearch.decryptPass) {
            preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
          }  
          } else {
            preSearch.decryptPass = preSearch?.password_plaintext
            preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
          }
         }
        )
        prevSearch.previousSearchBreaches = prevSearch.previousSearchBreaches.sort((a, b) => {
          return new Date(b.spycloud_publish_date) - new Date(a.spycloud_publish_date)
        })
        return prevSearch
      })
      const emailsPrevSearches = prevSearches.filter(prevSearch => prevSearch.email !== null)
      const domainsPrevSearches = prevSearches.filter(prevSearch => prevSearch.domain !== null)
      setPreviousSearches({
        emails: emailsPrevSearches,
        domains: domainsPrevSearches,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllPreviousSearchesFromAPI()
  }, [])


  function handlePreviousSearchesClick(prevSearchId) {
    setChecked(false)
    const prevSearch = allPreviousSearches.filter(prevSearch => prevSearch.id === prevSearchId)[0]
    setBreaches({
      breaches: prevSearch.previousSearchBreaches,
      requestBy: prevSearch.email || prevSearch.domain,
      customerName: prevSearch.customerName,
      pdfUrl: prevSearch.pdfUrl,
      reportPdf: prevSearch.reportPdf,
    })
  }

  function handleCloseModal() {
    setShowModal({})
  }

  const downloadPdfReports = async data => {
    try {
      setIsLoading(true)
      const res = await postProspectingReportPDFService(data)
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Prospecting Report.pdf`) //or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      resolve()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <MspMenu>
        <PreviousSearchesComponent
          {...{
            showModal,
            handleCloseModal,
            breaches,
            previousSearches,
            handlePreviousSearchesClick,
            downloadPdfReports,
            isLoading,
            handleCheckboxClick,
            checked,
            setChecked
          }}
        />
      </MspMenu>
    </>
  )
}

export default PreviousSearchesContainer
