import MspMenu from '../../components/MspMenu'
import BreachCatalogueComponent from './BreachCatalogueComponent.jsx'
import { useEffect, useState } from 'react'
import {
  getBreachCatalogueAssetStatsService,
  getBreachCatalogueService,
  searchBreachCatalogueService,
} from '../../services/spycloud.service'

const BreachCatalogueContainer = () => {
  const [assetStats, setAssetStats] = useState({
    email: 0,
    password: 0,
    ipAddresses: 0,
    username: 0,
    pii: 0,
    geolocation: 0,
    phone: 0,
    financialInformation: 0,
  })
  const [breachCatalogue, setBreachCatalogue] = useState([])
  const [allBreachCatalogue, setAllBreachCatalogue] = useState([])
  const [breachCatalogueRange, setBreachCatalogueRange] = useState({
    offset: 0,
    limit: 20,
  })
  const [search, setSearch] = useState('')

  async function getBreachCatalogueFromAPI(offset, limit) {
    try {
      const response = await getBreachCatalogueService(offset, limit)
      setAllBreachCatalogue(prevState => [...prevState, ...response.data.data])
      setBreachCatalogue(prevState => [...prevState, ...response.data.data])
    } catch (error) {
      console.log(error)
    }
  }

  async function getBreachCatalogueAssetStatsFromAPI() {
    try {
      const response = await getBreachCatalogueAssetStatsService()
      setAssetStats(prevState => ({ ...prevState, ...response.data.data }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBreachCatalogueAssetStatsFromAPI()
  }, [])

  useEffect(() => {
    const { offset, limit } = breachCatalogueRange
    getBreachCatalogueFromAPI(offset, limit)
  }, [breachCatalogueRange])

  async function handleClickLoadMore() {
    setBreachCatalogueRange(prevState => ({
      offset: prevState.offset + 20,
      limit: prevState.limit,
    }))
  }

  async function handleClickFilter(filter) {
    setSearch('')
    if (filter === 'private') {
      setBreachCatalogue(allBreachCatalogue.filter(breach => breach.type === 'PRIVATE'))
    } else if (filter === 'public') {
      setBreachCatalogue(allBreachCatalogue.filter(breach => breach.type === 'PUBLIC'))
    } else {
      setBreachCatalogue(allBreachCatalogue)
    }
  }

  async function handleClickSearch() {
    try {
      const breachCatalogue = await searchBreachCatalogueService(search)
      setBreachCatalogue(breachCatalogue.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleChangeSearch(e) {
    const { value } = e.target
    if (value === '') setBreachCatalogue(allBreachCatalogue)
    setSearch(value)
  }

  return (
    <>
      <MspMenu>
        <BreachCatalogueComponent
          {...{
            breachCatalogue,
            assetStats,
            handleClickLoadMore,
            handleClickFilter,
            search,
            handleChangeSearch,
            handleClickSearch,
          }}
        />
      </MspMenu>
    </>
  )
}

export default BreachCatalogueContainer
