const NewsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4V16H17V18H2C0 18 0 16 0 16V4H2ZM19 2V12H6V2H19ZM19.3 0H5.7C4.76 0 4 0.7 4 1.55V12.45C4 13.31 4.76 14 5.7 14H19.3C20.24 14 21 13.31 21 12.45V1.55C21 0.7 20.24 0 19.3 0ZM7 3H10V8H7V3ZM18 11H7V9H18V11ZM18 5H12V3H18V5ZM18 8H12V6H18V8Z"
        fill="black"
      />
    </svg>
  )
}

export default NewsIcon
