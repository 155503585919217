export function resetPasswordErrorHandler(error) {
  const status = error?.response?.status || error?.message
  if (status === 500) {
    return {
      show: true,
      title: 'INTERNAL SERVER ERROR',
      message: "I don't know what happened, try again in a few moments",
    }
  } else if (status === 400) {
    return {
      show: true,
      title: 'BAD REQUEST',
      message: error.response.data.message,
    }
  } else if (status === 404) {
    return {
      show: true,
      title: 'NOT FOUND',
      message: error.response.data.message,
    }
  } else if (status === 'Network Error') {
    return {
      show: true,
      title: 'NETWORK ERROR',
      message: 'Could not connect to the API, please try again in a few moments',
    }
  } else {
    return {
      show: true,
      title: 'ERROR',
      message: status,
    }
  }
}
