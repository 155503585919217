import { routes } from '../../config/constants'

export function prospectingReportErrorHandler(error) {
  const status = error.response ? error.response.status : error.message
  if (status === 500) {
    return {
      show: true,
      title: 'INTERNAL SERVER ERROR',
      message: "I don't know what happened, try again in a few moments",
    }
  } else if (status === 400) {
    if (error.response.data.message === 'That email or domain has already been searched') {
      return {
        show: true,
        title: 'NOT ALLOWED',
        message: "I'm sorry but that domain/email has already been searched in your prospecting reports this month. Please check the previous searches.",
      }
    } else {
      return {
        show: true,
        title: 'BAD REQUEST',
        message: 'The data was rejected by the API',
      }
    }
  } else if (status === 401) {
    return {
      show: true,
      title: 'UNAUTHORIZED',
      message: 'This action could not be authorized, you may need to log in again',
      redirect: routes.LOGIN_ROUTE,
    }
  } else if (status === 503) {
    return {
      show: true,
      title: 'SERVICE UNAVAILABLE',
      message: 'The breach lookup service does not seem to be having a network error',
    }
  } else if (status === 'Network Error') {
    return {
      show: true,
      title: 'NETWORK ERROR',
      message: 'Could not connect to the API, please try again in a few moments',
    }
  } else {
    return {
      show: true,
      title: 'ERROR',
      message: status,
    }
  }
}
