import MspMenu from '../../components/MspMenu'
import ProspectingReportComponent from './ProspectingReportComponent.jsx'
import { schema } from '../../schemas/prospectingReport.schema'
import { useFormik } from 'formik'
import { useState } from 'react'
import { getBreachesForEmailOrDomainService } from '../../services/prospectingReport.service'
import { prospectingReportErrorHandler } from './errorHandler'
import { postProspectingReportPDFService } from '../../services/pdf.services'
import crypto from 'crypto'
import { decryptPassword,handleCheckboxClick,hashPassword } from '../../utilities/decryptPass'



const ProspectingReportContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [checkToggle, setCheckToggle] = useState(false);
  const [showModal, setShowModal] = useState({})
  const [breaches, setBreaches] = useState({})
  const [checked, setChecked] = useState(false)
  const initialValues = {
    emailDomain: '',
    customerName: '',
    terms: false,
  }

  const domainCheck = /^((?!-))(xn--)?[a-z0-9][a-z0-9-]{0,61}[a-z0-9]{0,}\.?((xn--)?([a-z0-9\-.]{1,61}|[a-z0-9-]{0,30})\.[a-z-1-9]{2,})$/;
  const mailCheck = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,25})$/;

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        const trimedEmail = data.emailDomain.trim().replaceAll(" ", "").toLowerCase();
        if(trimedEmail.length===0){
          setShowModal(prospectingReportErrorHandler({
            show: true,
            title: 'ERROR',
            message: 'Please insert a valid email.',
          }))
          return;
        }
        data.emailDomain = trimedEmail;
        if(checkToggle){
          if (mailCheck.test(trimedEmail)) {
            setIsLoading(true);
            const response = await getBreachesForEmailOrDomainService(data);
            if(response && response.data && response.data.data){
              const breachesResponse = response.data.data
              actions.resetForm()
              setIsLoading(false)
              breachesResponse?.breaches.map((preSearch)=>{
                if(preSearch?.password_plaintext ){
                  preSearch.decryptPass = decryptPassword(preSearch?.password_plaintext)
                if (preSearch.decryptPass) {
                  preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
                }  
                } else {
                  preSearch.decryptPass = preSearch?.password_plaintext
                  preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
                }
               })
  
              setBreaches(breachesResponse)
            }else{
              setIsLoading(false)
              setShowModal(prospectingReportErrorHandler({
                show: true,
                title: 'ERROR',
                message: response && response.response 
                && response.response.data && response.response.data.error && response.response.data.error.errorMessage 
                && response.response.data.error.errorMessage.toString().indexOf('Invalid parameter')>=0 ? 'Please insert a valid domain.'
                : response && response.response && response.response.data && response.response.data.error && response.response.data.error.errorType == "ForbiddenRequest"
                ? 'Public email domains are forbidden, please enter a valid email.': 
                response.response.status === 500 ? 'Internal Server Error. Something went wrong.' :
                response.response.data.message ? response.response.data.message : 'Internal Server Error. Something went wrong.'
              }))
            }
          } else {
            setShowModal(prospectingReportErrorHandler({
              show: true,
              title: 'ERROR',
              message: 'Please insert a valid email.',
            }))
          }
        } else if (domainCheck.test(trimedEmail)) {
          setIsLoading(true);
          const response = await getBreachesForEmailOrDomainService(data);
          if(response && response.data && response.data.data){
            const breachesResponse = response.data.data
            actions.resetForm()
            setIsLoading(false)

            breachesResponse?.breaches.map((preSearch)=>{
              if(preSearch?.password_plaintext){
                preSearch.decryptPass = decryptPassword(preSearch?.password_plaintext)
              if (preSearch.decryptPass) {
                preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
              }  
              } else {
                preSearch.decryptPass = preSearch?.password_plaintext
                preSearch.hashedPassword = hashPassword(preSearch?.decryptPass , preSearch?.password_type)
              }
             })


            setBreaches(breachesResponse)
          }else{
            setIsLoading(false)
            setShowModal(prospectingReportErrorHandler({
              show: true,
              title: 'ERROR',
              message: response && response.response 
                && response.response.data && response.response.data.error && response.response.data.error.errorMessage 
                && response.response.data.error.errorMessage.toString().indexOf('Invalid parameter')>=0 ? 'Please insert a valid domain.'
                : response && response.response && response.response.data && response.response.data.error && response.response.data.error.errorType == "ForbiddenRequest"
                ? 'Public email domains are forbidden, please enter a valid domain.': 
                response.response.status === 500 ? 'Internal Server Error. Something went wrong.' :
                response.response.data.message ? response.response.data.message : 'Internal Server Error. Something went wrong.'
            }))
          }
        }
        else {
          setShowModal(prospectingReportErrorHandler({
            show: true,
            title: 'ERROR',
            message: 'Please insert a valid domain.',
          }))
        }
      } catch (error) {
        setIsLoading(false)
        setShowModal(prospectingReportErrorHandler(error))
      }
    },
  })

  function handleCloseModal() {
    setShowModal({})
  }

  
  const handleOnSwitch = (e) =>{
    setCheckToggle(e);
  }

  const downloadPdfReports = async (data) => {
    try {
      setIsDownloading(true)
      const res = await postProspectingReportPDFService(data)
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Prospecting Report.pdf`) //or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      resolve()
      setIsDownloading(false)
    } catch ( error ) {
      setIsDownloading(false)
      console.log(error)
    }
  }
  
  return (
    <>
      <MspMenu>
        <ProspectingReportComponent
          {...{
            formik,
            isLoading,
            showModal,
            handleCloseModal,
            breaches,
            handleOnSwitch,
            checkToggle,
            setCheckToggle,
            downloadPdfReports,
            isDownloading,
            handleCheckboxClick,
            checked,
            setChecked
          }}
        />
      </MspMenu>
    </>
  )
}

export default ProspectingReportContainer
