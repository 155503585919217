import MdwMenu from '../../components/MdwMenu'
import AdminMSPsComponent from './AdminMSPsComponent.jsx'
import {  updateMspService, deleteMspService,getAllMSPsServiceAdmin } from '../../services/msp.service'
import { mspErrorHandler } from './errorHandler'
import { useState, useEffect } from 'react'

const AdminMSPsContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [MSPsData, setMSPsData] = useState({})

  async function getAllMSPsFromAPI() {
    try {
      const MSPs = await getAllMSPsServiceAdmin(true)
      setMSPsData({ ...MSPs.data.data })
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllMSPsFromAPI()
  }, [])

  async function handleChangeStatus(e) {
    try {
      const { id } = e.target
      const MSP = MSPsData.msps.find(MSP => MSP.mspId === id)
      MSP.status ? (MSP.status = false) : (MSP.status = true)
      await updateMspService(MSP, id)
      getAllMSPsFromAPI()
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  async function handleDelete(e) {
    try {
      const { id } = e.target
      const MSP = MSPsData.msps.find(msp => msp.mspId === id)
      if (window.confirm(`Are you sure you want to remove the MSP "${MSP.companyName}"? This action is irreversible`)) {
        await deleteMspService(id)
        getAllMSPsFromAPI()
      }
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminMSPsComponent
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          MSPs={MSPsData.msps}
          handleChangeStatus={handleChangeStatus}
          handleDelete={handleDelete}
        />
      </MdwMenu>
    </>
  )
}

export default AdminMSPsContainer
