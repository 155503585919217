import Axios from '../config/Axios'

export const getAllPendingSubscriptionsService = async () => {
  return await Axios.get('pending/subscription/all')
}

export const addPendingSubscriptionService = async (data) => {
  return await Axios.post('pending/subscription', data)
}

export const updatePendingSubscriptionService = async (id, data) => {
  return await Axios.put(`pending/subscription/${id}`, data)
}
