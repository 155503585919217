import axios from 'axios'
import publicIp from 'public-ip'
import { routes } from './constants'

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
})

Axios.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem('token')
    const twoFaToken = localStorage.getItem('2fa-token')
    token ? (config.headers['token'] = token) : delete config.headers['token']

    twoFaToken
      ? (config.headers['msp-access-token-2fa'] = twoFaToken)
      : delete config.headers['msp-access-token-2fa']

    //config.headers['x-forwarded-for'] = await publicIp.v4()
    return config
  },
  null,
  { synchronous: false }
)

Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.replace(routes.LOGIN_ROUTE)
    }
    return Promise.reject(error);
  }
)

export default Axios
