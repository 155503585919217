import './AdminDashboardContentStyles.scss'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import AdminBanner from '../../components/AdminBanner'
import { adminRoutes } from '../../config/constants'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import FormInput from '../../components/FormInput'
import FormButton from '../../components/FormButton'

const AdminDashboardContentComponent = ({
  showModal,
  handleCloseModal,
  formik,
  editorState,
  onEditorStateChange,
  dashboardContents,
  isLoading,
  handleClickChangeStatus,
  handleClickDelete,
}) => {
  return (
    <>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Modal
          showModal={showModal.show}
          handleCloseModal={handleCloseModal}
          title={showModal.title}
          message={showModal.message}
          redirect={showModal.redirect}
        />
        <AdminBanner title="DASHBOARD CONTENT" redirect={adminRoutes.ADMIN_ROUTE} />

        <div className="admin-dashboard-content-container">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <div>
              <Row>
                <Col xs={8}>
                  <FormInput formik={formik} id="title" height={50} label="Title" />
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    toolbarClassName="admin-add-news-editor-toolbar"
                    wrapperClassName="admin-add-news-editor-wrapper"
                    editorClassName="admin-add-news-editor-editor"
                    toolbar={{
                      options: [
                        'inline',
                        'fontSize',
                        'textAlign',
                        'colorPicker',
                        'link',
                        'emoji',
                        'image',
                        'remove',
                        'history',
                      ],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                      fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                    }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 29 }} className="text-right">
                <FormButton
                  type="submit"
                  label={isLoading ? 'LOADING...' : 'ADD DASHBOARD CONTENT'}
                  mdw
                />
              </div>
            </div>
          </Form>
          <Table
            style={{ marginTop: 10, width: '50%' }}
            className="admin-features-table"
            borderless
            hover
          >
            <thead>
              <tr>
                <th>TITLE</th>
                <th className="text-center">STATUS</th>
                <th>CREATED AT</th>
                <th className="text-center">DELETE</th>
              </tr>
            </thead>
            <tbody>
              {dashboardContents?.map(dashboardContent => (
                <tr key={dashboardContent.id}>
                  <td>{dashboardContent.title}</td>
                  <td className="text-center">
                    <Form.Group controlId="statusCheckbox">
                      <div className="form-check form-checkbox-container">
                        <input
                          onChange={() => handleClickChangeStatus(dashboardContent.id)}
                          checked={dashboardContent.status}
                          id="statusCheckbox"
                          type="checkbox"
                          className="form-check-input"
                          style={{ marginLeft: -15 }}
                        />
                      </div>
                    </Form.Group>
                  </td>
                  <td>{dashboardContent.createdAt.slice(0, 10)}</td>
                  <td className="text-center">
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClickDelete(dashboardContent.id)}
                    >
                      delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </motion.div>
    </>
  )
}

export default AdminDashboardContentComponent
