import './AdminPackageConfirmation.scss'
import FormButton from '../FormButton'

const AdminPackageConfirmation = ({ show, onClose }) => {
  if (!show) {
    return null
  }

  return (
    <div className="admin-package-confirmation">
      <div className="admin-package-confirmation-content">
        <div className="admin-package-confirmation-header">
          <h1>Package has been added/Updates</h1>
        </div>
        <p>
          The package &lt;&lt;packagesku&gt;&gt; has been added / updated and can now be assigned to
          an MSP.
        </p>
        <div className="text-center">
          <FormButton onClick={onClose} label="Ok" height={50} width={90} mdw />
        </div>
      </div>
    </div>
  )
}

export default AdminPackageConfirmation
