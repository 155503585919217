import './AdminNewsStyles.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'
import Modal from '../../components/Modal'
import AdminNewsArticle from '../../components/AdminNewsArticle'
import AdminBanner from '../../components/AdminBanner'
import PlusIcon from '../../assets/icons/plusBlack.svg'
import Cross from '../../assets/icons/cross'

const NewsComponent = ({
  news,
  newsCategories,
  showModal,
  handleCloseModal,
  showNewsArticle,
  handleNewsArticleClick,
  handleOnCloseNewsArticle,
  handleFilterClick,
  handleNewsArticleDelete,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <AdminNewsArticle
        show={showNewsArticle.show}
        onClose={handleOnCloseNewsArticle}
        news={showNewsArticle.news}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <AdminBanner title="NEWS" />
        <div className="admin-news-container">
          <div className="admin-news-close-button-container">
            <h3 className="admin-news-filters">
              {newsCategories.length > 0 && (
                <span id="allCategories" onClick={handleFilterClick}>
                  All
                </span>
              )}
              {newsCategories.map(newsCategory => (
                <span key={newsCategory.id} id={newsCategory.id} onClick={handleFilterClick}>
                  {' | ' + newsCategory.name}
                </span>
              ))}
            </h3>
            <Link to={adminRoutes.ADD_NEWS_ROUTE}>
              <div className="admin-news-add-button">
                ADD NEWS
                <img src={PlusIcon} width={18} height={18} alt="Add icon" />
              </div>
            </Link>
          </div>
          <div className="admin-news-green-bar">NEWS</div>
          <div className="admin-news-news-container">
            {news.map(oneNews => (
              <div key={oneNews.id} className="admin-news-item-article">
                <div className="admin-news-item-article-header">
                  <h5 id={oneNews.id} onClick={handleNewsArticleClick}>
                    {oneNews.title}
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        handleNewsArticleDelete(oneNews.id)
                      }}
                      style={{ cursor: 'pointer', display: 'inline-block' }}
                    >
                      <Cross />
                    </div>
                  </h5>
                </div>
                <h3>{oneNews.createdAt.slice(0, -14)}</h3>
                <div dangerouslySetInnerHTML={{ __html: oneNews.description }}></div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default NewsComponent
