import './FirstBreachesStyles.scss'
import { motion } from 'framer-motion'
import { Table, Button } from 'react-bootstrap'
import Banner from '../../components/Banner'
import FormInput from '../../components/FormInputWithoutFormik'
import FormButton from '../../components/FormButton'
import { Link } from 'react-router-dom'
import { routes } from '../../config/constants'
import Modal from '../../components/Modal'
import { Form } from 'react-bootstrap'
import FormCheckbox from '../../components/FormCheckbox'
import { CSVLink } from "react-csv";
import FormSwitch from '../../components/FormSwitchWithoutFormik'
import PaginationComponent from '../../components/Pagination'

const FirstBreachesComponent = ({
  allBreaches,
  CustomerId,
  customer,
  values,
  handleChange,
  handleClickSearch,
  handleSendAlert,
  showModal,
  handleCloseModal,
  handleArchiveABreach,
  handleClickResetSearch,
  loading,
  mspCompanyName,
  handleArchive,
  handleAllStatusChange,
  handleStatusChange,
  isCheck,
  isCheckAll,
  archiveLoading,
  csvData,
  headerCSV,
  handleCsvDownload,
  handleShowCsvModal,
  handleCheckboxClick,
  checked,
  setChecked,
  currentPage,
  itemsPerPage,
  totalData,
  handlePageChange,
  handleItemsPerPageChange,
}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
      />
      <Banner
        redirect={CustomerId ? routes.CUSTOMERS_ROUTE + '/' + customer.customerId : '/account'}
        title={`INITIAL BREACH SCAN FOR ${CustomerId ? customer?.companyName?.toUpperCase() || '...' : mspCompanyName.toUpperCase()
          }`}
      />
      {loading ?
        <div style={{
          height: 'calc(100vh - 260px)'
        }}>
          <div className='main-loader'>
            <div className="loader"></div>
          </div>
        </div> :
        <div className="breaches-container">
          {customer.breaches?.length > 0 && !loading ? (
            <>
              <div className="breaches-header-container" style={{ position: "relative" }}>
                <FormInput
                  label="Domain Search"
                  id="domain"
                  value={values}
                  onChange={handleChange}
                  color="primary"
                  style={{ display: 'inline-block' }}
                  width={260}
                />
                <FormInput
                  label="Email Search"
                  id="email"
                  value={values}
                  onChange={handleChange}
                  color="primary"
                  style={{ display: 'inline-block' }}
                  width={260}
                />
                <FormButton
                  onClick={handleClickSearch}
                  style={{ marginTop: 8 }}
                  width={100}
                  label="SEARCH"
                />
                <FormButton
                  className={'archiveBox'}
                  onClick={handleArchive}
                  style={{ marginTop: 8, marginRight: '-125px' }}
                  width={150}
                  label={archiveLoading ? "LOADING..." : "ARCHIVE SELECTED"}
                />
                {csvData && csvData.length > 0 && isCheck.length > 0 ? (
                  <CSVLink className='csvBtn'
                    style={{ position: "absolute", right: "-276px", background: "#0ddb95", color: "white", marginTop: "8px", fontWeight: 700, height: "29px", borderRadius: "4px", width: "135px", padding: "2.5px 6px 0", fontSize: "14.5px" }}
                    onClick={handleCsvDownload} data={csvData[0]} headers={headerCSV} filename={(customer?.companyName) ? `${customer.companyName} -Initial Breach Report.csv` : "Initial Breach Report.csv"}>DOWNLOAD CSV
                  </CSVLink>
                ) : <button onClick={handleShowCsvModal}
                  style={{ position: "absolute", right: "-276px", background: "#0ddb95", color: "white", marginTop: "8px", fontWeight: 700, height: "29px", borderRadius: "4px", width: "135px", padding: "2.5px 6px 0", fontSize: "14.5px", border: "none" }}>
                  DOWNLOAD CSV
                </button>}
              </div>
              <div style={{ overflowY: 'auto', height: '570px' }} >
                <Table className="customers-table" striped hover borderless>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <FormCheckbox
                          id={'test_all'}
                          checked={isCheckAll}
                          onChange={handleAllStatusChange}
                        />
                      </th>
                      <th>Email Address</th>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                          <label style={{ marginBottom: 0, marginRight: '6px', fontSize: "14px" }}>
                            Password
                          </label>
                        }
                        <FormSwitch
                          label=""
                          value={checked}
                          errors={'errors'}
                          onChange={e => handleCheckboxClick(e, setChecked)}
                          id="revealPass"
                          color="primary"
                          mutedText=""
                          style={{ marginBottom: '5px', marginTop: '10px', marginRight: '20px' }}
                          password={true}
                        />
                      </div>
                      {/* <th>
                        <div className="switch">
                        <input id="switch__input" type="checkbox" checked={checked} onClick={(e)=> handleCheckboxClick(e,setChecked)}/>
                        <label htmlFor="switch__input">
                         Password<span></span>
                        </label>
                        </div>
                        </th> */}
                      {/* <th className="text-center">Decrypted Password</th> */}
                      <th>Breach Source</th>
                      <th>Breach Date</th>
                      <th>Publish Date</th>
                      <th className="text-center">Severity</th>
                      <th className="text-center">Alert Sent</th>
                      <th>Create Ticket</th>
                      <th className="text-center">Archive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer.breaches?.map(breach => (
                      <tr key={breach.id}>
                        <td className="text-center">
                          <FormCheckbox
                            id={breach.id}
                            checked={isCheck.includes(breach.id)}
                            onChange={handleStatusChange}
                          />
                        </td>
                        <td>{breach.email}</td>
                        <td>
                          {breach.decryptPass && breach.password_plaintext && checked
                            ? breach.decryptPass
                            : breach.hashedPassword
                          }
                        </td>
                        {/* <td className="text-center">{breach.password_plaintext ? 'yes' : 'no'}</td> */}
                        <td>
                          <Link
                            className="breaches-table-link"
                            to={`/breaches/${breach.breach?.mspbcid}/details`}
                          >
                            {breach.breach?.title || 'unknown'}
                          </Link>
                        </td>
                        <td>{breach.breach?.breach_date.slice(0, 10) || 'unknown'}</td>
                        <td>{breach.spycloud_publish_date.slice(0, 10)}</td>
                        <td className="text-center">{breach.severity}</td>
                        <td className="text-center">{breach.alert_sent?.slice(0, 10)}</td>
                        <td>
                          <span
                            onClick={() => {
                              handleSendAlert(breach.id)
                            }}
                            className="breaches-table-link"
                          >
                            create ticket
                          </span>
                        </td>
                        <td className="text-center">
                          <Form.Group controlId={`archive-${breach.id}`} style={{ marginBottom: 0 }}>
                            <Form.Check
                              className="form-switch"
                              type="switch"
                              onChange={() => handleArchiveABreach(breach.id)}
                              checked={breach.archive}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <PaginationComponent currentPage={currentPage} totalPages={Math.ceil(totalData / itemsPerPage)}
                onPageChange={handlePageChange} itemsPerPage={itemsPerPage} onItemsPerPageChange={handleItemsPerPageChange} />
            </>
          ) : (
            <>
              {allBreaches.length > 0 && !loading ? (
                <p>
                  There were no results for your search -{' '}
                  <b style={{ cursor: 'pointer' }} onClick={handleClickResetSearch}>
                    Click to go back
                  </b>
                </p>
              ) : (
                <>
                  {
                    !loading && (
                      <p>
                        Congratulations! You currently have a clean bill of health after your initial
                        Dark Web Scan.
                      </p>
                    )
                  }

                </>
              )}
            </>
          )}
        </div>
      }

    </motion.div>
  )
}

export default FirstBreachesComponent