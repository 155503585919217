import { useEffect, useState } from 'react'
import MdwMenu from '../../components/MdwMenu'
import AdminAdminComponent from './AdminAdminComponent'
import { adminErrorHandler } from './errorHandler'
import {
  getAllPaymentMethodsService,
  addPaymentMethodService,
  deletePaymentMethodService,
} from '../../services/paymentMethod.service'
import { useFormik } from 'formik'
import { schema } from '../../schemas/paymentMethod.schema'

export default function () {
  const [showModal, setShowModal] = useState({})
  const [showPaymentMethods, setShowPaymentMethods] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])

  const initialValues = {
    name: '',
  }

  async function getAllPaymentMethodsFromAPI() {
    try {
      const response = await getAllPaymentMethodsService()
      setPaymentMethods(response.data.data)
    } catch (error) {
      setShowModal(adminErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllPaymentMethodsFromAPI()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        await addPaymentMethodService(data)
        actions.resetForm()
        getAllPaymentMethodsFromAPI()
      } catch (error) {
        setShowModal(adminErrorHandler(error))
      }
    },
  })

  async function handleDeletePaymentMethod (id) {
    try {
      await deletePaymentMethodService(id)
      getAllPaymentMethodsFromAPI()
    } catch (error) {
      setShowModal(adminErrorHandler(error))
    }
  }

  function handleClickPaymentMethods() {
    setShowPaymentMethods(true)
  }

  function handleClosePaymentMethods() {
    setShowPaymentMethods(false)
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <MdwMenu>
      <AdminAdminComponent
        {...{
          formik,
          paymentMethods,
          showModal,
          showPaymentMethods,
          handleCloseModal,
          handleDeletePaymentMethod,
          handleClickPaymentMethods,
          handleClosePaymentMethods,
        }}
      />
    </MdwMenu>
  )
}
