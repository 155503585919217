import '../../assets/css/fonts.css'
import { Form, Row, Col } from 'react-bootstrap'
import FormButton from '../../components/FormButton'
import Modal from '../../components/Modal'

const ForgotPassword = ({ formik, isLoading, handleCloseModal, showModal }) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />

      <div className="TwoFact__container" style={{ marginTop: 120 }}>
        <Form noValidate onSubmit={formik.handleSubmit} className="form-col border">
          <Row style={{ margin: 'auto' }}>
            <Col>
              <Form.Group controlId="emailAddress" style={{ position: 'relative' }}>
                <Form.Control
                  type="text"
                  placeholder="EMAIL ADDRESS"
                  value={formik.values.emailAddress}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.emailAddress}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.emailAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="TwoFact__submit row">
                <div className="twodact__btn__link">
                  <FormButton width={150} type="submit" label={!isLoading ? 'SEND' : 'LOADING...'} mdw/>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default ForgotPassword
