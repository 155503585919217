import './AdminAddPaymentMethods.scss'
import FormInput from '../FormInput'
import TrashIcon from '../../assets/icons/trashDelete.svg'
import CrossIcon from '../../assets/icons/crossWhite.svg'
import PlusIcon from '../../assets/icons/plusBlack.svg'
import { Form } from 'react-bootstrap'

export default function AdminAddPaymentMethods({
  show,
  onClose,
  paymentMethods,
  formik,
  id,
  handleDelete,
}) {
  if (!show) {
    return null
  }

  return (
    <div className="admin-add-payment-methods">
      <div className="admin-add-payment-methods-content">
        <div className="admin-banner-container" style={{ borderRadius: '5px 5px 0 0' }}>
          <h1>PAYMENT METHODS</h1>
          <img
            onClick={onClose}
            src={CrossIcon}
            alt="Cross icon to close the modal"
            width={25}
            height={25}
          />
        </div>
        <div className="admin-add-payment-methods-body">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <div>
              <Form.Group controlId={id} style={{ position: 'relative', width: '50%' }}>
                <Form.Label className="form-input-label">Payment Method</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input-tertiary-color form-input-control"
                  style={{
                    height: 40,
                    display: 'inline-block',
                  }}
                  value={formik?.values[id]}
                  onChange={formik?.handleChange}
                  isInvalid={!!formik?.errors[id]}
                  autoComplete="nope"
                />
                <button
                  style={{ border: 'none', background: 'none', padding: 0, position: 'absolute' }}
                  type="submit"
                >
                  <img style={{ marginLeft: 10, marginTop: 5 }} src={PlusIcon} />
                </button>
                <Form.Control.Feedback type="invalid">{formik?.errors[id]}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Form>
          <div className="admin-add-payment-methods-list-container">
            <h3>Payment Method</h3>
            {paymentMethods?.map(paymentMethod => (
              <div key={paymentMethod.id} className="admin-add-payment-methods-items-container">
                <p>{paymentMethod.name}</p>
                <img
                  style={{ cursor: 'pointer' }}
                  src={TrashIcon}
                  width={20}
                  height={20}
                  alt="Delete icon"
                  onClick={() => handleDelete(paymentMethod.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
