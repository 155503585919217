import * as Yup from 'yup'

export const schema = Yup.object({
  packageSku: Yup.string()
    .min(5, 'SKU must be at least 5 chars long')
    .max(20, 'SKU must have a maximum of 20 characters')
    .required('SKU is required'),
  packageName: Yup.string()
    .min(5, 'Package name must be at least 5 chars long')
    .max(20, 'Package name must have a maximum of 20 characters')
    .required('Package name is required'),
  packageDescription: Yup.string().max(
    70,
    'Package description must have a maximum of 70 characters'
  ),
  packageDomains: Yup.number()
    .typeError('Package domains must be a number value')
    .required('Package domains is required'),
  packageEmails: Yup.number()
    .typeError('Package emails must be a number value')
    .required('Package emails is required'),
})
