import { Route, Redirect } from 'react-router-dom'
import { adminRoutes, routes } from '../../config/constants'

export const PrivateRoute = ({ roles, ...rest }) => {
  function isAuth(roles) {
    const role = localStorage.getItem('role')
    const token = localStorage.getItem('token')
    const token2fa = localStorage.getItem('2fa-token')
    if (!token || !token2fa) return false
    return roles.includes(role)
  }

  return isAuth(roles) ? <Route {...rest} /> : <Redirect to={routes.LOGIN_ROUTE} />
}

export const LoginRoute = ({ ...rest }) => {
  let redirectUrl = ''

  function isLogged() {
    const role = localStorage.getItem('role')
    const token = localStorage.getItem('token')
    const token2fa = localStorage.getItem('2fa-token')

    if (!role) redirectUrl = routes.LOGIN_ROUTE
    if (role === 'MSP' || role === 'MSP_USER') redirectUrl = routes.DASHBOARD_ROUTE
    if (role === 'MDW') redirectUrl = adminRoutes.DASHBOARD_ROUTE

    return !!(role && token && token2fa)
  }

  return isLogged() ? <Redirect to={redirectUrl} /> : <Route {...rest} />
}

export const Login2FaRoute = ({ ...rest }) => {
  let redirectUrl = ''

  function isLogged() {
    const role = localStorage.getItem('role')
    const token = localStorage.getItem('token')
    const token2fa = localStorage.getItem('2fa-token')

    if (!token) {
      redirectUrl = routes.LOGIN_ROUTE
      return true
    }

    if (!role) redirectUrl = routes.LOGIN_ROUTE
    if (role === 'MSP' || role === 'MSP_USER') redirectUrl = routes.DASHBOARD_ROUTE
    if (role === 'MDW') redirectUrl = adminRoutes.DASHBOARD_ROUTE

    return !!(role && token && token2fa)
  }

  return isLogged() ? <Redirect to={redirectUrl} /> : <Route {...rest} />
}
