import MdwMenu from '../../components/MdwMenu'
import AdminPendingMSPsComponent from './AdminPendingMSPsComponent.jsx'
import { useState, useEffect } from 'react'
import { pendingMSPsErrorHandler } from './errorHandler'
import {
  getAllPendingMSPsService,
  updatePendingMSPsService,
} from '../../services/pendingMSPs.service'
import { getAndSaveContactsFromEngagebayService } from '../../services/engagebay.service'

const AdminPendingMSPsContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [allMSPs, setAllMSPs] = useState([])
  const [pendingMSPs, setPendingMSPs] = useState([])
  const [processedMSPs, setProcessedMSPs] = useState([])
  const [loading, setLoading] = useState(false)

  async function getAllPendingMSPsFromAPI() {
    try {
      const response = await getAllPendingMSPsService()
      const PendingMSPs = response.data.data.sort((a, b) => {
        if (a.msp.companyName < b.msp.companyName) {
          return 1;
        }
        if (a.msp.companyName > b.msp.companyName) {
          return -1;
        }

        return 0;
      })

      setAllMSPs(PendingMSPs)
      setPendingMSPs([])
      setProcessedMSPs([])
      PendingMSPs.forEach(pendingMSP => {
        if (pendingMSP.welcomeEmail && pendingMSP.followUp && pendingMSP.completed) {
          setProcessedMSPs(prevState => [pendingMSP, ...prevState])
        } else {
          setPendingMSPs(prevState => [pendingMSP, ...prevState])
        }
      })
    } catch (error) {
      setShowModal(pendingMSPsErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllPendingMSPsFromAPI()
  }, [])

  async function handleOnChangeWelcomeEmail(e) {
    const { id } = e.target
    const pendingMSP = allMSPs.find(pendingMSP => pendingMSP.id === id)
    await updatePendingMSPsService(id, { welcomeEmail: !pendingMSP.welcomeEmail })
    getAllPendingMSPsFromAPI()
  }

  async function handleOnChangeFollowUp(e) {
    const { id } = e.target
    const pendingMSP = allMSPs.find(pendingMSP => pendingMSP.id === id)
    await updatePendingMSPsService(id, { followUp: !pendingMSP.followUp })
    getAllPendingMSPsFromAPI()
  }

  async function handleOnChangeCompleted(e) {
    const { id } = e.target
    const pendingMSP = allMSPs.find(pendingMSP => pendingMSP.id === id)
    await updatePendingMSPsService(id, { completed: !pendingMSP.completed })
    getAllPendingMSPsFromAPI()
  }

  async function handleClickEngage() {
    try {
      setLoading(true)
      await getAndSaveContactsFromEngagebayService()
      setTimeout(getAllPendingMSPsFromAPI, 1000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowModal(pendingMSPsErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminPendingMSPsComponent
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleOnChangeWelcomeEmail={handleOnChangeWelcomeEmail}
          handleOnChangeFollowUp={handleOnChangeFollowUp}
          handleOnChangeCompleted={handleOnChangeCompleted}
          pendingMSPs={pendingMSPs}
          processedMSPs={processedMSPs}
          handleClickEngage={handleClickEngage}
          loading={loading}
        />
      </MdwMenu>
    </>
  )
}

export default AdminPendingMSPsContainer
