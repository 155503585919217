import MspMenu from '../../components/MspMenu'
import NewsComponent from './NewsComponent.jsx'
import { useState, useEffect } from 'react'
import { getAllNewsCategoriesService } from '../../services/newsCategory.service'
import { getAllNewsService } from '../../services/news.service'
import { newsErrorHandler } from './errorHandler'

const NewsContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [showNewsArticle, setShowNewsArticle] = useState({})
  const [allNews, setAllNews] = useState([])
  const [news, setNews] = useState([])
  const [newsCategories, setNewsCategories] = useState([])

  async function getAllNewsFromAPI() {
    try {
      const News = await getAllNewsService()
      setNews(News.data.data)
      setAllNews(News.data.data)
    } catch (error) {
      setShowModal(newsErrorHandler(error))
    }
  }

  async function getAllNewsCategoriesFromAPI() {
    try {
      const NewsCategories = await getAllNewsCategoriesService()
      setNewsCategories(NewsCategories.data.data)
    } catch (error) {
      setShowModal(newsErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllNewsFromAPI()
    getAllNewsCategoriesFromAPI()
  }, [])

  function handleFilterClick(e) {
    const { id } = e.target
    const categoryId = id
    if (id === 'allCategories') {
      setNews(allNews)
    } else {
      setNews(allNews.filter(news => news.newsCategoryId === categoryId))
    }
  }

  function handleNewsArticleClick(e) {
    const { id } = e.target
    const clickedNews = news.find(oneNews => oneNews.id === id)
    setShowNewsArticle({ show: true, news: clickedNews })
  }

  function handleOnCloseNewsArticle() {
    setShowNewsArticle({})
  }

  function handleOnCloseModal() {
    setShowNewsArticle({})
  }

  return (
    <>
      <MspMenu>
        <NewsComponent
          news={news}
          newsCategories={newsCategories}
          showModal={showModal}
          handleCloseModal={handleOnCloseModal}
          handleFilterClick={handleFilterClick}
          showNewsArticle={showNewsArticle}
          handleNewsArticleClick={handleNewsArticleClick}
          handleOnCloseNewsArticle={handleOnCloseNewsArticle}
        />
      </MspMenu>
    </>
  )
}

export default NewsContainer
