import React from 'react'
import '../AdminUsersStyles.scss'
import MdwMenu from '../../../components/MdwMenu'
import AdminUsersContainer from '../../../pages/AdminUsers/AdminUserDetails/AdminUsersContainer';
import MspUsersContainer from '../../../pages/AdminUsers/MspUsers/MspUsersContainer'


const index = ( { match } ) => {
	console.log('Index_match', match)
  return (
    <>
      <MdwMenu>
        <AdminUsersContainer match={match} />
        <MspUsersContainer match={match} />
      </MdwMenu>
    </>
  )
}

export default index