import '../AdminUsersStyles.scss'
import { Table, Form } from 'react-bootstrap'
import { motion } from 'framer-motion'
import AdminBanner from '../../../components/AdminBanner'
import FormInput from '../../../components/FormInput'
import FormButton from '../../../components/FormButton'
import FormCheckbox from '../../../components/FormCheckbox'
import Modal from '../../../components/Modal'
import { routes } from '../../../config/constants'
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal'

const UsersComponent = ({
  formik,
  isLoading,
  showModal,
  handleCloseModal,
  users,
  UserId,
  handleStatusChange,
  handleClickUser,
  account,
  userType,
  show,
  handleShow,
  handleClose
} ) => {
  
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <ConfirmModal
        showModal={show.showModal}
        title={show.title}
        message={show.message}
        handleCloseCancelModal={handleClose}
        handleOk={show.handleOk}
        id={show.id}
      />
      <AdminBanner title="USERS" redirect={UserId && routes.USERS_ROUTE} />
      <div className="admin-users-container">
        <h3 className="admin-users-subtitle">MSP DARK WEB ADMIN USERS</h3>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="admin-users-inputs-container">
            <FormInput
              id="firstName"
              formik={formik}
              className="admin-users-input"
              label="First Name"
              color="tertiary"
              width={275}
            />
            <FormInput
              id="lastName"
              formik={formik}
              className="admin-users-input"
              label="Last Name"
              color="tertiary"
              width={275}
            />
            <FormInput
              id="emailAddress"
              formik={formik}
              className="admin-users-input"
              label="Email Address"
              color="tertiary"
              width={275}
            />
            <FormButton
              type="submit"
              label={isLoading ? 'LOADING...' : UserId ? 'SAVE' : 'ADD USER'}
              width={100}
              mdw
            />
          </div>
        </Form>
        <Table className="admin-users-table" hover striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email Address</th>
              <th className="text-center">2FA</th>
              <th className="text-center">Password</th>
              <th className="text-center">Active</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map(user => (
                <tr key={user.userId}>
                  <td>
                    <span
                      id={user.userId}
                      onClick={handleClickUser}
                      style={{ cursor: 'pointer' }}
                    >{`${user.firstName} ${user.lastName}`}</span>
                  </td>
                  <td>{user.username}</td>
                  <td>{user.emailAddress}</td>
                  <td className="text-center admin-users-table-reset">
                    <span id={user.userId} onClick={() => handleShow(user.userId, 'reset2FA')}>
                      reset
                    </span>
                  </td>
                  <td className="text-center admin-users-table-reset">
                    <span id={user.userId} onClick={() => handleShow(user.userId, 'resetPassword')}>
                      reset
                    </span>
                  </td>
                  <td className="text-center">
                    <FormCheckbox
                      id={user.userId}
                      checked={user.status}
                      onChange={handleStatusChange}
                    />
                  </td>
                  <td className="text-center admin-users-table-delete">
                    {account.username !== user.username && (
                      <span id={user.userId} onClick={() => handleShow(user.userId, 'deleteUser')}>
                        delete
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </motion.div>
  )
}

export default UsersComponent
