const MarketingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 15L2 10L3.41 8.58L7 12.17L14.59 4.58L16 6L7 15ZM16 0H2C0.89 0 0 0.89 0 2V14.93C0 15.62 0.35 16.23 0.88 16.59L9 22L17.11 16.59C17.64 16.23 18 15.62 18 14.93V2C18 0.89 17.1 0 16 0Z"
        fill="black"
      />
    </svg>
  )
}

export default MarketingIcon
