import './AdminBreachCatalogueStyles.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import AdminPublicDataSources from '../../components/AdminPublicDataSources'
import AdminPrivateDataSources from '../../components/AdminPrivateDataSources'
import AdminBanner from '../../components/AdminBanner'
import IconWithInfo from '../../components/IconWithInfo'
import EmailsIcon from '../../assets/icons/emails.svg'
import KeyIcon from '../../assets/icons/key.svg'
import NodoIcon from '../../assets/icons/nodo.svg'
import IdIcon from '../../assets/icons/id.svg'
import PersonInBoxIcon from '../../assets/icons/personInBox.svg'
import WorldIcon from '../../assets/icons/world.svg'
import PhoneIcon from '../../assets/icons/phone.svg'
import FinancialIcon from '../../assets/icons/financial.svg'
import PadlockIcon from '../../assets/icons/padlock.svg'
import PublicDataIcon from '../../assets/icons/publicData.svg'
import { dateToSemiTextFormat } from '../../utilities/transformDates'
import { numberWithCommas } from '../../utilities/transformNumbers'

const AdminBreachCatalogueComponent = ({
  breachCatalogue,
  assetStats,
  handleClickLoadMore,
  handleClickFilter,
}) => {
  return (
    <>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <AdminBanner title="BREACH CATALOGUE" />
        <div className="admin-breach-catalogue-container-container">
          <div className="admin-breach-catalogue-icons-header">
            <IconWithInfo
              text={numberWithCommas(assetStats.email)}
              bottomText={'Emails'}
              textSize={14}
              bottomTextSize={16}
              icon={EmailsIcon}
              filter="invert(0%) sepia(0%) saturate(7500%) hue-rotate(246deg) brightness(0%) contrast(100%)"
              width={50}
              height={50}
              color="tertiary"
              style={{
                marginRight: 5,
              }}
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.password)}
              bottomText={'Passwords'}
              textSize={14}
              bottomTextSize={16}
              icon={KeyIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.ipAddresses)}
              bottomText={'IP Addresses'}
              textSize={14}
              bottomTextSize={16}
              icon={NodoIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.username)}
              bottomText={'Usernames'}
              textSize={14}
              bottomTextSize={16}
              icon={IdIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.pii)}
              bottomText={'PII'}
              textSize={14}
              bottomTextSize={16}
              icon={PersonInBoxIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.geolocation)}
              bottomText={'Geographic Location'}
              textSize={14}
              bottomTextSize={16}
              icon={WorldIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.phone)}
              bottomText={'Phone Numbers'}
              textSize={14}
              bottomTextSize={16}
              icon={PhoneIcon}
              width={50}
              height={50}
              color="tertiary"
            />
            <IconWithInfo
              text={numberWithCommas(assetStats.financialInformation)}
              bottomText={'Financial Information'}
              textSize={14}
              bottomTextSize={16}
              icon={FinancialIcon}
              width={50}
              color="tertiary"
              height={50}
            />
          </div>
          <div className="admin-breach-catalogue-tab-container">
            <h3 className="admin-breach-catalogue-tab-buttons">
              <span onClick={() => handleClickFilter('all')}>VIEW ALL</span> |{' '}
              <span onClick={() => handleClickFilter('private')}>PRIVATE SOURCES</span> |{' '}
              <span onClick={() => handleClickFilter('public')}>PUBLIC SOURCES</span>
            </h3>
          </div>
          <div className="admin-breach-catalogue-container">
            {breachCatalogue.map(breach => (
              <div key={breach.mspbcid} className="admin-breach-catalogue-container-item">
                <Link to={`/admin/breaches/${breach.mspbcid}/details`}>
                  <h4>{breach.title}</h4>
                </Link>
                <h5>{breach.site ? breach.site : 'n/a'}</h5>
                <div className="admin-breach-catalogue-container-item-flex-div">
                  <p style={{ width: '100%' }}>
                    {breach.description}
                    <br />
                  </p>
                  <div style={{ marginLeft: 55, marginRight: 55 }}>
                    <h3>{numberWithCommas(breach.num_records)}</h3>
                    <p>Number of Records</p>
                  </div>
                  {breach.type === 'PRIVATE' ? (
                    <AdminPrivateDataSources>
                      <div>
                        <img src={PadlockIcon} alt="Private Data Icon" width={50} height={50} />
                        <p>Private Data</p>
                      </div>
                    </AdminPrivateDataSources>
                  ) : (
                    <AdminPublicDataSources>
                      <div>
                        <img src={PublicDataIcon} alt="Public Data Icon" width={50} height={50} />
                        <p>Public Data</p>
                      </div>
                    </AdminPublicDataSources>
                  )}
                </div>
                <p className="admin-breach-catalogue-container-item-date">
                  Published: {dateToSemiTextFormat(breach.spycloud_publish_date)}
                </p>
              </div>
            ))}
            <div className="admin-breach-catalogue-load-more-button" onClick={handleClickLoadMore}>
              LOAD MORE BREACHES
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AdminBreachCatalogueComponent
