import './FormButton.scss'

const FormButton = ({
  label,
  outline,
  style,
  width,
  className,
  mdw,
  height = 30,
  onClick,
  type,
  form,
  isDisabled
}) => {
  return (
    <button
      form={form}
      type={type}
      onClick={onClick}
      disabled={isDisabled === true ? isDisabled : false}
      style={{ width, ...style, height, fontSize: 14 }}
      className={
        (mdw
          ? outline
            ? 'form-mdw-button-outline '
            : 'form-mdw-button '
          : outline
          ? 'form-button-outline '
          : 'form-button ') + className
      }
    >
      {label}
    </button>
  )
}

export default FormButton
