import MdwMenu from '../../components/MdwMenu'
import AdminReportsComponent from './AdminReportsComponent.jsx'
import { useState, useEffect } from 'react'
import {
  getSpycloudReportService,
  getUtilizationReportService,
} from '../../services/report.service'

const AdminReportsContainer = () => {
  const [utilizationReport, setUtilizationReport] = useState(null)
  const [spycloudReport, setSpycloudReport] = useState(null)

  async function getUtilizationReportFromAPI() {
    try {
      const response = await getUtilizationReportService()
      const blob = new Blob([response.data.data], { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      setUtilizationReport(url)
    } catch (error) {
      console.log(error)
    }
  }

  async function getSpycloudReportFromAPI() {
    try {
      const response = await getSpycloudReportService()
      const blob = new Blob([response.data.data], { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      setSpycloudReport(url)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUtilizationReportFromAPI()
    getSpycloudReportFromAPI()
  }, [])

  return (
    <>
      <MdwMenu>
        <AdminReportsComponent {...{ utilizationReport, spycloudReport }} />
      </MdwMenu>
    </>
  )
}

export default AdminReportsContainer
