import './AdminUploadTrainingMaterial.scss'
import { Row, Col, Form } from 'react-bootstrap'
import CrossIcon from '../../assets/icons/crossWhite.svg'
import FormButton from '../FormButton'

const AdminUploadMarketingMaterial = ({
  show,
  onClose,
  values,
  onChangeInputs,
  onChangeFile,
  onSubmit,
  video,
  image,
  isLoading,
}) => {
  if (!show) {
    return null
  }

  return (
    <div className="admin-upload-training">
      <div className="admin-upload-training-content">
        <div className="admin-banner-container" style={{ borderRadius: '5px 5px 0 0' }}>
          <h1>UPLOAD TRAINING MATERIAL</h1>
          <img
            onClick={onClose}
            src={CrossIcon}
            alt="Cross icon to close the modal"
            width={25}
            height={25}
          />
        </div>
        <div className="admin-upload-training-body">
          <form onSubmit={onSubmit}>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Label className="form-input-label">Name</Form.Label>
              <Form.Control
                value={values.name}
                className="form-input-tertiary-color form-input-control"
                onChange={e => onChangeInputs(e, 'name')}
              />
            </Form.Group>
            <Row>
              <Col xs={10}>
                <Form.Group>
                  <Form.Label className="form-input-label">Description</Form.Label>
                  <Form.Control
                    value={values.description}
                    as="textarea"
                    rows={4}
                    style={{ resize: 'none' }}
                    className="form-input-tertiary-color form-input-control"
                    onChange={e => onChangeInputs(e, 'description')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{ marginBottom: 0 }}>
                  <Form.Label className="form-input-label">Order</Form.Label>
                  <Form.Control
                    value={values.order}
                    type="number"
                    className="form-input-tertiary-color form-input-control"
                    onChange={e => onChangeInputs(e, 'order')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {video ? (
                  <video controls className="admin-upload-training-video" src={video}>
                    Tu navegador no admite el elemento <code>video</code>.
                  </video>
                ) : (
                  <label className="admin-upload-training-input-file">
                    <input
                      accept="video/mp4, video/webm, video/ogg"
                      onChange={e => {
                        onChangeFile(e, 'video')
                      }}
                      type="file"
                      id="marketingMaterialImage"
                    />
                    BROWSE VIDEO
                  </label>
                )}
              </Col>
              <Col>
                {image ? (
                  <img style={{ width: '100%' }} src={image} />
                ) : (
                  <label className="admin-upload-training-input-file">
                    <input
                      accept="image/*"
                      onChange={e => {
                        onChangeFile(e, 'image')
                      }}
                      type="file"
                      id="marketingMaterialImage"
                    />
                    BROWSE IMAGE
                  </label>
                )}
              </Col>
            </Row>
            <div className="text-right">
              <FormButton
                style={{ position: 'absolute', bottom: 20, right: 20 }}
                width={100}
                type="submit"
                label={isLoading ? 'LOADING...' : 'SAVE'}
                mdw
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminUploadMarketingMaterial
