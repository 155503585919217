import AxiosPdf from '../config/pdfAxios'

export const postProspectingReportPDFService = async data => {
  try{
    const response = await AxiosPdf.post(`/pdf/prospectingreportpdf`, data)
    return response;
  }catch(error){
    return error;
  }
}
