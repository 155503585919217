import './FormTextarea.scss'
import { Form } from 'react-bootstrap'

const FormTextarea = ({
  label,
  rows,
  id,
  formik,
  readOnly,
  placeholder,
  style,
  size,
  color,
  width,
}) => {
  return (
    <Form.Group controlId={id} style={{ position: 'relative', width, ...style }}>
      <Form.Label className="form-textarea-label">{label}</Form.Label>
      <Form.Control
        style={{ resize: 'none', fontSize: 12 }}
        size={size}
        as="textarea"
        value={formik?.values[id]}
        onChange={formik?.handleChange}
        isInvalid={!!formik?.errors[id]}
        className={color && `form-textarea-${color}-color`}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={rows}
      />
    </Form.Group>
  )
}

export default FormTextarea
