import { Form } from 'react-bootstrap'
import './FormInput.scss'

const FormInput = ({
  autocomplete,
  label,
  id,
  formik,
  width,
  readOnly,
  placeholder,
  style,
  size,
  color,
  height = 30,
  mutedText,
  className,
  type = 'text',
  sameAsCompany
}) => {
  return (
    <Form.Group
      className={className}
      controlId={id}
      style={{ position: 'relative', width: width || '', ...style }}
    >
      <Form.Label
        className="form-input-label"
        style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
      >
        <span>{label}</span>
        {sameAsCompany && (
          <span onClick={() => sameAsCompany(id)} style={{ cursor: 'pointer' }}>
            Same as company
          </span>
        )}
      </Form.Label>
      {!!formik?.errors[id] ? (
        <Form.Control
          type={type}
          size={size}
          className="form-input-danger-color form-input-control"
          style={{
            height: height && height,
            fontSize: 12,
          }}
          value={formik?.values[id]}
          onChange={formik?.handleChange}
          isInvalid={!!formik?.errors[id]}
          placeholder={placeholder}
          readOnly={readOnly}
          autocomplete={autocomplete}
        />
      ) : (
        <Form.Control
          type={type}
          size={size}
          className={(color && `form-input-${color}-color`) + ' form-input-control'}
          style={{
            height: height && height,
            fontSize: 12,
          }}
          value={formik?.values[id]}
          onChange={formik?.handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}
      <Form.Text className="muted-text">{mutedText}</Form.Text>
    </Form.Group>
  )
}

export default FormInput
