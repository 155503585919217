import Axios from '../config/Axios'
export const getRateLimit = async () => {
    const response = await Axios.get('rate-limit/get-limit')
    return response
}
export const setRateLimit = async (data) => {
    const response =  await Axios.post('rate-limit/set-limit', data)
    return response;
}

export const getBatchData = async()=>{
    const response = await Axios.get('rate-limit/get-batch-data');
    return response;
}

export const pauseBatch = async (data,id)=>{
const response = await Axios.put(`rate-limit/pause-batch/${id}`,data)
return response;
}

export const deleteBatch = async (id) =>{
    const response = await Axios.delete(`rate-limit/delete-batch/${id}`)
    return response;
}