import React, { useEffect } from 'react'
import './MspMenu.scss'
import PersonIcon from '../../assets/icons/person'
import BuildingIcon from '../../assets/icons/building'
import PeopleIcon from '../../assets/icons/people'
import StarIcon from '../../assets/icons/star'
import NewsIcon from '../../assets/icons/news'
import ErrorIcon from '../../assets/icons/error'
import IdIcon from '../../assets/icons/id'
import AlertIcon from '../../assets/icons/alert'
import LensIcon from '../../assets/icons/lens'
import LogoutIcon from '../../assets/icons/logout'
import MarketingIcon from '../../assets/icons/marketing'
import BreachesIcon from '../../assets/icons/breaches'
import TrainingIcon from '../../assets/icons/training'

import { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { routes } from '../../config/constants'
import Navbar from '../Navbar'
import { getMyUserService } from '../../services/user.service'
import { logoutService } from '../../services/auth.service'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'

const MspMenu = ({ children }) => {
  const [loggedOut, setLoggedOut] = useState(false)
  const [account, setAccount] = useState({})
  const [logoUrl, setLogoUrl] = useState('')

  async function getMyMSPFromAPI() {
    try {
      const storageLogo = localStorage.getItem('logo')
      const sessionStatus = sessionStorage.getItem('sessionStatus')

      // if (!sessionStatus) {
      //   await logoutService()
      // }

      if (storageLogo) {
        setLogoUrl(storageLogo)
      } else {
        setLogoUrl(Logo)
      }

      const response = await getMyUserService()
      const accountData = response.data.data
      setAccount(accountData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getMyMSPFromAPI()
  }, [])

  async function logout() {
    await logoutService()
    localStorage.clear()
    setLoggedOut(true)
  }

  return (
    <>
      {loggedOut ? (
        <Redirect to={routes.LOGIN_ROUTE} />
      ) : (
        <>
          <Navbar logo={logoUrl} />

          <div className="sidebar">
            <div className="title">
              <p>MSP ADMIN</p>
              <span onClick={logout} className="mdw-menu-logout-button">
                <LogoutIcon />
              </span>
            </div>
            <div className="options">
              <p
                className="option"
                style={{ fontWeight: 'bold', fontSize: 16, fontFamily: 'Roboto' }}
              >
                Management
              </p>
              <div className="option">
                <Link to={routes.BREACHES_ROUTE}>
                  <span className="icon">
                    <BreachesIcon />
                  </span>
                  <span className="text">Breaches</span>
                </Link>
              </div>
              <div className="option">
                <Link to={routes.CUSTOMERS_ROUTE}>
                  <span className="icon">
                    <BuildingIcon />
                  </span>
                  <span className="text">Customers</span>
                </Link>
              </div>
                <div className="option">
                  <Link to={routes.COMMUNITY_ROUTE}>
                    <span className="icon">
                      <StarIcon />
                    </span>
                    <span className="text">Community</span>
                    <span style={{ float: 'right', marginTop: '8px', marginRight: '25px', backgroundColor: '#6c9472', color: '#fff', padding: '1px 3px', fontSize: '11px', fontWeight: '700', transform: "rotate(20deg)", borderRadius:'5px'}}>
                      NEW
                    </span>
                  </Link>
                </div>
              {/* <div className="option">
                <Link to={routes.NEWS_ROUTE}>
                  <span className="icon">
                    <NewsIcon />
                  </span>
                  <span className="text">News</span>
                  {account.badge?.news > 0 && (
                    <span className="badge badge-pill badge-danger">{account.badge?.news}</span>
                  )}
                </Link>
              </div> */}
              <div className="option">
                <Link to={routes.BREACH_CATALOGUE_ROUTE}>
                  <span className="icon">
                    <ErrorIcon />
                  </span>
                  <span className="text">Breach Catalogue</span>
                </Link>
              </div>
              <div className="option">
                <Link to={routes.PROSPECTING_REPORT_ROUTE}>
                  <span className="icon">
                    <LensIcon />
                  </span>
                  <span className="text">Prospecting Report</span>
                </Link>
              </div>
              {/* <div className="option">
                <Link to={routes.TRAINING_MATERIAL_ROUTE}>
                  <span className="icon">
                    <TrainingIcon />
                  </span>
                  <span className="text">Training</span>
                </Link>
              </div> */}
              <p
                className="option"
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  fontFamily: 'Roboto',
                  marginTop: 20,
                }}
              >
                Configuration
              </p>
              <div className="option">
                <Link to={routes.ACCOUNT_ROUTE}>
                  <span className="icon">
                    <PersonIcon />
                  </span>
                  <span className="text">Account</span>
                </Link>
              </div>
              <div className="option">
                <Link to={routes.USERS_ROUTE}>
                  <span className="icon">
                    <PeopleIcon />
                  </span>
                  <span className="text">Users</span>
                </Link>
              </div>
              <div className="option">
                <Link to={routes.BRANDIND_ROUTE}>
                  <span className="icon">
                    <IdIcon />
                  </span>
                  <span className="text">Branding</span>
                </Link>
              </div>
              <div className="option">
                <Link to={routes.HELPDESK_ALERTING_ROUTE}>
                  <span className="icon">
                    <AlertIcon />
                  </span>
                  <span className="text">Helpdesk Alerting</span>
                </Link>
              </div>
            </div>
            <div className="footer">
              <p className="footer-title">LOGGED IN USER</p>
              <p className="footer-username">{account.username}</p>
            </div>
          </div>
          <div className="content">{children}</div>
        </>
      )}
    </>
  )
}

export default MspMenu
