import MspMenu from '../../components/MspMenu'
import AddCustomerComponent from './AddCustomerComponent.jsx'
import { useState } from 'react'
import { addCustomerService } from '../../services/customer.service'
import { schema } from '../../schemas/customer.schema'
import { getCustomerErrorHandler } from './errorHandler'
import { useHistory } from 'react-router-dom'
import { notAllowedDomains } from '../../config/constants'

const AddCustomerContainer = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [errors, setErrors] = useState({})
  const initialValues = {
    companyName: '',
    firstName: '',
    lastName: '',
    reportEmail: '',
    industry: '',
    country: '',
    status: false,
    customerReport: false,
    totalDomains: 0,
    totalEmails: 0,
    reportFrequency: 'monthly',
    reportStatus: false,
    domains: [],
    emails: [],
  }
  const [customer, setCustomer] = useState(initialValues)
  const [table, setTable] = useState([])

  function handleOnChange(e) {
    const { id, value, type } = e.target

    if (type === 'checkbox') {
      setCustomer({ ...customer, [id]: !customer[id] })
    } else {
      setCustomer({ ...customer, [id]: value })
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      await schema.validate(customer, { abortEarly: false })
      setIsLoading(true)
      const response = await addCustomerService(customer)
      history.replace('/first-breach/' + response.data.data.customerId)
    } catch (error) {
      setIsLoading(false)
      if (error.inner) {
        const newErrorObject = {}
        error.inner.forEach(e => {
          if (e.path.includes('domains')) e.path = 'domains'
          if (e.path.includes('emails')) e.path = 'emails'
          newErrorObject[e.path] = e.message
        })
        setErrors({ ...newErrorObject })
      } else {
        setShowModal(getCustomerErrorHandler(error))
      }
    }
  }

  function handleClickDelete(selectedString, id) {
    setTable(table.filter(value => value !== selectedString))
    if (id === 'emails') {
      const updatedEmails = customer.emails.filter(email => email !== selectedString)
      setCustomer({ ...customer, emails: [...updatedEmails] })
    } else if (id === 'domains') {
      const updatedDomains = customer.domains.filter(domain => domain !== selectedString)
      setCustomer({ ...customer, domains: [...updatedDomains] })
    }
  }

  function handleOnClickAdd(id) {
    const value = document.getElementById(id).value
    if (id === 'domains') {
      if (customer.domains.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER DOMAIN TO SEARCH',
          message: `You entered "${value}" this domain is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (
          !/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(value)
        ) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `You entered "${value}" which is not a valid domain`,
          })
          document.getElementById(id).value = ''
        } else if (notAllowedDomains.includes(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `The domain ${value} is in a list of disallowed domains`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, value])
          if (value !== '') setCustomer({ ...customer, [id]: [...customer[id], value] })
          document.getElementById(id).value = ''
        }
      }
    } else {
      if (customer.emails.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER EMAIL TO SEARCH',
          message: `You entered "${value}" this email is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,25}$/.test(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID EMAIL TO SEARCH',
            message: `You entered "${value}" which is not a valid email`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, value])
          if (value !== '') setCustomer({ ...customer, [id]: [...customer[id], value] })
          document.getElementById(id).value = ''
        }
      }
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <AddCustomerComponent
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          isLoading={isLoading}
          errors={errors}
          customer={customer}
          handleClickDelete={handleClickDelete}
          handleOnClickAdd={handleOnClickAdd}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          table={table}
        />
      </MspMenu>
    </>
  )
}

export default AddCustomerContainer
