import { useState } from 'react'
import Navbar from '../../components/Navbar'
import ResetPasswordComponent from './ResetPasswordComponent.jsx'
import { useFormik } from 'formik'
import { resetPasswordService } from '../../services/auth.service'
import { schema } from '../../schemas/resetPassword.schema'
import { resetPasswordErrorHandler } from './errorHandler'
import { routes } from '../../config/constants'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'

const ResetPasswordContainer = ({ match }) => {
  const resetToken = match.params.token
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})

  const initialValues = {
    resetToken,
    newPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await resetPasswordService(data)
        setShowModal({
          show: true,
          title: 'THE PASSWORD HAS BEEN CHANGED',
          message: 'The password has been changed successfully',
          redirect: routes.LOGIN_ROUTE,
        })
        actions.resetForm()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(resetPasswordErrorHandler(error))
        actions.resetForm()
      }
    },
  })

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <Navbar logo={Logo} />
      <ResetPasswordComponent
        isLoading={isLoading}
        formik={formik}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
      />
    </>
  )
}

export default ResetPasswordContainer
