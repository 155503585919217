import Axios from '../config/Axios'

export const addFeatureRequestService = async data => {
  const response = await Axios.post('feature-request/', data)
  return response
}

export const plusOneFeatureRequestService = async id => {
  const response = await Axios.post(`feature-request/plus-one/${id}`)
  return response
}

export const getAllFeatureRequestsService = async () => {
  const response = await Axios.get('feature-request/all')
  return response
}

export const updateFeatureRequestService = async (data, id) => {
  const response = await Axios.put(`feature-request/${id}`, data)
  return response
}

export const deleteFeatureRequestService = async (id) => {
  const response = await Axios.delete(`feature-request/${id}`)
  return response
}
