import './FeatureRequestsStyles.scss'
import { Form, Table } from 'react-bootstrap'
import { motion } from 'framer-motion'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import FormTextarea from '../../components/FormTextarea'
import FormButton from '../../components/FormButton'
import FeatureDetails from '../../components/FeatureDetails'
import Banner from '../../components/Banner'
import Modal from '../../components/Modal'

const FeatureRequestsComponent = ({
  formik,
  featureRequests,
  categories,
  isLoading,
  showModal,
  handleCloseModal,
  showFeatureDetails,
  handleFeatureDetailsClick,
  handleOnCloseFeatureDetails,
  handlePlusOneFeatureRequestClick,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <FeatureDetails
        featureRequest={showFeatureDetails.data}
        show={showFeatureDetails.show}
        onClose={handleOnCloseFeatureDetails}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Banner title="FEATURE REQUESTS" />
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="feature-requests-container">
            <div style={{ width: '70%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormInput
                  formik={formik}
                  id="title"
                  style={{ display: 'inline-block', marginBottom: 23 }}
                  width={260}
                  color="primary"
                  label="Title"
                />
                <FormSelect
                  id="featureRequestCategoryId"
                  formik={formik}
                  options={categories}
                  type="categories"
                  style={{ display: 'inline-block', marginBottom: 23 }}
                  width={260}
                  color="primary"
                  label="Category"
                />
                <FormButton type="submit" label={isLoading ? 'LOADING...' : 'SUBMIT'} width={100} />
              </div>
              <FormTextarea
                id="description"
                formik={formik}
                rows={4}
                color="primary"
                label="Feature Description"
              />
            </div>
            {featureRequests.map(featureRequestDate => {
              const [status, featuresRequestsForStatus] = featureRequestDate
              return (
                <div className="feature-requests-table" key={status}>
                  <h5>{status}</h5>
                  <Table borderless>
                    <thead>
                      <tr>
                        <th style={{ width: '10%' }}>Feature Title</th>
                        <th style={{ width: '60%' }}>Feature Description</th>
                        <th style={{ width: '10%' }}>Request Date</th>
                        <th style={{ width: '10%' }} className="text-center">
                          Requests
                        </th>
                        <th style={{ width: '10%' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {featuresRequestsForStatus.map(featureRequest => (
                        <tr key={featureRequest.id}>
                          <td>
                            <span
                              id={featureRequest.id}
                              className="feature-requests-feature-details-click"
                              onClick={handleFeatureDetailsClick}
                            >
                              {featureRequest.title}
                            </span>
                          </td>
                          <td>{featureRequest.description}</td>
                          <td>{featureRequest.createdAt.slice(0, -14)}</td>
                          <td className="text-center">{featureRequest.requests}</td>
                          <td>
                            <button
                              disabled={!featureRequest.availableToPlus}
                              id={featureRequest.id}
                              type="button"
                              className="feature-requests-table-button"
                              onClick={handlePlusOneFeatureRequestClick}
                            >
                              +1
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )
            })}
          </div>
        </Form>
      </motion.div>
    </>
  )
}

export default FeatureRequestsComponent
