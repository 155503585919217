import './PreviousSearchesStyles.scss'
import { Table, Row, Col } from 'react-bootstrap'
import Banner from '../../components/Banner'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import { routes } from '../../config/constants'
import { Link } from 'react-router-dom'
import FormButton from '../../components/FormButton'
import FormSwitch from '../../components/FormSwitchWithoutFormik'

const ProspectingReportComponent = ({
  showModal,
  handleCloseModal,
  breaches,
  previousSearches,
  handlePreviousSearchesClick,
  downloadPdfReports,
  isLoading,
  handleCheckboxClick,
  checked,
  setChecked
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Banner title="PREVIOUS SEARCHES" redirect={routes.PROSPECTING_REPORT_ROUTE} />
        <div className="previous-searches-container">
          <>
            <Row>
              <Col>
                <Row>
                  {previousSearches.emails.length > 0 && (
                    <Col>
                      <h5>Email Searches</h5>
                      <ul>
                        {previousSearches.emails.map(email => (
                          <li
                            key={email.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handlePreviousSearchesClick(email.id)}
                          >
                            {email.email}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  )}
                  <Col>
                    <h5>Domain Searches</h5>
                    <ul>
                      {previousSearches.domains.map(domain => (
                        <li
                          key={domain.id}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handlePreviousSearchesClick(domain.id)}
                        >
                          {domain.domain}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col></Col>
            </Row>
            <div className="prospecting-report-separate-line" />
            {breaches.breaches ? (
              <>
                {breaches.breaches.length > 0 ? (
                  <>
                    <div className="prospecting-report-results-container">
                      <h2 className="prospecting-report-results-subtitle">RESULTS</h2>
                      <FormButton
                        onClick={() => {
                          downloadPdfReports(breaches.reportPdf)
                        }}
                        label={isLoading ? 'DOWNLOADING...' : 'DOWNLOAD REPORT'}
                        isDisabled={isLoading}
                        width={200}
                      />
                    </div>
                    <p>Search Results for: {breaches.customerName}</p>
                    <Table className="previous-searches-table" borderless hover striped>
                      <thead>
                        <tr>
                          <th>Email Address</th>
                          <th style={{ display: 'flex', alignItems: 'flex-end' , gap: '5px', minWidth:'120px'}}>
                    Password
                <FormSwitch
                  label=""
                  value={checked}
                  errors={'errors'}
                  onChange={e => handleCheckboxClick(e,setChecked)}
                  id="revealPass"
                  color="primary"
                  mutedText=""
                  style={{ margin:0  }}
                  password={true}
                />
                        
                        </th>
                          <th>Breach Source</th>
                          <th>Breach Date</th>
                          <th>Publish Date</th>
                          <th className="text-center">Severity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {breaches.breaches?.map(breach => (
                          <tr key={breach.id}>
                            <td>{breach.email}</td>
                            <td>
                              {breach.decryptPass && breach.password_plaintext && checked
                                ? breach.decryptPass
                                : breach.hashedPassword
                                }
                            </td>
                            {/* <td style={{textAlign:'center'}} ><input  id={breach.id} type="checkbox" onClick={(e)=> handleCheckboxClick(e)} /></td>
                            <td className="text-center">
                              {breach.password_plaintext ? 'yes' : 'no'}
                            </td> */}
                            <td>
                              <Link
                                className="breaches-table-link"
                                to={`/breaches/${breach.breach?.mspbcid}/details`}
                              >
                                {breach.breach?.title || 'unknown'}
                              </Link>
                            </td>
                            <td>{breach.breach?.breach_date.slice(0, 10) || 'unknown'}</td>
                            <td>{breach.spycloud_publish_date.slice(0, 10)}</td>
                            <td className="text-center">{breach.severity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <div className="prospecting-report-results-container">
                      <h2 className="prospecting-report-results-subtitle">RESULTS</h2>
                    </div>
                    <p>Search Results for: {breaches.customerName}</p>
                    <p>Congratulations! You currently have a clean bill of health.</p>
                  </>
                )}
              </>
            ) : (
              'Select a previous search to see its results.'
            )}
          </>
        </div>
      </motion.div>
    </>
  )
}

export default ProspectingReportComponent
