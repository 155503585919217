import './AdminTrainingMaterialStyles.scss'
import Modal from '../../components/Modal'
import AdminBanner from '../../components/AdminBanner'
import UploadIcon from '../../assets/icons/upload.svg'
import AdminUploadTrainingMaterial from '../../components/AdminUploadTrainingMaterial'
import TrashIcon from '../../assets/icons/trash'

const AdminTrainingMaterialComponent = ({
  showModal,
  handleCloseModal,
  showUploadTrainingMaterial,
  handleOnCloseUploadTrainingMaterial,
  dataToSend,
  handleChangeFile,
  handleChangeInputs,
  handleSubmit,
  handleUploadTrainingMaterialClick,
  trainingMaterials,
  handleDeleteTrainingMaterial,
  isLoading,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <AdminUploadTrainingMaterial
        show={showUploadTrainingMaterial.show}
        video={showUploadTrainingMaterial.video}
        image={showUploadTrainingMaterial.image}
        onClose={handleOnCloseUploadTrainingMaterial}
        values={dataToSend}
        onChangeFile={handleChangeFile}
        onChangeInputs={handleChangeInputs}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
      <AdminBanner title="TRAINING MATERIAL" />
      <div className="admin-training-material-container">
        <div
          onClick={handleUploadTrainingMaterialClick}
          className="text-center admin-training-material-icon-container"
        >
          <img src={UploadIcon} width={30} height={37.5} />
          <p>UPLOAD</p>
        </div>
        <br />
        <div className="admin-training-material-body">
          {trainingMaterials.map(trainingMaterial => (
            <div style={{ marginRight: 50, marginBottom: 30 }} key={trainingMaterial.id}>
              <h4 className="admin-training-material-video-title">{trainingMaterial.name}</h4>
              <video
                controls
                poster={trainingMaterial.imageUrl}
                src={trainingMaterial.videoUrl}
                className="admin-training-material-video"
              ></video>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="admin-training-material-video-description">
                  {trainingMaterial.description}
                </p>
                <TrashIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteTrainingMaterial(trainingMaterial.id)}
                  stroke="#0d66d0"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AdminTrainingMaterialComponent
