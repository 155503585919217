import MdwMenu from '../../components/MdwMenu'
import AdminTrainingMaterialComponent from './AdminTrainingMaterialComponent.jsx'
import { useState, useEffect } from 'react'
import {
  deleteTrainingMaterial,
  getAllTrainingMaterials,
  uploadTrainingMaterial,
} from '../../services/trainingMaterial.service'
import { trainingMaterialErrorHandler } from './errorHandler'

const AdminTrainingMaterialContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [dataToSend, setDataToSend] = useState({
    name: '',
    description: '',
    order: '',
    video: null,
    image: null,
  })
  const [showUploadTrainingMaterial, setShowUploadTrainingMaterial] = useState({
    show: false,
    video: null,
    image: null,
  })
  const [trainingMaterials, setTrainingMaterials] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  async function getAllTrainingMaterialsFromAPI() {
    try {
      const response = await getAllTrainingMaterials()
      setTrainingMaterials(response.data.data)
    } catch (error) {
      setShowModal(trainingMaterialErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllTrainingMaterialsFromAPI()
  }, [])

  async function handleSubmit(e) {
    try {
      e.preventDefault()
      if (
        dataToSend.name === '' ||
        dataToSend.description === '' ||
        dataToSend.order === '' ||
        dataToSend.video === null
      ) {
        setShowModal({
          show: true,
          title: 'FILL IN ALL FIELDS',
          message: 'All fields are required, complete them before saving',
        })
      } else {
        setIsLoading(true)
        await uploadTrainingMaterial(dataToSend)
        setShowUploadTrainingMaterial({
          show: false,
          video: null,
        })
        getAllTrainingMaterialsFromAPI()
        setIsLoading(false)
      }
    } catch (error) {
      setShowModal(trainingMaterialErrorHandler(error))
    }
  }

  async function handleDeleteTrainingMaterial(id) {
    const trainingMaterial = trainingMaterials.find(trainingMaterial => trainingMaterial.id === id)
    try {
      if (
        window.confirm(
          `Are you sure you want to remove the training material "${trainingMaterial.name}"? This action is irreversible`
        )
      ) {
        await deleteTrainingMaterial(id)
        getAllTrainingMaterialsFromAPI()
      }
    } catch (error) {
      setShowModal(trainingMaterialErrorHandler(error))
    }
  }

  function handleChangeInputs(e, id) {
    setDataToSend(prevState => ({ ...prevState, [id]: e.target.value }))
  }

  function handleChangeFile(e, id) {
    setDataToSend(prevState => ({ ...prevState, [id]: e.target.files[0] }))
    const tempUrl = URL.createObjectURL(e.target.files[0])
    setShowUploadTrainingMaterial(prevState => ({ ...prevState, [id]: tempUrl }))
  }

  function handleCloseModal() {
    setShowModal({})
  }

  function handleUploadTrainingMaterialClick() {
    setShowUploadTrainingMaterial({ show: true, video: null })
  }

  function handleOnCloseUploadTrainingMaterial() {
    setShowUploadTrainingMaterial({ show: false, video: null })
  }

  return (
    <>
      <MdwMenu>
        <AdminTrainingMaterialComponent
          {...{
            showModal,
            handleCloseModal,
            showUploadTrainingMaterial,
            handleOnCloseUploadTrainingMaterial,
            dataToSend,
            handleChangeFile,
            handleChangeInputs,
            handleSubmit,
            handleUploadTrainingMaterialClick,
            trainingMaterials,
            handleDeleteTrainingMaterial,
            isLoading,
          }}
        />
      </MdwMenu>
    </>
  )
}

export default AdminTrainingMaterialContainer
