import './AdminMSPsStyles.scss'
import { Container, Table } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'
import Modal from '../../components/Modal'
import CloudActiveIcon from '../../assets/icons/cloudStatusActive.svg'
import CloudNotActiveIcon from '../../assets/icons/cloudStatus.svg'
import TrashActiveIcon from '../../assets/icons/trashSuspendActive.svg'
import TrashNotActiveIcon from '../../assets/icons/trashSuspend.svg'
import TrashDelete from '../../assets/icons/trashDelete.svg'
import FormButton from '../../components/FormButton'
import AdminBanner from '../../components/AdminBanner'

const AdminMSPsComponent = ({
  showModal,
  handleCloseModal,
  MSPs,
  handleChangeStatus,
  handleDelete,
}) => {
  return (
    <>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Modal
          showModal={showModal.show}
          handleCloseModal={handleCloseModal}
          title={showModal.title}
          message={showModal.message}
          redirect={showModal.redirect}
        />
        <AdminBanner title="MSPs" />
        <div className="admin-msps-container">
          <Table className="admin-msps-table" borderless hover striped>
            <thead>
              <tr>
                <th>COMPANY</th>
                <th>CONTACT</th>
                <th>PACKAGE</th>
                <th className="text-center">CUSTOMERS</th>
                <th className="text-center">DOMAINS</th>
                <th className="text-center">EMAILS</th>
                <th className="text-center">STATUS</th>
                <th className="text-center">SUSPEND</th>
                <th className="text-center">DELETE</th>
              </tr>
            </thead>
            <tbody>
              {MSPs?.map(MSP => (
                <tr key={MSP.mspId}>
                  <td>
                    <Link
                      to={adminRoutes.EDIT_MSP_ROUTE + MSP.mspId}
                      style={{ color: 'black', textDecoration: 'none' }}
                    >
                      {MSP.companyName}
                    </Link>
                  </td>
                  <td>{`${MSP.pcFirstName} ${MSP.pcLastName}`}</td>
                  <td>{MSP.packageSku}</td>
                  <td className="text-center">{MSP.customers}</td>
                  <td className="text-center">{MSP.domains}</td>
                  <td className="text-center">{MSP.emails}</td>
                  <td className="text-center">
                    {MSP.status ? (
                      <img
                        src={CloudActiveIcon}
                        alt="Cloud icon to change the msp status"
                        width={24}
                        height={16}
                      />
                    ) : (
                      <img
                        src={CloudNotActiveIcon}
                        alt="Cloud icon to change the msp status"
                        width={24}
                        height={16}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    {MSP.status ? (
                      <img
                        id={MSP.mspId}
                        onClick={handleChangeStatus}
                        style={{ cursor: 'pointer' }}
                        src={TrashActiveIcon}
                        alt="Trash icon to suspend an MSP"
                        width={15.16}
                        height={16}
                      />
                    ) : (
                      <img
                        id={MSP.mspId}
                        onClick={handleChangeStatus}
                        style={{ cursor: 'pointer' }}
                        src={TrashNotActiveIcon}
                        alt="Trash icon to suspend an MSP"
                        width={15.16}
                        height={16}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <img
                      id={MSP.mspId}
                      onClick={handleDelete}
                      style={{ cursor: 'pointer' }}
                      src={TrashDelete}
                      alt="Trash icon to delete an MSP"
                      width={12.44}
                      height={16}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </motion.div>
      <Link to={adminRoutes.ADD_MSP_ROUTE}>
        <FormButton className="admin-msp-add-button" label="ADD MSP" width={100} height={40} mdw />
      </Link>
    </>
  )
}

export default AdminMSPsComponent
