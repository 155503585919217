import './AdminNewsArticle.scss'
import CrossIcon from '../../assets/icons/crossWhite.svg'
import { dateToSemiTextFormat } from '../../utilities/transformDates'

const AdminNewsArticle = ({ show, onClose, news }) => {
  if (!show) {
    return null
  }

  return (
    <div className="admin-news-article">
      <div className="admin-news-article-content">
        <div className="admin-news-article-header">
          <h4 className="admin-news-article-title">NEWS</h4>
          <img onClick={onClose} src={CrossIcon} width={25} height={25} />
        </div>
        <div className="admin-news-article-container">
          <h4 className="admin-news-article-news-title">{news.title}</h4>
          <div className="admin-news-article-date-container">
            <h3>{dateToSemiTextFormat(news.createdAt)}</h3>
            <h3>{news.newsCategory?.name}</h3>
          </div>
          <div className="admin-news-article-separate-line" />
          <div
            className="admin-news-article-body"
            dangerouslySetInnerHTML={{ __html: news.description }}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminNewsArticle
