import { useEffect, useState } from 'react'
import MspMenu from '../../components/MspMenu'
import DashboardComponent from './DashboardComponent.jsx'
import { getAllNewsService } from '../../services/news.service'
import { getMyMspService } from '../../services/msp.service'
import { dashboardErrorHandler } from './errorHandler'
import { getAllBreachesServiceCount } from '../../services/breach.service'
import { getDashboardContentService } from '../../services/dashboardContent.service'
import { getCustomersCountService } from '../../services/customer.service'
import { getPreviousSearchesCountService } from '../../services/prospectingReport.service'

const DashboardContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [news, setNews] = useState([])
  const [account, setAccount] = useState({})
  const [breaches, setBreaches] = useState([])
  const [dashboardContent, setDashboardContent] = useState(null)
  const [icons, setIcons] = useState({
    newBreaches: '...',
    subscription: '...',
    domains: '...',
    customers: '...',
    emails: '...',
    prospecting: '...',
  })

  async function getMyMSPFromAPI() {
    try {
      const myMSP = await getMyMspService()
      const customersCount = await getCustomersCountService()
      const previousSearches = await getPreviousSearchesCountService()
      const updatedAccount = {
        ...myMSP.data.data,
        customers: customersCount.data.data,
        previousSearches: previousSearches.data.data,
      }

      setAccount(updatedAccount)
      setIcons(prevState => ({
        ...prevState,
        ...{
          subscription: updatedAccount.package.packageSku,
          domains: `${updatedAccount.domains?.length}/${updatedAccount.package.packageDomains}`,
          customers: updatedAccount.customers,
          emails: updatedAccount.emails?.length,
          prospecting: previousSearches.data.data,
        },
      }))
    } catch (error) {
        setShowModal(dashboardErrorHandler(error))

      }
  }

  async function getAllNewsFromAPI() {
    try {
      const limit = 3
      const News = await getAllNewsService(limit)
      setNews(News.data.data)
    } catch (error) {
      setShowModal(dashboardErrorHandler(error))
    }
  }

  async function getDashboardContentFromAPI() {
    try {
      const response = await getDashboardContentService()
      setDashboardContent(response.data.data)
    } catch (error) {
      setShowModal(dashboardErrorHandler(error))
    }
  }

  async function getAllMSPBreachesFromAPI() {
    try {
      const response = await getAllBreachesServiceCount()
      setBreaches(response.data.data.slice(0, 6))

      // const thirtyDaysAgo = new Date()
      // thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

      // const newBreaches = response.data.data.filter(
      //   breach => new Date(breach.spycloud_publish_date) > thirtyDaysAgo
      // )

      setIcons(prevState => ({
        ...prevState,
        newBreaches: response?.data?.data?.[0]?.count || 0,
      }))
    } catch (error) {
      setShowModal(dashboardErrorHandler(error))
    }
  }

  useEffect(() => {
    getMyMSPFromAPI()
    getAllNewsFromAPI()
    getAllMSPBreachesFromAPI()
    // getDashboardContentFromAPI()
  }, [])

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <DashboardComponent
          icons={icons}
          news={news}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          breaches={breaches}
          dashboardContent={dashboardContent}
        />
      </MspMenu>
    </>
  )
}

export default DashboardContainer
