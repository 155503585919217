import './BreachDetailsStyles.scss'
import { routes } from '../../config/constants'
import IconWithInfo from '../../components/IconWithInfo'
import EmailsIcon from '../../assets/icons/emails.svg'
import KeyIcon from '../../assets/icons/key.svg'
import NodoIcon from '../../assets/icons/nodo.svg'
import IdIcon from '../../assets/icons/id.svg'
import PersonInBoxIcon from '../../assets/icons/personInBox.svg'
import WorldIcon from '../../assets/icons/world.svg'
import PhoneIcon from '../../assets/icons/phone.svg'
import FinancialIcon from '../../assets/icons/financial.svg'
import PadlockBlackIcon from '../../assets/icons/padlockBlack.svg'
import Banner from '../../components/Banner'
import { dateToSemiTextFormat } from '../../utilities/transformDates'
import { numberWithCommas } from '../../utilities/transformNumbers'

const BreachDetailsComponent = ({
  breachDetails,
  assetStats,
}) => {
  return (
    <>
      <Banner title="BREACH DETAILS" redirect={routes.BREACH_CATALOGUE_ROUTE} />
      <div className="breach-details-container">
        <div className="breach-details-banner">
          <h4>{breachDetails.title}</h4>
          <h5>
            Published:{' '}
            {breachDetails.spycloud_publish_date
              ? dateToSemiTextFormat(breachDetails.spycloud_publish_date)
              : ''}
          </h5>
        </div>
        <p className="breach-details-subtitle">
          {breachDetails.description}
          <br />
          This breach is being privately shared on the internet.
        </p>
        <div className="breach-details-remedation-overview-container">
          <div className="breach-details-number-records">
            <h3 style={{ marginBottom: 0 }}>
              {breachDetails.num_records ? numberWithCommas(breachDetails.num_records) : ''}
            </h3>
            <p>Number of Records</p>
          </div>
          <div className="breach-details-private-data">
            <img src={PadlockBlackIcon} alt="Private Data Icon" width={50} height={50} />
            <p>Private Data</p>
          </div>
        </div>
        <div className="breach-catalogue-icons-header">
          <IconWithInfo
            text={numberWithCommas(assetStats.email)}
            bottomText={'Emails'}
            textSize={14}
            bottomTextSize={16}
            icon={EmailsIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.password)}
            bottomText={'Passwords'}
            textSize={14}
            bottomTextSize={16}
            icon={KeyIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.ip_addresses)}
            bottomText={'IP Addresses'}
            textSize={14}
            bottomTextSize={16}
            icon={NodoIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.username)}
            bottomText={'Usernames'}
            textSize={14}
            bottomTextSize={16}
            icon={IdIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.pii)}
            bottomText={'PII'}
            textSize={14}
            bottomTextSize={16}
            icon={PersonInBoxIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.geolocation)}
            bottomText={'Geographic Location'}
            textSize={14}
            bottomTextSize={16}
            icon={WorldIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.phone)}
            bottomText={'Phone Numbers'}
            textSize={14}
            bottomTextSize={16}
            icon={PhoneIcon}
            width={50}
            height={50}
            color="primary"
          />
          <IconWithInfo
            text={numberWithCommas(assetStats.financialInformation)}
            bottomText={'Financial Information'}
            textSize={14}
            bottomTextSize={16}
            icon={FinancialIcon}
            width={50}
            height={50}
            color="primary"
          />
        </div>
        <div className="breach-details-bottom-container">
          <div>
            <p>Other</p>
            <h5>INDUSTRY</h5>
          </div>
          <div>
            <p>{breachDetails.site ? breachDetails.site : 'n/a'}</p>
            <h5>TARGET SITE</h5>
          </div>
          <div>
            <p>
              {breachDetails.breach_date
                ? dateToSemiTextFormat(breachDetails.breach_date)
                : 'unknown'}
            </p>
            <h5>BREACH DATE</h5>
          </div>
          <div>
            <p>{breachDetails.type === 'PRIVATE' ? 'Private' : 'Public'}</p>
            <h5>SOURCE TYPE</h5>
          </div>
        </div>
      </div>
    </>
  )
}

export default BreachDetailsComponent
