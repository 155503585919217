import MspMenu from '../../components/MspMenu'
import { useState } from 'react';
import './CommunityContainerStyles.scss'
const CommunityContainer = () => {
	const [loading, setLoading] = useState(true);

	const handleIframeLoad = () => {
		setLoading(false);
	};
	return (
<MspMenu>
	{loading && (
				<div className='main'>
					<div className="loader"></div>
					</div>
			)}
			<div style={{
				height: 'calc(100vh - 100px)', overflow: 'auto' }}>
				<iframe onLoad={handleIframeLoad} src={process.env.REACT_APP_COMMUNITY_URL} style={{ width: '100%', minHeight: '99%', border: 'none' }} title="Community Msp dark web"></iframe>

			</div>
		
		</MspMenu>
	)
}
export default CommunityContainer