import './RequestUpgrade.scss'
import FormSelect from '../FormSelectWithoutFormik'

const RequestUpgrade = ({ options, onChange, value, onSubmit, show, onClose }) => {
  if (!show) {
    return null
  }

  return (
    <div className="request-upgrade">
      <div className="request-upgrade-content">
        <div className="request-upgrade-container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <h5 style={{ marginBottom: 0 }}>REQUEST AN UPGRADE TO YOUR ACCOUNT</h5>
            <span style={{ cursor: 'pointer' }} onClick={onClose}>
              X
            </span>
          </div>
          <FormSelect
            id="package"
            value={value}
            onChange={onChange}
            options={options}
            type="package"
            label={
              <a
                className="request-upgrade-price-redirect"
                target="_blank"
                href="https://mspdarkweb.com/packages-and-pricing/"
              >
                view package pricing
              </a>
            }
            textDecorationLine="underline"
            style={{
              width: '100%',
            }}
          />
          <p>
            Select the package you wish to upgrade to from the drop down above and we will process
            your request within 24 hours.{' '}
          </p>
          <button type="button" onClick={onSubmit}>
            REQUEST UPGRADE
          </button>
          <p className="request-upgrade-email-redirect text-center">
            To request a downgrade to your account please send an email to{' '}
            <a
              href="mailto:subscriptions@mspdarkweb.com?subject=MSP%20Dark%20Web%20Package%20Downgrade%20Request"
              target="_blank"
            >
              subscriptions@mspdarkweb.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RequestUpgrade
