const BuildingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V20H6.85714V16.1111H9.14286V20H16V0H0ZM2.28571 2.22222H4.57143V4.44444H2.28571V2.22222ZM6.85714 2.22222H9.14286V4.44444H6.85714V2.22222ZM11.4286 2.22222H13.7143V4.44444H11.4286V2.22222ZM2.28571 6.66667H4.57143V8.88889H2.28571V6.66667ZM6.85714 6.66667H9.14286V8.88889H6.85714V6.66667ZM11.4286 6.66667H13.7143V8.88889H11.4286V6.66667ZM2.28571 11.1111H4.57143V13.3333H2.28571V11.1111ZM6.85714 11.1111H9.14286V13.3333H6.85714V11.1111ZM11.4286 11.1111H13.7143V13.3333H11.4286V11.1111ZM2.28571 15.5556H4.57143V17.7778H2.28571V15.5556ZM11.4286 15.5556H13.7143V17.7778H11.4286V15.5556Z"
        fill="black"
      />
    </svg>
  )
}

export default BuildingIcon
