import './AdminAddMSPStyles.scss'
import { Row, Col, Form } from 'react-bootstrap'
import { adminRoutes } from '../../config/constants'
import AdminBanner from '../../components/AdminBanner'
import Modal from '../../components/Modal'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import FormButton from '../../components/FormButton'
import FormCheckbox from '../../components/FormCheckbox'

const AdminAddMSPComponent = ({
  MSPId,
  MSPData,
  formik,
  showModal,
  handleCloseModal,
  isLoading,
  packages,
  paymentMethods,
  handleSuspendMSP,
  handleSameAsCompany,
  handleDelete,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <AdminBanner redirect={adminRoutes.MSPS_ROUTE} title={MSPId ? 'EDIT MSP' : 'ADD MSP'} />
      <div className="admin-add-msp-container">
        {MSPId && (
          <Row>
            <Col style={{ minWidth: 210 }}>
              <div>
                <div className="admin-add-msp-data">
                  <h3>COMPANY NAME</h3>
                  <p>{MSPData.companyName || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>ADDRESS</h3>
                  <p>{MSPData.street || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>DOMAINS</h3>
                  {MSPData.domains?.length > 0 ? (
                    MSPData.domains?.map(domain => (
                      <p style={{ marginBottom: 0 }}>&bull; {domain.domain}</p>
                    ))
                  ) : (
                    <p>This MSP has no domains registered</p>
                  )}
                </div>
              </div>
            </Col>
            <Col style={{ minWidth: 210 }}>
              <div>
                <div className="admin-add-msp-data">
                  <h3>PRIMARY EMAIL</h3>
                  <p>{MSPData.pcEmail || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>COMPANY TELEPHONE</h3>
                  <p>{MSPData.phoneNumber || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>EMAILS</h3>
                  {MSPData.emails?.length > 0 ? (
                    MSPData.emails?.map(email => (
                      <p style={{ marginBottom: 0 }}>&bull; {email.email}</p>
                    ))
                  ) : (
                    <p>This MSP has no emails registered</p>
                  )}
                </div>
              </div>
            </Col>
            <Col style={{ minWidth: 210 }}>
              <div>
                <div className="admin-add-msp-data">
                  <h3>PRIMARY CONTACT</h3>
                  <p>{`${MSPData.pcFirstName} ${MSPData.pcLastName}` || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>COMPANY WEBSITE</h3>
                  <p>{MSPData.website || '...'}</p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>PAYMENT METHOD</h3>
                  <p>
                    {paymentMethods.find(
                      paymentMethod => paymentMethod.id === MSPData.paymentMethodId
                    )?.name || '...'}
                  </p>
                </div>
                <div className="admin-add-msp-data">
                  <h3>PRIMARY TELEPHONE</h3>
                  <p>{MSPData.pcTelephone || '...'}</p>
                </div>
              </div>
            </Col>
            <Col style={{ minWidth: 210 }}>
              <Row>
                <Col>
                  <div className="admin-add-msp-data">
                    <h3>PACKAGE</h3>
                    <p>
                      {packages.find(packagee => packagee.packageId === MSPData.packageId)
                        ?.packageSku || '...'}
                    </p>
                  </div>
                  <div className="admin-add-msp-data">
                    <h3>CUSTOMERS</h3>
                    <p>{MSPData.countCustomer || '...'}</p>
                  </div>
                </Col>
                <Col>
                  <div className="admin-add-msp-data">
                    <h3>DOMAINS</h3>
                    <p>{MSPData.countDomain|| '...'}</p>
                  </div>
                  <div className="admin-add-msp-data">
                    <h3>EMAILS</h3>
                    <p>{MSPData.countEmail|| '...'}</p>
                  </div>
                </Col>
              </Row>
              <FormCheckbox
                width={20}
                height={20}
                onChange={handleSuspendMSP}
                checked={!MSPData.status}
                label="Suspend MSP"
              />
              <FormButton onClick={handleDelete} width="100%" label="DELETE MSP" mdw />
            </Col>
          </Row>
        )}
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col style={{ minWidth: 210 }}>
              <FormInput formik={formik} id="companyName" label="Company Name" color="tertiary" />
              <FormInput formik={formik} id="street" label="Street" color="tertiary" />
              <FormInput formik={formik} id="city" label="City" color="tertiary" />
              <FormInput formik={formik} id="postalCode" label="Postcode / Zip" color="tertiary" />
              <FormInput formik={formik} id="phoneNumber" label="Company Phone" color="tertiary" />
            </Col>
            <Col style={{ minWidth: 210 }}>
              <FormInput
                formik={formik}
                id="pcFirstName"
                label="Primary Contact First Name"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="pcLastName"
                label="Primary Contact Last Name"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="pcEmail"
                label="Primary Contact Email"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="pcTelephone"
                label="Primary Contact Telephone"
                color="tertiary"
                sameAsCompany={handleSameAsCompany}
              />
              <FormInput formik={formik} id="website" label="Company Website" color="tertiary" />
            </Col>
            <Col style={{ minWidth: 210 }}>
              <FormInput
                formik={formik}
                id="billingFirstName"
                label="Billing Contact First Name"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="billingLastName"
                label="Billing Contact Last Name"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="billingEmail"
                label="Billing Contact Email"
                color="tertiary"
              />
              <FormInput
                formik={formik}
                id="billingTelephone"
                label="Billing Contact Telephone"
                color="tertiary"
                sameAsCompany={handleSameAsCompany}
              />
              <FormInput
                formik={formik}
                id="invoiceDelivery"
                label="Invoice Delivery Email"
                color="tertiary"
              />
            </Col>
            <Col style={{ minWidth: 210 }}>
              <FormSelect
                formik={formik}
                id="packageId"
                type="packages"
                options={packages}
                label="Package"
                color="tertiary"
              />
              <FormSelect
                formik={formik}
                id="paymentMethodId"
                type="paymentMethods"
                options={paymentMethods}
                label="Payment Method"
                color="tertiary"
              />
              <FormButton
                type="submit"
                className="admin-add-msp-button"
                label={
                  MSPId
                    ? isLoading
                      ? 'LOADING...'
                      : 'UPDATE MSP'
                    : isLoading
                    ? 'LOADING...'
                    : 'ADD MSP'
                }
                mdw
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default AdminAddMSPComponent
