import './NewsStyles.scss'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal'
import NewsArticle from '../../components/NewsArticle'
import Banner from '../../components/Banner'

const NewsComponent = ({
  news,
  newsCategories,
  showModal,
  handleCloseModal,
  handleFilterClick,
  showNewsArticle,
  handleNewsArticleClick,
  handleOnCloseNewsArticle,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <NewsArticle
        news={showNewsArticle.news}
        show={showNewsArticle.show}
        onClose={handleOnCloseNewsArticle}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <Banner title="NEWS" />
        <div className="news-container">
          <div className="news-close-button-container">
            <h3 className="news-filters">
              {newsCategories.length > 0 && (
                <span id="allCategories" onClick={handleFilterClick}>
                  All
                </span>
              )}
              {newsCategories.map(newsCategory => (
                <span key={newsCategory.id} id={newsCategory.id} onClick={handleFilterClick}>
                  {' | ' + newsCategory.name}
                </span>
              ))}
            </h3>
          </div>
          <div className="news-green-bar">NEWS</div>
          <div className="news-news-container">
            {news.map(oneNews => (
              <div key={oneNews.id} className="news-item-article">
                <div className="news-item-article-header">
                  <h5
                    style={{ cursor: 'pointer' }}
                    id={oneNews.id}
                    onClick={handleNewsArticleClick}
                  >
                    {oneNews.title}
                  </h5>
                </div>
                <h3 className="text-nowrap">{oneNews.createdAt.slice(0, -14)}</h3>
                <div dangerouslySetInnerHTML={{ __html: oneNews.description }}></div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default NewsComponent
