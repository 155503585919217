import LoginComponent from './LoginComponent.jsx'
import Navbar from '../../components/Navbar'
import { useFormik } from 'formik'
import { login } from '../../services/auth.service'
import { useState } from 'react'
import { schema } from '../../schemas/login.schema'
import { Redirect } from 'react-router-dom'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'

const LoginContainer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [showModal, setShowModal] = useState(false)

  function handleCloseModal() {
    setShowModal(false)
  }

  const initialValues = {
    username: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        const loginResponse = await login(data)
        localStorage.setItem('token', loginResponse.data.data)
        setLoggedIn(true)
      } catch (error) {
        setIsLoading(false)
        setShowModal(true)
        actions.resetForm()
      }
    },
  })

  return (
    <>
      <Navbar logo={Logo} />
      {loggedIn ? (
        <Redirect to="/2fa" />
      ) : (
        <LoginComponent
          isLoading={isLoading}
          formik={formik}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
        />
      )}
    </>
  )
}

export default LoginContainer
