import Axios from '../config/Axios'

export const getBreachesForEmailOrDomainService = async data => {
  try{
    const response = await Axios.post(`/prospecting/report`, data)
    return response;
  }catch(error){
    return error;
  }
}

export const getPreviousSearchesService = async () => {
  const response = await Axios.get('/prospecting/report/previous/searches')
  return response
}
export const getPreviousSearchesCountService = async () => {
  const response = await Axios.get('/prospecting/report/previous/searches/count')
  return response
}

export const getPreviousSearchedData = async () => {
  const response = await Axios.get('/prospecting/report/previous/searchedData')
  return response
}

export const getPreviousSearchServiceByID = async (id) => {
  const response = await Axios.get(`/prospecting/report/previous/searches/${id}`)
  return response
}

export const getPreviousAllSearchesCountService = async () => {
  const response = await Axios.get('/prospecting/report/previous/searches/all/count')
  return response
}

export const deletePreviousSearchesService = async (mspId) => {
  const response = await Axios.delete('/prospecting/report/previous/searches/delete/' + mspId)
  return response
}
