import './HelpdeskAlertingStyles.scss'
import { motion } from 'framer-motion'
import FormInput from '../../components/FormInput'
import FormInputWithoutFormik from '../../components/FormInputWithoutFormik'
import FormSelect from '../../components/FormSelectWithoutFormik'
import FormButton from '../../components/FormButton'
import Banner from '../../components/Banner'
import Modal from '../../components/Modal'
import { Form, Row, Col } from 'react-bootstrap'

const HelpdeskAlertingComponent = ({
  formik,
  isLoading,
  isLoadingTest,
  showModal,
  handleCloseModal,
  helpdeskTest,
  handleOnChangeTestHelpdesk,
  customers,
  handleSubmitTestHelpdesk,
  handleClickVariable,
}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <Banner title="HELPDESK ALERTING" />
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="helpdesk-alerting-container">
          <FormInput
            formik={formik}
            id="helpdeskEmailAddress"
            label="Helpdesk Email Address"
            color="primary"
            style={{ maxWidth: 820 }}
            height={30}
            small
          />
          <p className="helpdesk-alerting-footer-text">
            This is the email address you want the alerts to be sent to. <br />
          </p>
          <FormInput
            formik={formik}
            id="helpdeskEmailSubject"
            label="Email Subject - Leave Unchanged for the default subject format"
            color="primary"
            placeholder="Dark Web Alert - %%companyname%% - %%firstname%% %%lastname%%"
            width={820}
            height={30}
            small
          />

          <div className="helpdesk-alerting-subject-variables-container">
            <h5 className="helpdesk-alerting-subject-variables">
              <b>SUBJECT VARIABLES: </b>
              <span onClick={() => handleClickVariable('%%companyName%%')}>%%companyName%%, </span>
              <span onClick={() => handleClickVariable('%%firstName%%')}>%%firstName%%, </span>
              <span onClick={() => handleClickVariable('%%lastName%%')}>%%lastName%%, </span>
              <span onClick={() => handleClickVariable('%%breachedEmail%%')}>
                %%breachedEmail%%
              </span>
            </h5>
            <FormButton
              type="submit"
              label={isLoading ? 'LOADING...' : 'SAVE'}
              height={30}
              width={100}
            />
          </div>
          <div className="helpdesk-alerting-footer-button"></div>
          <div className="helpdesk-alerting-separate-line" style={{ maxWidth: 820 }} />
          <p className="helpdesk-alerting-footer-text">
            If you are running a PSA then generally the email subject can be parsed. <br />
            To make it really easy to capture these alerts and log them against the right company
            and contact we allow you to customise the email subject. <br />
            <b>
              All email alerts are sent from noreply@darkwebsecurity.cloud. Please ensure you whitelist
              this email address.
            </b>{' '}
            <br />
            We are constantly working on adding more integrations, if your PSA integration is not
            listed then please log a feature request.
          </p>
          <div className="helpdesk-alerting-separate-line" style={{ maxWidth: 820 }} />
          <div className="helpdesk-alerting-inputs-container">
            <FormSelect
              id="testCompany"
              options={customers}
              type="customer"
              value={helpdeskTest}
              style={{ marginBottom: 20 }}
              width={320}
              label="Test Company"
              onChange={e => handleOnChangeTestHelpdesk('testCompany', e)}
              color="primary"
              className="helpdesk-alerting-input"
              height={30}
              small
            />
            <FormInputWithoutFormik
              id="testEmailAddress"
              value={helpdeskTest}
              onChange={e => handleOnChangeTestHelpdesk('testEmailAddress', e)}
              style={{ marginBottom: 20 }}
              width={320}
              label="Test Email Address"
              color="primary"
              className="helpdesk-alerting-input"
              height={30}
              small
            />
            <FormButton
              onClick={handleSubmitTestHelpdesk}
              type="button"
              width={140}
              style={{ marginTop: -2 }}
              height={30}
              label={isLoadingTest ? 'LOADING...' : 'SEND TEST EMAIL'}
            />
          </div>
          <p className="helpdesk-alerting-footer-text">
            Select one of your customers from the drop-down menu. Type in the email that you would
            like to use to run the test breach alert against. <br />
            This test breach alert will be sent to the Helpdesk Email Address that you have saved
            above.
          </p>
        </div>
      </Form>
    </motion.div>
  )
}

export default HelpdeskAlertingComponent
