import { formatDateToDDMMYYYY } from "./formatDate"

export const createAccordionData= (data)=>{
return data.map((item,index)=>{
    return {
        header:'Batch'+  " "+ (index+1),
        id:item.id,
        batchDate:formatDateToDDMMYYYY(item.createdAt),
        pause:item.isPause,
        processed:item.isProcessed,
        rows : [...item?.emailData]
    }
})
}