import '../../assets/css/fonts.css'
import { Form, Row, Col } from 'react-bootstrap'
import FormButton from '../../components/FormButton'
import Modal from '../../components/Modal'

const ForgotPassword = ({ formik, isLoading, handleCloseModal, showModal }) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />

      <div className="TwoFact__container" style={{ marginTop: 120 }}>
        <Form noValidate onSubmit={formik.handleSubmit} className="form-col border">
          <Row style={{ margin: 'auto' }}>
            <Col>
              <Form.Group controlId="newPassword" style={{ position: 'relative' }}>
                <Form.Control
                  placeholder="NEW PASSWORD"
                  type="password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.newPassword}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="passwordConfirmation" style={{ position: 'relative' }}>
                <Form.Control
                  placeholder="CONFIRM PASSWORD"
                  type="password" 
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.passwordConfirmation}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.passwordConfirmation}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="TwoFact__submit row">
                <div className="twodact__btn__link">
                  <FormButton
                    width={150}
                    type="submit"
                    label={!isLoading ? 'CHANGE' : 'LOADING...'}
                    mdw
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default ForgotPassword
