import './TrainingMaterialStyles.scss'
import Modal from '../../components/Modal'
import Banner from '../../components/Banner'

const TrainingMaterialComponent = ({ showModal, handleCloseModal, trainingMaterials }) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <Banner title="TRAINING MATERIAL" />
      <div className="admin-training-material-container">
        <div className="admin-training-material-body">
          {trainingMaterials.map(trainingMaterial => (
            <div style={{ marginRight: 50, marginBottom: 30 }} key={trainingMaterial.id}>
              <h4 className="admin-training-material-video-title">{trainingMaterial.name}</h4>
              <video
                poster={trainingMaterial.imageUrl}
                controls
                src={trainingMaterial.videoUrl}
                className="admin-training-material-video"
              ></video>
              <p className="admin-training-material-video-description">
                {trainingMaterial.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TrainingMaterialComponent
