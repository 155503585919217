import MdwMenu from '../../components/MdwMenu'
import AdminBreachDetailsComponent from './AdminBreachDetailsComponent.jsx'
import { useEffect, useState } from 'react'
import { getBreachDetailsService } from '../../services/spycloud.service'

const AdminBreachDetailsContainer = ({ match }) => {
  const BreachId = match.params.id
  const [breachDetails, setBreachDetails] = useState({
    title: '',
    description: '',
    num_records: 0,
    site: '',
    type: '',
  })
  const [assetStats, setAssetStats] = useState({
    email: 0,
    password: 0,
    ipAddresses: 0,
    username: 0,
    pii: 0,
    geolocation: 0,
    phone: 0,
    financialInformation: 0,
  })

  async function getBreachDetailsFromAPI() {
    try {
      const response = await getBreachDetailsService(BreachId)
      setBreachDetails(response.data.data)
      setAssetStats(prevState => ({ ...prevState, ...response.data.data.breachCatalogueAsset }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBreachDetailsFromAPI()
  }, [BreachId])

  return (
    <>
      <MdwMenu>
        <AdminBreachDetailsComponent
          breachDetails={breachDetails}
          assetStats={assetStats}
        />
      </MdwMenu>
    </>
  )
}

export default AdminBreachDetailsContainer
