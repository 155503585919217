import './Navbar.scss'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NavbarComponent = ({ logo }) => {
  const environment = process.env.REACT_APP_NODE_ENV

  return (
    <Navbar fixed="top" className="msp-navbar">
      <div>
        <Link className="navbar-brand" to="/">
          {logo && <img className="msp-navbar-image" src={logo} height={96} alt="MSP logo" />}
        </Link>
        {environment === 'development' && (
          <div className="msp-navbar-development-mode">DEVELOPMENT MODE</div>
        )}
      </div>
    </Navbar>
  )
}

export default NavbarComponent
