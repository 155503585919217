import Axios from '../config/Axios'
window.Buffer = window.Buffer || require('buffer').Buffer

export const signup = async data => {
  const response = await Axios.post('auth/signup', data)
  return response
}

export const login = async data => {
  const response = await Axios.post('auth/login', data)
  return response
}

export const verifyauth = async () => {
  const response = await Axios.post('auth')
  return response
}

export const getQrCode = async () => {
  const response = await Axios.get('/auth/qrcode', {
    responseType: 'arraybuffer',
  })
  response.image = Buffer.from(response.data, 'binary').toString('base64')
  return response
}

export const getSecretCode = async () => {
  const response = await Axios.get('/auth/secretcode')
  return response
}

export const loginTwoFa = async data => {
  const response = await Axios.post('/auth/login2fa', data)
  return response
}

export const forgotPasswordService = async data => {
  const response = await Axios.post('auth/forgot-password', data)
  return response
}

export const resetPasswordService = async data => {
  const response = await Axios.post('auth/reset-password', data)
  return response
}

export const logoutService = async () => {
  const response = await Axios.post('auth/logout')
  return response
}

export const reset2FAService = async () => {
  const response = await Axios.post('/auth/reset-2fa')
  return response
}
