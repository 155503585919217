import './AdminPackagesStyles.scss'
import { Row, Col, Table, Form } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'
import Modal from '../../components/Modal'
import AdminBanner from '../../components/AdminBanner'
import AdminPackageConfirmation from '../../components/AdminPackageConfirmation'
import FormInput from '../../components/FormInput'
import FormSwitch from '../../components/FormSwitch'
import FormButton from '../../components/FormButton'
import TrashDelete from '../../assets/icons/trashDelete.svg'

const AdminPackagesComponent = ({
  PackageId,
  packages,
  formik,
  showModal,
  handleCloseModal,
  isLoading,
  handleDelete,
  showPackageConfirmation,
  handleOnClosePackageConfirmation,
}) => {
  return (
    <>
      <AdminPackageConfirmation
        show={showPackageConfirmation}
        onClose={handleOnClosePackageConfirmation}
      />
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <AdminBanner title="PACKAGES" redirect={PackageId && adminRoutes.PACKAGES_ROUTE} />
        <div className="admin-packages-container">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <FormInput
                  formik={formik}
                  id="packageSku"
                  style={{ minWidth: 200 }}
                  label="Package SKU"
                />
              </Col>
              <Col>
                <FormInput
                  formik={formik}
                  id="packageName"
                  style={{ minWidth: 200 }}
                  label="Package Name"
                />
              </Col>
              <Col>
                <FormInput
                  formik={formik}
                  id="packageDescription"
                  style={{ minWidth: 200 }}
                  label="Package Description"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  formik={formik}
                  id="packageDomains"
                  style={{ minWidth: 100 }}
                  label="Domains"
                />
              </Col>
              <Col>
                <FormInput
                  formik={formik}
                  id="packageEmails"
                  style={{ minWidth: 100 }}
                  label="Emails"
                />
              </Col>
              <Col className="admin-packages-switch-container">
                <FormSwitch
                  formik={formik}
                  id="status"
                  style={{ marginBottom: 0 }}
                  label="Enable"
                />
              </Col>
              <Col></Col>
              <Col></Col>
              <Col className="text-right admin-packages-button-container">
                <FormButton
                  type="submit"
                  label={isLoading ? 'LOADING...' : 'SAVE'}
                  width={100}
                  height={50}
                  mdw
                />
              </Col>
            </Row>
          </Form>
          <div className="admin-packages-table-container">
            <Table className="admin-packages-table" hover borderless>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>PACKAGE NAME</th>
                  <th>PACKAGE DESCRIPTION</th>
                  <th className="text-center">DOMAINS</th>
                  <th className="text-center">EMAILS</th>
                  <th className="text-center">STATUS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {packages?.map(packagee => (
                  <tr key={packagee.packageId}>
                    <td>
                      <Link
                        to={adminRoutes.PACKAGES_ROUTE + '/' + packagee.packageId}
                        style={{ color: 'black', textDecoration: 'none' }}
                      >
                        {packagee.packageSku}
                      </Link>
                    </td>
                    <td>{packagee.packageName}</td>
                    <td>{packagee.packageDescription}</td>
                    <td className="text-center">{packagee.packageDomains}</td>
                    <td className="text-center">{packagee.packageEmails}</td>
                    <td className="text-center">{packagee.status ? 'Enabled' : 'Disabled'}</td>
                    <td className="text-center">
                      <img
                        id={packagee.packageId}
                        onClick={handleDelete}
                        style={{ cursor: 'pointer' }}
                        src={TrashDelete}
                        alt="Trash icon to delete a package"
                        width={16}
                        height={16}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AdminPackagesComponent
