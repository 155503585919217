import { Form } from 'react-bootstrap'

const FormInput = ({
  label,
  id,
  onChange,
  errors = [],
  value,
  style,
  color,
  width,
  height = 30,
  className
}) => {
  return (
    <Form.Group
      className={className}
      controlId={id}
      style={{ position: 'relative', width, ...style }}
    >
      <Form.Label
        style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
        className="form-input-label"
      >
        {label}
      </Form.Label>
      <Form.Control
        size="sm"
        className="margin-null"
        type="text"
        className={(color && `form-input-${color}-color`) + ' form-input-control'}
        style={{
          height: height && height,
          fontSize: 12,
        }}
        value={value[id]}
        onChange={onChange}
        isInvalid={!!errors[id]}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        {errors[id]}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormInput
