import MdwMenu from '../../components/MdwMenu'
import AdminSubscriptionsComponent from './AdminSubscriptionsComponent.jsx'
import { useState, useEffect } from 'react'
import { pendingSubscriptionErrorHandler } from './errorHandler'
import {
  getAllPendingSubscriptionsService,
  updatePendingSubscriptionService,
} from '../../services/pendingSubscription.service'

const AdminSubscriptionsContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [allSubscriptions, setAllSubscriptions] = useState([])
  const [pendingSubscriptions, setPendingSubscriptions] = useState([])
  const [processedSubscriptions, setProcessedSubscriptions] = useState([])

  async function getAllPendingSubscriptionsFromAPI() {
    try {
      const response = await getAllPendingSubscriptionsService()
      setPendingSubscriptions([])
      setProcessedSubscriptions([])
      setAllSubscriptions(response.data.data)
      response.data.data.forEach(pending => {
        if (pending.processed) {
          setProcessedSubscriptions(prevState => [...prevState, pending])
        } else {
          setPendingSubscriptions(prevState => [...prevState, pending])
        }
      })
    } catch (error) {
      setShowModal(pendingSubscriptionErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllPendingSubscriptionsFromAPI()
  }, [])

  async function handleChangeSubscriptionStatus(e) {
    const { id } = e.target
    const subscription = allSubscriptions.find(subscription => subscription.id === id)
    await updatePendingSubscriptionService(id, { processed: !subscription.processed })
    getAllPendingSubscriptionsFromAPI()
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminSubscriptionsComponent
          pendingSubscriptions={pendingSubscriptions}
          processedSubscriptions={processedSubscriptions}
          handleChangeSubscriptionStatus={handleChangeSubscriptionStatus}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      </MdwMenu>
    </>
  )
}

export default AdminSubscriptionsContainer
