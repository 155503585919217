import Axios from '../config/Axios'

export const addPackageService = async (data) => {
  const response = await Axios.post('package/', data)
  return response
}

export const updatePackageService = async (data, id) => {
  const response = await Axios.put(`package/${id}`, data)
  return response
}

export const getPackageService = async (id) => {
  const response = await Axios.get(`package/${id}`)
  return response
}

export const deletePackageService = async (id) => {
  const response = await Axios.delete(`package/${id}`)
  return response
}

export const getAllPackagesService = async (status = false) => {
  return status
    ? await Axios.get('package/all?status=true')
    : await Axios.get('package/all')
}
