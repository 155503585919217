import { adminRoutes, routes } from '../../config/constants'

export function adminUserErrorHandler(error) {
  console.log(error)
  const status = error.response ? error.response.status : error.message
  if (status === 404) {
    return {
      show: true,
      title: 'USER NOT FOUND',
      message:
        error.response.data.message ||
        'The user could not be found please make sure it has not been deleted',
      redirect: adminRoutes.DASHBOARD_ROUTE,
    }
  } else if (status === 400  || status === 500 ) {
    if (error.response.data.message === 'Token required') {
      return {
        show: true,
        title: 'UNAUTHORIZED',
        message: 'This action could not be authorized, you may need to log in again',
        redirect: routes.LOGIN_ROUTE,
      }
    }
    if (error.response.data.message === 'email_address must be unique') {
      return {
        show: true,
        title: 'DUPLICATE EMAIL',
        message: 'The email entered is already registered please enter another',
      }
    }
    return {
      show: true,
      title: 'BAD REQUEST',
      message: 'The data was rejected by the API',
    }
  } else if (status === 401) {
    return {
      show: true,
      title: 'UNAUTHORIZED',
      message: 'This action could not be authorized, you may need to log in again',
      redirect: routes.LOGIN_ROUTE,
    }
  } else if (status === 500) {
    return {
      show: true,
      title: 'INTERNAL SERVER ERROR',
      message: "I don't know what happened, try again in a few moments",
    }
  } else if (status === 'Network Error') {
    return {
      show: true,
      title: 'NETWORK ERROR',
      message: 'Could not connect to the API, please try again in a few moments',
    }
  } else {
    return {
      show: true,
      title: 'ERROR',
      message: status,
    }
  }
}
