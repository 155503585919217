import './AdminSubscriptionsStyles.scss'
import { Table } from 'react-bootstrap'
import Modal from '../../components/Modal'
import AdminBanner from '../../components/AdminBanner'
import FormCheckbox from '../../components/FormCheckbox'

const AdminSubscriptionsComponent = ({
  pendingSubscriptions,
  processedSubscriptions,
  handleChangeSubscriptionStatus,
  showModal,
  handleCloseModal,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <AdminBanner title="SUBSCRIPTIONS" />
      <div className="admin-subscriptions-container">
        <div className="admin-subscriptions-table-container">
          <h3 className="admin-subscriptions-title">Pending Upgrades</h3>
          <Table className="admin-subscriptions-table" borderless hover striped>
            <thead>
              <tr>
                <th>MSP</th>
                <th>CURRENT</th>
                <th>REQUESTED</th>
                <th className="text-center">PROCESSED</th>
              </tr>
            </thead>
            <tbody>
              {pendingSubscriptions.map(pendingSubscription => (
                <tr key={pendingSubscription.id}>
                  <td>{pendingSubscription.msp.companyName}</td>
                  <td>{pendingSubscription.msp.package.packageSku}</td>
                  <td>{pendingSubscription.requestedPackage.packageSku}</td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingSubscription.id}
                      onChange={handleChangeSubscriptionStatus}
                      checked={pendingSubscription.processed}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="admin-subscriptions-table-container" style={{ marginTop: 10 }}>
          <h3 className="admin-subscriptions-title">Completed Upgrades</h3>
          <Table className="admin-subscriptions-table" borderless hover striped>
            <thead>
              <tr>
                <th>MSP</th>
                <th>PREVIOUS</th>
                <th>CURRENT</th>
                <th>REQUESTED</th>
                <th className="text-center">PROCESSED</th>
              </tr>
            </thead>
            <tbody>
              {processedSubscriptions.map(processedSubscription => (
                <tr key={processedSubscription.id}>
                  <td>{processedSubscription.msp.companyName}</td>
                  <td>{processedSubscription?.previousPackage?.packageSku || "-"}</td>
                  <td>{processedSubscription?.currentPackage?.packageSku || processedSubscription.msp.package.packageSku}</td>
                  <td>{processedSubscription.requestedPackage.packageSku}</td>
                  <td className="text-center">
                    <FormCheckbox
                      id={processedSubscription.id}
                      onChange={handleChangeSubscriptionStatus}
                      checked={processedSubscription.processed}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export default AdminSubscriptionsComponent
