import './FeatureDetails.scss'
import CrossIcon from '../../assets/icons/cross.svg'
import { dateToSemiTextFormat } from '../../utilities/transformDates'

const FeatureDetails = ({ featureRequest, show, onClose }) => {
  if (!show || !featureRequest) {
    return null
  }

  return (
    <div className="feature-details">
      <div className="feature-details-content">
        <div className="feature-details-header">
          <h4 className="feature-details-title">FEATURE</h4>
          <img onClick={onClose} src={CrossIcon} width={25} height={25} />
        </div>
        <div className="feature-details-feature-title">
          <h4>{featureRequest.title}</h4>
          <p>{featureRequest.featureRequestStatus?.name || 'Waiting...'}</p>
        </div>
        <div className="feature-details-date">
          <h4>Date Requested: &nbsp;</h4>
          <p>{dateToSemiTextFormat(featureRequest.createdAt)}</p>
        </div>
        <div className="feature-details-separate-line" />
        <div className="feature-details-body">
          {featureRequest.description}
        </div>
      </div>
    </div>
  )
}

export default FeatureDetails
