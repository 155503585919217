import './BrandingStyles.scss'
import { useState } from 'react'
import { motion } from 'framer-motion'
import BrandingReportsComponent from './BrandingReportsComponent.jsx'
import BrandingLogoComponent from './BrandingLogoComponent.jsx'
import ReportsLogoComponent from './ReportsLogoComponent.jsx'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Banner from '../../components/Banner'
import Modal from '../../components/Modal'

const BrandingComponent = ({
  editorState,
  onEditorStateChange,
  editorStateCleanBill,
  onEditorStateCleanBillChange,
  formik,
  lightImg,
  onChangeLightImg,
  onDeleteLightImg,
  handleSubmitLightLogo,
  darkImg,
  onChangeDarkImg,
  onDeleteDarkImg,
  handleSubmitDarkLogo,
  isLoading,
  showModal,
  handleCloseModal,
  handleClickVariable,
  customers,
  formikTest,
}) => {
  const [tab, setTab] = useState('brandingReports')

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <Banner title="YOUR BRANDING" />
      <div className="branding-container">
        <h3 className="branding-subtitle">
          <span onClick={() => setTab('brandingReports')} className="branding-tab-buttons">
            Customer Reports
          </span>{' '}
          |{' '}
          <span onClick={() => setTab('brandingLogo')} className="branding-tab-buttons">
            Branding Logo
          </span>{' '}
          |{' '}
          <span onClick={() => setTab('reportsLogo')} className="branding-tab-buttons">
            Reports Logo
          </span>
        </h3>
        {tab === 'brandingReports' ? (
          <BrandingReportsComponent
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            formik={formik}
            handleClickVariable={handleClickVariable}
            isLoading={isLoading}
            editorStateCleanBill={editorStateCleanBill}
            onEditorStateCleanBillChange={onEditorStateCleanBillChange}
            customers={customers}
            formikTest={formikTest}
          />
        ) : (
          <>
            {tab === 'brandingLogo' ? (
              <BrandingLogoComponent
                img={lightImg}
                onChangeImg={onChangeLightImg}
                onDeleteImg={onDeleteLightImg}
                submitLogo={handleSubmitLightLogo}
                isLoading={isLoading}
              />
            ) : (
              <ReportsLogoComponent
                img={darkImg}
                onChangeImg={onChangeDarkImg}
                onDeleteImg={onDeleteDarkImg}
                submitLogo={handleSubmitDarkLogo}
                isLoading={isLoading}
              />
            )}
          </>
        )}
      </div>
    </motion.div>
  )
}

export default BrandingComponent
