import MdwMenu from '../../components/MdwMenu'
import AdminAddMSPComponent from './AdminAddMSPComponent.jsx'
import { useState, useEffect } from 'react'
import {
  addMspServiceForAdmin,
  updateMspService,
  getMspService,
  deleteMspService,
} from '../../services/msp.service'
import { getAllPackagesService } from '../../services/package.service'
import { getAllPaymentMethodsService } from '../../services/paymentMethod.service'
import { mspErrorHandler } from './errorHandler'
import { useFormik } from 'formik'
import { schema } from '../../schemas/msp.schema'
import { adminRoutes } from '../../config/constants'
import { useHistory } from 'react-router-dom'

const AdminAddMSPContainer = ({ match }) => {
  const history = useHistory()
  const MSPId = match.params.id
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [packages, setPackages] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [initialValues, setInitialValues] = useState({
    companyName: '',
    street: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    website: '',
    pcFirstName: '',
    pcLastName: '',
    pcEmail: '',
    pcTelephone: '',
    emailDomain: '',
    emailAddress: '',
    invoiceDelivery: '',
    billingFirstName: '',
    billingLastName: '',
    billingEmail: '',
    billingTelephone: '',
    packageId: '',
    status: true,
  })

  async function getMSPFromAPI() {
    if (MSPId) {
      try {
        const msp = await getMspService(MSPId);
        const mspData = msp.data.data;
        setInitialValues(prevState => ({ ...prevState, ...mspData }));
      } catch (error) {
        setShowModal(mspErrorHandler(error));
      }
    }
  }

  useEffect(() => {
    async function getAllPackagesFromAPI() {
      try {
        const allPackagesResponse = await getAllPackagesService(true)
        setPackages(allPackagesResponse.data.data)
      } catch (error) {
        setShowModal(mspErrorHandler(error))
      }
    }
    async function getAllPaymentMethodsFromAPI() {
      try {
        const response = await getAllPaymentMethodsService()
        setPaymentMethods(response.data.data)
      } catch (error) {
        setShowModal(mspErrorHandler(error))
      }
    }
    getMSPFromAPI()
    getAllPackagesFromAPI()
    getAllPaymentMethodsFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        if (MSPId) {
          await updateMspService(data, MSPId)
          setShowModal({
            show: true,
            title: 'MSP HAS BEEN UPDATED',
            message: 'The MSP has been updated successfully',
            redirect: adminRoutes.MSPS_ROUTE,
          })
        } else {
          await addMspServiceForAdmin(data)
          setShowModal({
            show: true,
            title: 'MSP HAS BEEN CREATED',
            message: "To send the welcome email go to Pending MSP's section",
            redirect: adminRoutes.MSPS_ROUTE,
          })
        }
        actions.resetForm()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(mspErrorHandler(error))
      }
    },
  })

  async function handleSuspendMSP() {
    try {
      await updateMspService({ status: !initialValues.status }, MSPId)
      getMSPFromAPI()
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  function handleSameAsCompany(id) {
    setInitialValues({ ...formik.values, [id]: formik.values.phoneNumber })
  }

  function handleCloseModal() {
    setShowModal({})
  }

  async function handleDelete() {
    try {
      if (
        window.confirm(
          `Are you sure you want to remove the MSP "${initialValues.companyName}"? This action is irreversible`
        )
      ) {
        await deleteMspService(MSPId)
        history.replace('/admin/msps')
      }
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  return (
    <>
      <MdwMenu>
        <AdminAddMSPComponent
          MSPId={MSPId}
          MSPData={initialValues}
          formik={formik}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          isLoading={isLoading}
          packages={packages}
          paymentMethods={paymentMethods}
          handleSuspendMSP={handleSuspendMSP}
          handleSameAsCompany={handleSameAsCompany}
          handleDelete={handleDelete}
        />
      </MdwMenu>
    </>
  )
}

export default AdminAddMSPContainer
