import MdwMenu from '../../components/MdwMenu'
import AdminDashboardContentComponent from './AdminDashboardContentComponent.jsx'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { schema } from '../../schemas/dashboardContent.schema'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {
  addDashboardContentService,
  deleteDashboardContentService,
  getAllDashboardContentsService,
  updateDashboardContentService,
} from '../../services/dashboardContent.service'

const AdminDashboardContentContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [dashboardContents, setDashboardContents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    title: '',
    description: '',
  })

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState)
  }

  async function getAllDashboardContentsFromAPI() {
    try {
      const response = await getAllDashboardContentsService()
      setDashboardContents(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllDashboardContentsFromAPI()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        data.description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        await addDashboardContentService(data)
        actions.resetForm()
        setIsLoading(false)
        setShowModal({
          show: true,
          title: 'CREATED SUCCESSFULLY',
          message: `The dashboard content was created correctly`,
        })
        getAllDashboardContentsFromAPI()
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    },
  })

  async function handleClickChangeStatus(id) {
    try {
      await updateDashboardContentService({ status: true }, id)
      getAllDashboardContentsFromAPI()
    } catch (error) {
      console.log(error)
    }
  }

  async function handleClickDelete(id) {
    try {
      const dashboardContent = dashboardContents.find(
        dashboardContent => dashboardContent.id === id
      )
      if (dashboardContent.status === true) {
        setShowModal({
          show: true,
          title: 'UNABLE TO DELETE',
          message: 'You cannot delete this dashboard content because it is the main one',
        })
      } else {
        await deleteDashboardContentService(id)
        getAllDashboardContentsFromAPI()
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MdwMenu>
        <AdminDashboardContentComponent
          {...{
            formik,
            showModal,
            handleCloseModal,
            editorState,
            onEditorStateChange,
            dashboardContents,
            isLoading,
            handleClickChangeStatus,
            handleClickDelete,
          }}
        />
      </MdwMenu>
    </>
  )
}

export default AdminDashboardContentContainer
