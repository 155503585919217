import './FormSwitch.scss'
import { Form } from 'react-bootstrap'

const FormSwitch = ({ id, label, style, mutedText, width, color, formik }) => {
  return (
    <Form.Group controlId={id} style={{ position: 'relative', ...style, width }}>
      <Form.Check
        checked={formik?.values[id]}
        onChange={formik?.handleChange}
        className="form-switch"
        type="switch"
        label={label}
      />
      <Form.Text className={color && `form-switch-${color}-color`}>{mutedText}</Form.Text>
    </Form.Group>
  )
}

export default FormSwitch
