import './BreachesStyles.scss'
import { motion } from 'framer-motion'
import { Table } from 'react-bootstrap'
import Banner from '../../components/Banner'
import FormInput from '../../components/FormInputWithoutFormik'
import FormButton from '../../components/FormButton'
import { Link } from 'react-router-dom'
import Modal from '../../components/Modal'
import FormSwitch from '../../components/FormSwitchWithoutFormik'
import PaginationComponent from '../../components/Pagination'

const BreachesComponent = ({
  breaches,
  values,
  handleChange,
  handleClickSearch,
  handleSendAlert,
  showModal,
  handleCloseModal,
  handleCheckboxClick,
  checked,
  setChecked,
  handlePageChange,
  handleItemsPerPageChange,
  itemsPerPage,
  currentPage,
  totalData,
  loading,
  handleClearSearch

}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <div style={{
        height: 'calc(100vh - 100px)', overflow: 'auto'
      }}>
        {loading ?
          <div className='main'>
            <div className="loader"></div>
          </div>
          :
          <>
            <Banner title="BREACHES" />
            <div className="breaches-container">
              <div className="breaches-header-container">
                <FormInput
                  label="Domain Search"
                  id="domain"
                  value={values}
                  onChange={handleChange}
                  color="primary"
                  style={{ display: 'inline-block' }}
                  width={260}
                />
                <FormInput
                  label="Email Search"
                  id="email"
                  value={values}
                  onChange={handleChange}
                  color="primary"
                  style={{ display: 'inline-block' }}
                  width={260}
                />
                <FormButton
                  onClick={handleClickSearch}
                  style={{ marginTop: 4 }}
                  width={100}
                  label="SEARCH"
                />
                <FormButton
                  onClick={handleClearSearch}
                  style={{ marginTop: 4 }}
                  width={100}
                  label="CLEAR"
                />
              </div>
              <div style={{ overflowY: 'auto', height: '570px' }} >


                <Table className="customers-table" striped hover borderless>
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th>Email Address</th>

                      <th style={{ display: 'flex', alignItems: 'center', gap: '5px', minWidth: '150px' }}>
                        Password
                        <FormSwitch
                          label=""
                          value={checked}
                          errors={'errors'}
                          onChange={e => handleCheckboxClick(e, setChecked)}
                          id="revealPass"
                          color="primary"
                          mutedText=""
                          style={{ margin: 0 }}
                          password={true}
                        />
                      </th>


                      <th>Breach Source</th>
                      <th>Breach Date</th>
                      <th>Publish Date</th>
                      <th className="text-center">Severity</th>
                      <th className="text-center">Alert Sent</th>
                      <th>Resend Alert</th>
                    </tr>
                  </thead>
                  <tbody>
                    {breaches?.map(breach => (
                      <tr key={breach.id}>
                        <td>
                          {breach.customer?.companyName
                            ? breach.customer?.companyName
                            : breach.msp?.companyName
                              ? breach.msp?.companyName
                              : 'The MSP'}
                        </td>
                        <td>{breach.email}</td>
                        <td>
                          {breach.decryptPass && breach.password_plaintext && checked
                            ? breach.decryptPass
                            : breach.hashedPassword
                          }
                        </td>
                        <td>
                          <Link
                            className="breaches-table-link"
                            to={`/breaches/${breach.breach?.mspbcid}/details`}
                          >
                            {breach.breach?.title || 'unknown'}
                          </Link>
                        </td>
                        <td>{breach.breach?.breach_date.slice(0, 10) || 'unknown'}</td>
                        <td>{breach.spycloud_publish_date.slice(0, 10)}</td>
                        <td className="text-center">{breach.severity}</td>
                        <td className="text-center">{breach.alert_sent?.slice(0, 10)}</td>
                        <td>
                          <span
                            onClick={() => {
                              handleSendAlert(breach.id)
                            }}
                            className="breaches-table-link"
                          >
                            create ticket
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <PaginationComponent currentPage={currentPage} totalPages={Math.ceil(totalData / itemsPerPage)}
                onPageChange={handlePageChange} itemsPerPage={itemsPerPage} onItemsPerPageChange={handleItemsPerPageChange} />
            </div>
          </>
        }
      </div>
    </motion.div>
  )
}

export default BreachesComponent