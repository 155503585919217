import React from 'react'
import MdwMenu from '../../components/MdwMenu'
import AdminRateLimitComponent from './AdminRateLimitComponent'

const AdminRateLimitContainer = () => {
    return (
        <>
            <MdwMenu>
                <AdminRateLimitComponent />
            </MdwMenu>
        </>
    )
}

export default AdminRateLimitContainer