import './AccountStyles.scss'
import { Col, Row, Form, Table } from 'react-bootstrap'
import FormInput from '../../components/FormInput'
import FormButton from '../../components/FormButton'
import TrashDeleteBlackIcon from '../../assets/icons/trashDeleteBlack.svg'
import Banner from '../../components/Banner'
import Modal from '../../components/Modal'
import PlusIcon from '../../assets/icons/plus.svg'
import { Link } from 'react-router-dom'
import RequestUpgrade from '../../components/RequestUpgrade'

const EditAccountComponent = ({
  formik,
  isLoading,
  showModal,
  handleCloseModal,
  handleOnClickAdd,
  table,
  handleResetDomainOrEmail,
  handleClickDelete,
  handleClickUpgradeAccount,
  showAccountUpgradeModal,
  packages,
  packageSelected,
  handleChangePackageRequest,
  handleSubmitPackageRequest,
  handleCloseAccountUpgradeModal,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
      />
      <RequestUpgrade
        show={showAccountUpgradeModal}
        options={packages}
        value={packageSelected}
        onChange={handleChangePackageRequest}
        onSubmit={handleSubmitPackageRequest}
        onClose={handleCloseAccountUpgradeModal}
      />
      <Banner title="YOUR ACCOUNT" />
      <div className="edit-account-container">
        <Form noValidate onSubmit={formik.handleSubmit} id="editAccountForm">
          <Row>
            <Col className="edit-account-col">
              <div className="form-group" style={{ position: 'relative' }}>
                <label
                  className="form-input-label form-label"
                  for="companyName"
                  style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                >
                  <span>Company Name</span>
                </label>
                <input
                  title="To change the company name please contact support"
                  type="text"
                  id="companyName"
                  className="form-input-primary-color form-input-control form-control"
                  value={formik.values.companyName}
                  style={{ height: 30 }}
                  disabled
                />
                <small className="muted-text form-text"></small>
              </div>
              <FormInput id="street" formik={formik} label="Street" color="primary" />
              <FormInput id="city" formik={formik} label="City" color="primary" />
              <FormInput id="postalCode" formik={formik} label="Postcode / Zip" color="primary" />
              <FormInput id="phoneNumber" formik={formik} label="Company Phone" color="primary" />
              <Form.Group controlId="domains" style={{ position: 'relative' }}>
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="form-input-label"
                >
                  <span>Domain(s)</span>
                </Form.Label>
                <div>
                  <div className="form-input-plus-container" style={{ position: 'relative' }}>
                    <Form.Control
                      disabled={formik?.values?.availableDomains <= 0?true:false}
                      className="form-input-primary-color form-input-plus-control"
                      type="text"
                      isInvalid={formik.errors.domains}
                      style={{ height: 30 }}
                    />
                    <img
                      style={{ position: 'absolute', cursor: 'pointer', top: 6, right: 6 }}
                      src={PlusIcon}
                      onClick={() => formik?.values?.availableDomains && handleOnClickAdd('domains')}
                      alt="Add icon"
                      width={18}
                      height={18}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.domains}
                  </Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  {formik?.values?.availableDomains > 0 ? "Adding a domain will monitor the entire email domain for your customer and lookfor any breaches with email addresses in that domain." : <span style={{ color:'#f85954'}}>You have reached your package domain limit. To add new domains for monitoring, please go to your account screen and request an increase for your monthly package.</span>}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col className="edit-account-col">
              <FormInput
                id="pcFirstName"
                formik={formik}
                label="Primary Contact First Name"
                color="primary"
              />
              <FormInput
                id="pcLastName"
                formik={formik}
                label="Primary Contact Last Name"
                color="primary"
              />
              <FormInput
                id="pcEmail"
                formik={formik}
                label="Primary Contact Email"
                color="primary"
              />
              <FormInput
                id="pcTelephone"
                formik={formik}
                label="Primary Contact Telephone"
                color="primary"
              />
              <FormInput id="website" formik={formik} label="Company Website" color="primary" />
              <FormInput id="ip" formik={formik} label="Company Website IP Address" color="primary" />
              <Form.Group controlId="emails" style={{ position: 'relative' }}>
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="form-input-label"
                >
                  <span>Personal Email Addresses</span>
                </Form.Label>
                <div>
                  <div className="form-input-plus-container" style={{ position: 'relative' }}>
                    <Form.Control
                      className="form-input-primary-color form-input-plus-control"
                      type="text"
                      isInvalid={formik.errors.emails}
                      style={{ height: 30 }}
                    />
                    <img
                      style={{ position: 'absolute', cursor: 'pointer', top: 6, right: 6 }}
                      src={PlusIcon}
                      onClick={() => handleOnClickAdd('emails')}
                      alt="Add icon"
                      width={18}
                      height={18}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.emails}
                  </Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  Adding personal email addresses for persons of significant importance within this
                  company will monitor for breaches on that email address only.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col className="edit-account-col">
              <FormInput
                id="billingFirstName"
                formik={formik}
                label="Billing Contact First Name"
                color="primary"
              />
              <FormInput
                id="billingLastName"
                formik={formik}
                label="Billing Contact Last Name"
                color="primary"
              />
              <FormInput
                id="billingEmail"
                formik={formik}
                label="Billing Contact Email"
                color="primary"
              />
              <FormInput
                id="billingTelephone"
                formik={formik}
                label="Billing Contact Telephone"
                color="primary"
              />
              <FormInput
                id="invoiceDelivery"
                formik={formik}
                label="Invoice Delivery Email"
                color="primary"
              />
              <FormButton
                onClick={handleClickUpgradeAccount}
                style={{ marginTop: 19 }}
                label="UPGRADE YOUR ACCOUNT"
                width="100%"
                type="button"
              />
            </Col>
          </Row>
          <Row>
            <Table
              style={{ margin: '0 15px 45px 15px' }}
              className="customers-table"
              hover
              borderless
            >
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Type</th>
                  <th style={{ width: '30%' }}>Domain/Email</th>
                  <th style={{ width: '15%' }} className="text-center">
                    View Initial Scan
                  </th>
                  <th style={{ width: '15%' }} className="text-center">
                    Reset Initial Scan
                  </th>
                  <th style={{ width: '10%' }} className="text-center">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>
                {table.map((emailOrDomain, i) => (
                  <tr key={i}>
                    <td>
                      {emailOrDomain.value.includes('@') ? 'Personal Email Address' : 'Domain'}
                    </td>
                    <td>{emailOrDomain.value}</td>
                    <td className="text-center">
                      {emailOrDomain.new ? (
                        <span
                          title="You must save the changes first"
                          style={{
                            textDecoration: 'underline',
                            color: 'black',
                            cursor: 'not-allowed',
                            opacity: 0.6,
                          }}
                        >
                          view
                        </span>
                      ) : (
                        <Link
                          to={`/first-breach?${
                            emailOrDomain.value.includes('@') ? 'email' : 'domain'
                          }=${emailOrDomain.value}`}
                        >
                          <span style={{ textDecoration: 'underline', color: 'black' }}>view</span>
                        </Link>
                      )}
                    </td>
                    <td className="text-center">
                      {emailOrDomain.new ? (
                        <span
                          title="You must save the changes first"
                          style={{
                            textDecoration: 'underline',
                            cursor: 'not-allowed',
                            opacity: 0.6,
                          }}
                        >
                          reset
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            handleResetDomainOrEmail(emailOrDomain.value)
                          }}
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          reset
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleClickDelete(
                            emailOrDomain.value,
                            emailOrDomain.value.includes('@') ? 'emails' : 'domains'
                          )
                        }}
                        src={TrashDeleteBlackIcon}
                        alt="Delete icon"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Form>
      </div>
      <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
        <FormButton
          form="editAccountForm"
          width={100}
          type="submit"
          label={isLoading ? 'LOADING...' : 'SAVE'}
        />
      </div>
    </>
  )
}

export default EditAccountComponent
