import Axios from '../config/Axios'

export const getAllBreachesService = async (page, pageSize, signal, email = '', domain = '') => {
  const response = await Axios.get(`/breach/all?page=${page}&limit=${pageSize}&email=${email ?? ''}&domain=${domain ?? ''}`, { signal: signal })
  return response
}

export const getAllBreachesServiceCount = async () => {
  const response = await Axios.get('/breach/all-count')
  return response
}
export const sendABreachAlertService = async breachId => {
  const response = await Axios.post(`/breach/alert/${breachId}`)
  return response
}

export const sendATestMonthlyReport = async ({ customer, emailToSend }) => {
  const response = await Axios.post('/breach/monthly-report/test', { customer, emailToSend })
  return response
}
