import './AdminBanner.scss'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../../config/constants'
import CrossIcon from '../../assets/icons/crossWhite.svg'

const AdminBanner = ({ title, style, close = true, redirect = adminRoutes.DASHBOARD_ROUTE }) => {
  return (
    <div className="admin-banner-container" style={{...style}}>
      <h1>{title}</h1>
      {close && (
        <Link to={redirect}>
          <img src={CrossIcon} alt="Cross icon to close page" width={25} height={25} />
        </Link>
      )}
    </div>
  )
}

export default AdminBanner
