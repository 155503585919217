import React from 'react';
import { Pagination, Form } from 'react-bootstrap';
import './pagination.scss'

const PaginationComponent = ({ currentPage, totalPages, onPageChange, itemsPerPage, onItemsPerPageChange }) => {
	const maxPagesToShow = 5;
	const pages = [];

	let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
	let endPage = startPage + maxPagesToShow - 1;

	if (endPage > totalPages) {
		endPage = totalPages;
		startPage = Math.max(endPage - maxPagesToShow + 1, 1);
	}

	for (let i = startPage; i <= endPage; i++) {
		pages.push(
			<Pagination.Item
				key={i}
				active={i === currentPage}
				onClick={() => onPageChange(i)}
			>
				{i}
			</Pagination.Item>
		);
	}

	return (
		<div className="d-flex justify-content-end align-items-center mt-3" style={{ gap: 10 }}>
			<Pagination>
				<Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
				<Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
				{startPage > 1 && <Pagination.Ellipsis />}
				{pages}
				{endPage < totalPages && <Pagination.Ellipsis />}
				<Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
				<Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
			</Pagination>
			<Form.Group controlId="itemsPerPageSelect" className="d-flex align-items-center">
				<Form.Control as="select" value={itemsPerPage} onChange={onItemsPerPageChange} className='custom-select'>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
					<option value={500}>500</option>
					<option value={1000}>1000</option>
				</Form.Control>
			</Form.Group>


		</div>
	);
};

export default PaginationComponent;