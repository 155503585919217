import Axios from '../config/Axios'

export const addFeatureRequestStatusService = async (data) => {
  const response = await Axios.post('feature-request/status/', data)
  return response
}

export const deleteFeatureRequestStatusService = async (id) => {
  const response = await Axios.delete(`feature-request/status/${id}`)
  return response
}

export const getAllFeatureRequestStatusesService = async () => {
  const response = await Axios.get('feature-request/status/all')
  return response
}
