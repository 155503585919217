import Axios from '../config/Axios'

export const addNewsService = async data => {
  const response = await Axios.post('news/', data)
  return response
}

export const getAllNewsService = async limit => {
  if (limit) {
    return await Axios.get(`news/all?limit=${limit}`)
  }
  return await Axios.get('news/all')
}

export const updateNewsService = async (data, id) => {
  const response = await Axios.put(`news/${id}`, data)
  return response
}

export const deleteNewsService = async (id) => {
  const response = await Axios.delete(`news/${id}`)
  return response
}
