import './AddCustomerStyles.scss'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { routes } from '../../config/constants'
import Modal from '../../components/Modal'
import FormInput from '../../components/FormInputWithoutFormik'
import FormSelect from '../../components/FormSelectWithoutFormik'
import FormSwitch from '../../components/FormSwitchWithoutFormik'
import Banner from '../../components/Banner'
import { industries, countries } from '../../config/constants'
import PlusIcon from '../../assets/icons/plus.svg'
import TrashDeleteBlackIcon from '../../assets/icons/trashDeleteBlack.svg'
import FormButton from '../../components/FormButton'

const AddCustomerComponent = ({
  showModal,
  handleCloseModal,
  errors,
  customer,
  handleClickDelete,
  handleOnClickAdd,
  handleOnChange,
  handleSubmit,
  table,
  isLoading,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message || 'The new package can now be assigned to an MSP'}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <Banner title="ADD CUSTOMER" redirect={routes.CUSTOMERS_ROUTE} />
      <div className="add-customer-container">
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xs={5}>
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="companyName"
                label="Company Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="firstName"
                label="First Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="lastName"
                label="Last Name"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSelect
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                options={industries}
                type="industries"
                id="industry"
                label="Industry"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSelect
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                options={countries}
                type="countries"
                id="country"
                label="Country"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormInput
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="reportEmail"
                label="Customer Report Email Address"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSwitch
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="reportStatus"
                label="Disable/Enable Reports"
                color="primary"
                mutedText="Enabling Customer Reports will send a
              report to the customer report delivery email address at the frequency selected."
                style={{ marginBottom: 5, marginTop: 30 }}
              />
              <FormSelect
                value={customer ? customer : customer.reportFrequency}
                errors={errors}
                onChange={handleOnChange}
                options={['monthly']}
                type="frequency"
                id="reportFrequency"
                label="Report Frequency"
                color="primary"
                style={{ marginBottom: 5 }}
              />
              <FormSwitch
                value={customer}
                errors={errors}
                onChange={handleOnChange}
                id="status"
                label="Disable/Enable Customer"
                color="primary"
                mutedText="Disabling the customer will disable all domains and emails from live monitoring"
                style={{ marginBottom: 5 }}
              />
              <Form.Group style={{ marginBottom: 5 }} controlId="domains">
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                  className="form-input-label"
                >
                  Domain(s)
                </Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    className="form-input-primary-color form-input-plus-control"
                    type="text"
                    isInvalid={errors.domains}
                    style={{ fontSize: 12, height: 30 }}
                  />
                  <img
                    style={{ position: 'absolute', cursor: 'pointer', top: 6, right: 6 }}
                    src={PlusIcon}
                    onClick={() => handleOnClickAdd('domains')}
                    alt="Add icon"
                    width={18}
                    height={18}
                  />
                  <Form.Control.Feedback type="invalid">{errors.domains}</Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  Adding a domain will monitor the entire email domain for your customer and look
                  for any breaches with email addresses in that domain.
                </Form.Text>
              </Form.Group>
              <Form.Group style={{ marginBottom: 5 }} controlId="emails">
                <Form.Label
                  style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                  className="form-input-label"
                >
                  Personal Email Addresses
                </Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    className="form-input-primary-color form-input-plus-control"
                    type="text"
                    isInvalid={errors.emails}
                    style={{ fontSize: 12, height: 30 }}
                  />
                  <img
                    style={{ position: 'absolute', cursor: 'pointer', top: 6, right: 6 }}
                    src={PlusIcon}
                    onClick={() => handleOnClickAdd('emails')}
                    alt="Add icon"
                    width={18}
                    height={18}
                  />
                  <Form.Control.Feedback type="invalid">{errors.emails}</Form.Control.Feedback>
                </div>
                <Form.Text className="muted-text">
                  Adding personal email addresses for persons of significant importance within this
                  company will monitor for breaches on that email address only.
                </Form.Text>
              </Form.Group>
              <div className="add-customer-buttons-container">
                <FormButton type="submit" width={100} label={isLoading ? 'LOADING...' : 'SAVE'} />
              </div>
            </Col>
            <Col>
              <div>
                <Table className="customers-table" hover borderless>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Type</th>
                      <th style={{ width: '40%' }}>Domain/Email</th>
                      <th style={{ width: '20%' }} className="text-center">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((value, i) => (
                      <tr key={i}>
                        <td>{value.includes('@') ? 'Personal Email Address' : 'Domain'}</td>
                        <td>{value}</td>
                        <td className="text-center">
                          <img
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleClickDelete(value, value.includes('@') ? 'emails' : 'domains')
                            }}
                            src={TrashDeleteBlackIcon}
                            alt="Delete icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default AddCustomerComponent
